import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import {
  Button,
  Form,
  FormField,
  SlideOver,
  TextInput,
  Modal,
  type SlideOverOnCloseProps,
  LoadingIndicator,
  TagWindowIcon,

} from '@client/shared/toolkit';
import {
  type TemplateScenarioShortReadModel,
  useApiPostTemplateScenarioMutation,
  useApiPutTemplateScenarioMutation,
} from '@client/shared/api';
import * as yup from 'yup';
import type { InferType } from 'yup';
import { safeMutation } from '@client/shared/utilities';
import { TemplateScenarioDeleteModal } from './TemplateScenarioDeleteModal';

export const TemplateScenarioFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
});

export type TemplateScenarioFormValidationValues = InferType<typeof TemplateScenarioFormValidationSchema>;

interface TemplateScenarioDetailsSlideOverProps extends SlideOverOnCloseProps {
  isAddMode: boolean;
  templateId: string;
  scenario: TemplateScenarioShortReadModel | undefined;
}

export const TemplateScenarioSlideOver = ({
  isAddMode,
  templateId,
  scenario,
  onClose,
}: TemplateScenarioDetailsSlideOverProps) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);

  const [postUpdate, { isLoading: isUpdating }] = useApiPutTemplateScenarioMutation();
  const [postCreate, { isLoading: isCreating }] = useApiPostTemplateScenarioMutation();
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const defaultFormValues = {
    name: scenario?.name ?? '',
  };

  const handleTemplateScenarioSubmit = async (data: TemplateScenarioFormValidationValues) => {
    if (isAddMode) {
      try {
        await safeMutation(
          postCreate,
          {
            templateId: templateId,
            body: {
                name: data.name
            },
          },
          isCreating
        );
        onClose(true);
      } catch {
        /* left blank */
      }
    } else {
      try {
        await safeMutation(
          postUpdate,
          {
            templateId: templateId,
            scenarioId: scenario?.id ?? '',
            body: {
              name: data.name,
            },
          },
          isUpdating
        );
        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <>
      <Form<TemplateScenarioFormValidationValues>
        onSubmit={handleTemplateScenarioSubmit}
        validationSchema={TemplateScenarioFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
      >
        <SlideOver.Header
          title={scenario?.name ?? t('projectVariants.actionCreateCopy')}
          backgroundClassName="bg-gray-600"
          onClose={() => onClose(false)}
        />

        <SlideOver.Content>
          {isUpdating || isCreating ? (
            <div className="m-2">
              <LoadingIndicator text={t('templates.projectTemplatesLoadingIndicator') ?? ''} />
            </div>
          ) : (
            <div>
              <div className="m-8 bg-white">
                <div className="divide-gray-100 divide-y">
                  <FormField name="name">
                    {(control) => <TextInput label={t('common.name')} icon={<TagWindowIcon />} {...control} />}
                  </FormField>
                </div>
              </div>

            </div>
          )}
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className="w-full flex justify-between">
            {!isAddMode && (
              <Button variant="warning" onClick={() => setShowDelete(true)}>
                {t('common.delete')}
              </Button>
            )}
            <div className="flex ml-auto">
              <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
                {t('common.cancel')}
              </Button>
              <Button variant="primary" formSubmit={true} innerRef={submitRef}>
                {t('common.save')}
              </Button>
            </div>
          </div>
        </SlideOver.Controls>
      </Form>

      <Modal isOpen={showDelete} onClose={onClose} variant="small">
        <TemplateScenarioDeleteModal
          scenario={scenario}
          onClose={(success: boolean) => {
            setShowDelete(false);

            if (success) {
              onClose(true);
            }
          }}
        />
      </Modal>
    </>
  );
};
