import { CalculateContext, TimeLineView } from '.';
import React, { PropsWithChildren, ReactNode, useContext } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { DocumentCustomIcon, HierarchyIndicator, TextHighlighter } from '@client/shared/toolkit';

export interface CalculateGroupElementInformationColumnProps extends PropsWithChildren {
  className?: string;
  level: number;
  view: TimeLineView;
  handleOnClick?: (evt: React.MouseEvent<HTMLDivElement>) => void;
  rightColumn?: ReactNode;
  leftColumn?: ReactNode;
  menu?: ReactNode;
  'data-cy'?: string;
}

/* Information Column for group elements */
export const CalculateGroupElementInformationColumn = (props: CalculateGroupElementInformationColumnProps) => {
  const { level, rightColumn, leftColumn, view, handleOnClick, menu } = props;
  const { isListCollapsed } = useContext(CalculateContext);
  return (
    <div
      className={cn('flex w-full h-full', {
        'cursor-pointer': handleOnClick,
      })}
      onClick={handleOnClick}
      data-cy={props['data-cy']}
    >
      <div
        className={cn({
          'w-2/3 md:w-1/2': view === TimeLineView.TIMELINE && !isListCollapsed,
          'w-full flex-1': isListCollapsed || view === TimeLineView.FINANCE,
          'pr-6': isListCollapsed && view === TimeLineView.TIMELINE,
        })}
      >
        <div
          data-level={level}
          className={cn('group w-full flex h-full', {
            'text-[15px]': level === 0,
            'text-xs': level > 0,
            'pr-4': view === TimeLineView.FINANCE,
          })}
          style={{ paddingLeft: (level * 10) + 32 }}
        >
          <div
            className={cn('flex w-full truncate', {
              'border-b': level === 0,
              //'ml-12': level === 2,
              //'ml-24': level === 3,
            })}
          >
            {leftColumn}
          </div>
        </div>
      </div>
      {view === TimeLineView.TIMELINE && !isListCollapsed && (
        <div className="w-1/3 md:w-1/2 pr-6 lg:pr-0">
          <div
            className={cn('w-full flex h-[38px] pr-4 truncate justify-end', {
              'border-b': level === 0,
              'text-sm': level === 0,
              'text-xs': level > 0,
            })}
          >
            {rightColumn}
          </div>
        </div>
      )}
      {menu}
    </div>
  );
};

export interface CalculateGroupElementInformationColumnContentProps extends PropsWithChildren {
  level: number;
  code?: string | null;
  handleOnCodeClick?: (evt: React.MouseEvent<HTMLDivElement>) => void;
  showExpanded?: boolean;
  showError?: boolean;
  title?: string | null;
  contentType?: 'group' | 'row' | 'contract' | 'commitment' | 'invoicingParty';
  marker?: ReactNode;
  showCode?: boolean;
  searchValue?: string;
  restBudget?: boolean;
  index?: number;
}
export const CalculateInformationColumnContent = (props: CalculateGroupElementInformationColumnContentProps) => {
  const {
    showCode = true,
    code,
    handleOnCodeClick,
    level,
    showExpanded,
    showError,
    title,
    children,
    contentType = 'group',
    marker,
    searchValue,
    restBudget,
    index
  } = props;
  return (
    <div className={
      cn('w-full flex items-center', {
        'gap-2': contentType === 'row' || contentType === 'group',
        'gap-1': contentType === 'commitment' || contentType === 'contract' || contentType === 'invoicingParty'
      })
    }>
      <div className={cn('flex items-center flex-none justify-between gap-2')}>
        {/* Marker */}
        {marker ? (
          marker
        ) : (
          <>
            {contentType === 'row' || contentType === 'group' ? (
              <div className={cn('relative w-[0.5625rem] flex-none', { invisible: !showExpanded })}>
                {contentType === 'row' && <div className="w-px h-8 absolute ml-1 -mt-9 bg-slate-400 z-0" />}
                <HierarchyIndicator />
              </div>
            ) : (
              <div className="relative">
                {index === 0 && !restBudget ? (
                  <>
                    <div className="ml-[4px] w-[9px] h-[10px] absolute -mt-[22px] border-slate-400 z-1 rounded-bl-2xl border-l border-b" />
                    <div className="ml-[12px] w-[9px] h-[10px] absolute -mt-[13px] border-slate-400 z-1 rounded-tr-2xl border-r border-t" />
                  </>
                ) : (
                  <div className="ml-5 w-px h-9 absolute -mt-9 bg-slate-400 z-0" />
                )}
                <div className="w-px h-10 absolute ml-1 -mt-9 bg-slate-400 z-0" />
                <HierarchyIndicator className="ml-4" />
              </div>
            )}
          </>
        )}

        {/* Error */}
        {showError && <ExclamationTriangleIcon className="h-6 w-6 text-red-900 flex-shrink-0" />}

        {/* Code */}
        {code && (
          <span
            data-level={level}
            data-content-type={contentType}
            className={cn('shrink-0 text-right flex-1 truncate flex items-center', {
              'font-bold': level === 0,
              'ml-6': contentType === 'commitment' || contentType === 'contract' || contentType === 'invoicingParty'
            })}
            onClick={handleOnCodeClick}
          >
            {/* Icon */}
            {(contentType === 'commitment' || contentType === 'contract' || contentType === 'invoicingParty') && (
              <DocumentCustomIcon className="h-4 w-4 mr-1 opacity-60 flex-none" />
            )}
            {showCode && !searchValue && code}
            {showCode && searchValue && code && (
              <TextHighlighter
                text={code}
                highlighted={searchValue
                  .split(',')
                  .filter((x) => x !== '')
                  .map((x) => x.trim())}
              />
            )}
        </span>
        )}
      </div>

      {/* Title */}
      <div
        className={cn('flex flex-row items-center gap-2 truncate', {
          'font-bold': level < 2 && contentType !== 'row',
        })}
      >
        {title && (
          <span className="overflow-x-hidden text-ellipsis text-slate-600" title={title}>
            {searchValue ? (
              <TextHighlighter
                text={title}
                highlighted={searchValue
                  .split(',')
                  .filter((x) => x !== '')
                  .map((x) => x.trim())}
              />
            ) : (
              title
            )}
          </span>
        )}
        {children}
      </div>
    </div>
  );
};
