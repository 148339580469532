import { formatDateOnly, safeMutation } from '@client/shared/utilities';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  API_MAPPING,
  ApiClientMappingDeleteModal,
  DatevMappingAddSlideOver,
  DatevMappingHeaderMenu,
  YardiMappingAddSlideOver,
} from '@client/project/shared';
import {
  AddButton,
  Button,
  ContextMenu,
  ContextMenuItem,
  DATEV_LOGO_IMAGE,
  EditListIcon,
  FilledLockIcon,
  HintBox,
  LoadingIndicator,
  Modal,
  PencilIcon,
  SlideOverSortableList,
  SlideOverSortableListItem,
  SortDownIcon,
  SortingArrowsIcon,
  TrashIcon,
} from '@client/shared/toolkit';
import classNames from 'classnames';
import {
  DatevProbisMappingReadModel,
  useApiChangeProjectDatevClientMutation,
  useApiCreateDatevAuthenticationUrlQuery,
  useApiCreateDatevCacheMutation,
  useApiDeleteProjectDatevProbisMappingMutation,
  useApiDeleteYardiProbisProjectMappingMutation,
  useApiGetDatevActiveClientsQuery,
  useApiGetExternalApisQuery,
  useApiGetProjectDatevConnectionQuery,
  useApiGetProjectYardiConnectionQuery,
  useApiPostImportTenantDatevActiveApiMappingMutation,
  useApiPostImportYardiApiMappingInProjectMutation,
  useApiUpdateDatevAccountingMonthMutation,
  YardiProbisMappingReadModel,
} from '@client/shared/api';
import { updateDatevAuthCookie, useLoadedProjectId } from '@client/project/store';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useUi } from '@client/shared/store';

function groupMappingsByType<T extends { type: string }>(mappings: T[]) {
  const grouped = mappings.reduce(
    (acc, item) => {
      const group = acc.find((x) => x.name === item.type);
      if (!group) {
        acc.push({ name: item.type, items: [item] });
      } else {
        group.items.push(item);
      }
      return acc;
    },
    [] as { name: string; items: T[] }[],
  );
  grouped.sort((a, b) => a.name.localeCompare(b.name));
  return grouped;
}

export type ApiConnectionType = 'Datev' | 'Yardi';

interface EditProjectConnectionsProps {
  projectId: string;
}

export const EditProjectConnections = ({ projectId }: EditProjectConnectionsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loadedProjectId = useLoadedProjectId();
  const ui = useUi();
  const subdomain = ui.appUser.tenant?.subdomain;

  const { data: datevData, isFetching: isFetchingDatev } = useApiGetProjectDatevConnectionQuery({
    projectId: projectId,
  });

  const { data: yardiData, isFetching: isFetchingYardi } = useApiGetProjectYardiConnectionQuery({
    projectId: projectId,
  });
  
  const [postCreateDatevCache, { isLoading: isCreatingDatevCache }] = useApiCreateDatevCacheMutation();
  const [updateActiveClient, { isLoading: isUpdatingDatevClient }] = useApiChangeProjectDatevClientMutation();
  const [updateAccountingMonth, { isLoading: isUpdatingAccountingMonth }] = useApiUpdateDatevAccountingMonthMutation();
  const [importDatevMappings, { isLoading: isImportingDatevMappings }] =
    useApiPostImportTenantDatevActiveApiMappingMutation();
  const [importYardiMappings, { isLoading: isImportingYardiMappings }] =
    useApiPostImportYardiApiMappingInProjectMutation();

  const { data: projectClients, isFetching: isFetchingClients } = useApiGetDatevActiveClientsQuery(undefined);

  const isDatevImportEnabled = !(datevData?.projectDatevOverview?.hasDatevMappingImported ?? false);
  const isYardiImportEnabled = !(yardiData?.hasMappingImported ?? false);

  const [selectedClient, setSelectedClient] = useState<{ clientId: string; datevClientId: string }>({
    clientId: datevData?.connectionResponse.clientDetail?.id ?? '',
    datevClientId: '',
  });
  const [openModal, setOpenModal] = useState<'EditDatev' | 'EditYardi' | 'Delete' | null>(null);
  const [selectedApiClientItem, setSelectedApiClientItem] = useState<
    YardiProbisMappingReadModel | DatevProbisMappingReadModel | null
  >(null);
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<string>('');

  const currentClient = useMemo(() => {
    return datevData?.connectionResponse.clientDetail?.id;
  }, [datevData?.connectionResponse.clientDetail?.id]);

  const selectedClientHasAccess = useMemo(() => {
    return projectClients?.clients.find((client) => client.id === selectedClient.clientId)?.hasAccessAvailable;
  }, [projectClients?.clients, selectedClient.clientId]);

  const { data: externalApis, isFetching } = useApiGetExternalApisQuery();

  const { data: createDatevAuthUrlResponse, isFetching: isFetchingDatevAuthUrl } =
    useApiCreateDatevAuthenticationUrlQuery(
      {
        datevClientId: selectedClient.datevClientId,
      },
      {
        skip:
          !selectedClient.datevClientId || selectedClient.datevClientId === currentClient || selectedClientHasAccess,
        refetchOnMountOrArgChange: true,
      },
    );

  const yearOptions = useMemo(() => {
    const yearSet = new Set<string>();
    datevData?.connectionResponse.clientDetail?.accountingInformation.forEach(({ fiscalYearStart }) => {
      const year = String(new Date(fiscalYearStart).getFullYear());
      yearSet.add(year < '2000' ? '2000' : year);
    });
    return Array.from(yearSet).map((year) => ({ value: year, label: year }));
  }, [datevData?.connectionResponse.clientDetail?.accountingInformation]);

  const monthOptions = useMemo(() => {
    const year = datevData?.connectionResponse.clientDetail?.accountingInformation.find(
      (accountingInformation) => new Date(accountingInformation.fiscalYearStart).getFullYear() === Number(selectedYear),
    );
    const fiscalYearStart = year ? new Date(year.fiscalYearStart).getMonth() : new Date().getMonth();
    const fiscalYearEnd = year ? new Date(year.fiscalYearEnd).getMonth() : new Date().getMonth();

    return [
      { value: '0', label: t('common.monthJanuary'), disabled: fiscalYearStart > 0 && fiscalYearEnd < 0 },
      { value: '1', label: t('common.monthFebruary'), disabled: fiscalYearStart > 1 && fiscalYearEnd < 1 },
      { value: '2', label: t('common.monthMarch'), disabled: fiscalYearStart > 2 && fiscalYearEnd < 2 },
      { value: '3', label: t('common.monthApril'), disabled: fiscalYearStart > 3 && fiscalYearEnd < 3 },
      { value: '4', label: t('common.monthMay'), disabled: fiscalYearStart > 4 && fiscalYearEnd < 4 },
      { value: '5', label: t('common.monthJune'), disabled: fiscalYearStart > 5 && fiscalYearEnd < 5 },
      { value: '6', label: t('common.monthJuly'), disabled: fiscalYearStart > 6 && fiscalYearEnd < 6 },
      { value: '7', label: t('common.monthAugust'), disabled: fiscalYearStart > 7 && fiscalYearEnd < 7 },
      { value: '8', label: t('common.monthSeptember'), disabled: fiscalYearStart > 8 && fiscalYearEnd < 8 },
      { value: '9', label: t('common.monthOctober'), disabled: fiscalYearStart > 9 && fiscalYearEnd < 9 },
      { value: '10', label: t('common.monthNovember'), disabled: fiscalYearStart > 10 && fiscalYearEnd < 10 },
      { value: '11', label: t('common.monthDecember'), disabled: fiscalYearStart > 11 && fiscalYearEnd < 11 },
    ];
  }, [datevData?.connectionResponse.clientDetail?.accountingInformation, t, selectedYear]);

  useEffect(() => {
    setSelectedYear(
      String(
        datevData?.projectDatevOverview.accountingMonth
          ? new Date(datevData?.projectDatevOverview.accountingMonth).getFullYear()
          : '',
      ),
    );
  }, [datevData?.projectDatevOverview.accountingMonth, yearOptions]);

  useEffect(() => {
    setSelectedMonth(
      String(
        datevData?.projectDatevOverview.accountingMonth
          ? new Date(datevData?.projectDatevOverview.accountingMonth).getMonth()
          : '',
      ),
    );
  }, [datevData?.projectDatevOverview.accountingMonth, monthOptions]);

  const handleAdd = (connection: ApiConnectionType) => {
    setSelectedApiClientItem(null);
    setOpenModal(connection === API_MAPPING.DATEV ? 'EditDatev' : 'EditYardi');
  };

  const handleEdit = (
    item: DatevProbisMappingReadModel | YardiProbisMappingReadModel,
    connection: ApiConnectionType,
  ) => {
    setSelectedApiClientItem(item);
    setOpenModal(connection === API_MAPPING.DATEV ? 'EditDatev' : 'EditYardi');
  };

  const [deleteDatevProjectMapping, { isLoading: isDeletingDatevProjectMapping }] =
    useApiDeleteProjectDatevProbisMappingMutation();
  const [deleteYardiProjectMapping, { isLoading: isDeletingYardiProjectMapping }] =
    useApiDeleteYardiProbisProjectMappingMutation();

  const deleteItem = async (connection: ApiConnectionType) => {
    try {
      await safeMutation(
        connection === API_MAPPING.DATEV ? deleteDatevProjectMapping : deleteYardiProjectMapping,
        {
          projectId: selectedApiClientItem?.projectId ?? '',
          mappingId: selectedApiClientItem?.id ?? '',
        },
        connection === API_MAPPING.DATEV ? isDeletingDatevProjectMapping : isDeletingYardiProjectMapping,
      );
      setOpenModal(null);
      setSelectedApiClientItem(null);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDelete = (item: DatevProbisMappingReadModel | YardiProbisMappingReadModel) => {
    setSelectedApiClientItem(item);
    setOpenModal('Delete');
  };

  const importConnections = async (connection: ApiConnectionType) => {
    try {
      await safeMutation(
        connection === API_MAPPING.DATEV ? importDatevMappings : importYardiMappings,
        {
          projectId,
        },
        connection === API_MAPPING.DATEV ? isImportingDatevMappings : isImportingYardiMappings,
      );
    } catch (e) {
      console.error(e);
    }
  };

  const connections = useMemo(
    () => [
      {
        id: 'datev',
        name: API_MAPPING.DATEV as ApiConnectionType,
        icon: <img src={DATEV_LOGO_IMAGE} alt="DATEV Logo" className="w-14 h-14" />,
        items: datevData?.connectionResponse.datevProbisMapping
          ? groupMappingsByType(datevData.connectionResponse.datevProbisMapping)
          : [],
      },
      {
        id: 'yardi',
        name: API_MAPPING.YARDI as ApiConnectionType,
        icon: (
          <div className="w-7 h-7 bg-white text-xxs flex justify-center items-center text-slate-800">
            {API_MAPPING.YARDI}
          </div>
        ),
        items: yardiData?.mappings ? groupMappingsByType(yardiData.mappings) : [],
        disabled: !yardiData?.isYardiAccessActive,
      },
    ],
    [datevData, yardiData],
  );

  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const handleConnectDatev = async (createDatevAuthUrlResponseUrl: string) => {
    try {
      const response = await safeMutation(
        postCreateDatevCache,
        {
          body: {
            datevTenantSubdomain: subdomain ?? '',
            datevClientId: selectedClient.clientId,
            datevTenantAuthData: undefined,
            datevClientAuthData: undefined,
            datevUpdateData: {
              projectId: projectId,
              apiAccessId:
                externalApis
                  ?.find((api) => api.api.name === API_MAPPING.DATEV)
                  ?.accessItems.find((accessItem) => accessItem.isActive)?.id ?? '',
              projectView: loadedProjectId ? 'Dashboard' : 'Overview',
            },
            datevWizardType: 'Add',
          },
        },
        isCreatingDatevCache,
      );
      if (response?.key) {
        updateDatevAuthCookie(response?.key, subdomain);
        window.open(createDatevAuthUrlResponseUrl, '_self');
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpenDatevWizard = async () => {
    try {
      const response = await safeMutation(
        postCreateDatevCache,
        {
          body: {
            datevTenantSubdomain: subdomain ?? '',
            datevTenantAuthData: undefined,
            datevClientId: undefined,
            datevClientAuthData: undefined,
            datevUpdateData: undefined,
            datevWizardType: 'Add',
            datevApiAccessId: undefined,
          },
        },
        isCreatingDatevCache,
      );
      if (response?.key) {
        updateDatevAuthCookie(response?.key, subdomain);
        navigate({
          pathname: ROUTES_CONFIG.SETTINGS_CONNECT_DATEV.path.replace(':id', loadedProjectId ?? ''),
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!!selectedClient.clientId && selectedClient.clientId !== currentClient && selectedClientHasAccess) {
      try {
        safeMutation(
          updateActiveClient,
          {
            datevClientId: selectedClient.clientId,
            projectId: projectId,
          },
          isUpdatingDatevClient,
        );
      } catch (e) {
        console.error(e);
      }
    } else if (
      !!selectedClient.clientId &&
      selectedClient.clientId !== currentClient &&
      createDatevAuthUrlResponse?.url
    ) {
      handleConnectDatev(createDatevAuthUrlResponse?.url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, createDatevAuthUrlResponse]);

  const handleChangeAccountingMonth = async (month: string, year: string) => {
    if (projectId) {
      try {
        await safeMutation(
          updateAccountingMonth,
          {
            projectId: projectId,
            body: {
              accountingMonth: formatDateOnly(new Date(Number(year ?? 2000), Number(month ?? 0), 1)),
            },
          },
          isUpdatingAccountingMonth,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (selectedClient.clientId !== currentClient)
      setSelectedClient({
        clientId: datevData?.connectionResponse.clientDetail?.id ?? '',
        datevClientId: '',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datevData?.connectionResponse.clientDetail?.id]);

  const clientOptions = useMemo(() => {
    return (
      projectClients?.clients.map((client) => {
        return {
          label: client.name,
          value: client.id,
        };
      }) ?? []
    );
  }, [projectClients]);

  const listItems = useMemo(() => {
    return connections.map((connection) => {
      return connection.items?.map((item, i) => {
        return {
          id: item.name,
          name: item.name,
          disabled: true,
          children: expandedItems.includes(item.name) && (
            <ul className="w-full">
              {item.items.map((mapping, i) => (
                <MappingItem
                  key={mapping.id}
                  item={mapping}
                  connection={connection}
                  i={i}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              ))}
            </ul>
          ),
          icon: (
            <span className="flex h-full w-14 gap-2">
              <SortDownIcon
                className={classNames('h-5 cursor-pointer', !expandedItems.includes(item.name) && '-rotate-90')}
                onClick={(e) => {
                  e.stopPropagation();

                  expandedItems.includes(item.name)
                    ? setExpandedItems(expandedItems.filter((name) => name !== item.name))
                    : setExpandedItems([...expandedItems, item.name]);
                }}
              />
              <EditListIcon className="h-5" />
            </span>
          ),
          className: 'bg-white',
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connections, datevData, expandedItems, t, clientOptions]);

  return (
    <div>
      {isFetchingDatev ||
        isFetchingYardi ||
        isFetchingClients ||
        isUpdatingDatevClient ||
        isUpdatingAccountingMonth ||
        isImportingDatevMappings ||
        isImportingYardiMappings ||
        isFetching ||
        isFetchingDatevAuthUrl ? (
        <LoadingIndicator text={t('app.settingsApiMappingsLoading') ?? ''} mode="overlay" />
      ) : (
        <ul className="flex flex-col gap-5">
          {connections.filter((connection) => !connection.disabled).map((connection, i) => (
            <li key={connection.id} className="mb-5 relative">
              <SlideOverSortableList
                data={listItems[i] ?? []}
                headline={
                  i === 0 ? (
                    <span className="text-[13px] text-gray-400">{t('app.settingsApiDatevSynchronisation')}</span>
                  ) : undefined
                }
                bgColor={'bg-transparent'}
                noItemsMessage={t('app.settingsApiDatevNoConnections')}
                gridCols="grid-cols-3 gap-8"
                additionalTopChildren={
                  <div className="w-full h-[60px] bg-gray-200 flex justify-between items-center">
                    <div className="flex items-center justify-start w-8/12">
                      <div className="w-20 flex items-center justify-center">{connection.icon}</div>
                      <span className="text-lg font-bold mr-2">{connection.name}</span>
                    </div>
                    {connection.name === API_MAPPING.DATEV && datevData?.projectDatevOverview?.hasActiveDatevApi && (
                      <div className="flex">
                        {selectedClient.clientId && (
                          <div className="flex">
                            <DatevMappingHeaderMenu
                              className="w-60 mr-5"
                              label={t('app.settingsApiDatevDefaultYear')}
                              options={yearOptions}
                              value={selectedYear}
                              onChange={(value) => {
                                setSelectedYear(value);
                                if (value !== '') {
                                  handleChangeAccountingMonth(selectedMonth, value);
                                }
                              }}
                            />
                            <DatevMappingHeaderMenu
                              className="w-60 mr-5"
                              label={t('app.settingsApiDatevDefaultMonth')}
                              options={monthOptions}
                              value={selectedMonth}
                              onChange={(value) => {
                                setSelectedMonth(value);
                                if (value !== '') {
                                  handleChangeAccountingMonth(value, selectedYear);
                                }
                              }}
                            />
                          </div>
                        )}
                        {datevData?.projectDatevOverview?.hasDatevMappingImported && (
                          <DatevMappingHeaderMenu
                            className="w-60 mr-5"
                            label={t('app.settingsApiDatevConnectedClient')}
                            options={clientOptions.sort((a, b) => a.label.localeCompare(b.label))}
                            value={selectedClient.clientId}
                            onChange={(value) => {
                              setSelectedClient({
                                clientId: value,
                                datevClientId:
                                  projectClients?.clients.find((client) => client.id === value)?.clientId ?? '',
                              });
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                }
              />

              <div className="absolute right-10 -bottom-5 flex justify-center gap-2">
                {((connection.name === API_MAPPING.DATEV &&
                  datevData?.projectDatevOverview?.hasActiveDatevApi &&
                  isDatevImportEnabled) ||
                  (connection.name === API_MAPPING.YARDI &&
                    yardiData?.isYardiAccessActive &&
                    isYardiImportEnabled)) && (
                    <Button
                      onClick={() => importConnections(connection.name)}
                      variant="primary"
                      disabled={isImportingDatevMappings || isImportingYardiMappings}
                    >
                      {t('app.settingsApiDatevImportMapping')}
                    </Button>
                  )}

                {((connection.name === API_MAPPING.DATEV && selectedClient.clientId !== '' && !isDatevImportEnabled) ||
                  (connection.name === API_MAPPING.YARDI && yardiData?.isYardiAccessActive && !isYardiImportEnabled)) && (
                    <AddButton onClick={() => handleAdd(connection.name)} />
                  )}
              </div>

              {(connection.name === API_MAPPING.DATEV && !datevData?.projectDatevOverview?.hasActiveDatevApi) && (
                <HintBox hintType="info" className="mt-5 ">
                  <div className="h-full flex flex-col justify-center items-center">
                    <div className="p-2 mb-2 text-center">
                      {t('app.settingsApiNoClientConfigured', { api: connection.name })}
                    </div>
                    <Button
                      variant="primary"
                      onClick={handleOpenDatevWizard}
                    >
                      {t('app.settingsConnectMarketplace')}
                    </Button>
                  </div>
                </HintBox>
              )}
            </li>
          ))}
        </ul>
      )}

      <YardiMappingAddSlideOver
        isOpen={openModal === 'EditYardi'}
        onClose={() => {
          setOpenModal(null);
          setSelectedApiClientItem(null);
        }}
        item={selectedApiClientItem as YardiProbisMappingReadModel | null}
        projectId={projectId}
        connectionData={yardiData}
      />

      <DatevMappingAddSlideOver
        isOpen={openModal === 'EditDatev'}
        onClose={() => {
          setOpenModal(null);
          setSelectedApiClientItem(null);
        }}
        item={selectedApiClientItem as DatevProbisMappingReadModel | null}
        projectId={projectId}
        connectionData={datevData?.connectionResponse}
      />
      <Modal isOpen={openModal === 'Delete'} onClose={() => setOpenModal(null)}>
        <ApiClientMappingDeleteModal
          onClose={() => setOpenModal(null)}
          itemId={selectedApiClientItem?.id ?? ''}
          itemName={selectedApiClientItem?.name ?? ''}
          deleteItem={() =>
            selectedApiClientItem &&
            deleteItem(API_MAPPING.DATEV_PROPERTY_NAME in selectedApiClientItem ? API_MAPPING.DATEV : API_MAPPING.YARDI)
          }
          isLoading={isDeletingDatevProjectMapping || isDeletingYardiProjectMapping}
        />
      </Modal>
    </div>
  );
};

interface MappingItemProps {
  item: DatevProbisMappingReadModel | YardiProbisMappingReadModel;
  connection: { name: ApiConnectionType };
  i: number;
  handleEdit: (item: DatevProbisMappingReadModel | YardiProbisMappingReadModel, connection: ApiConnectionType) => void;
  handleDelete: (item: DatevProbisMappingReadModel | YardiProbisMappingReadModel) => void;
}

const MappingItem = ({ item, connection, i, handleEdit, handleDelete }: MappingItemProps) => {
  const { t } = useTranslation();

  const yardiReadOnly = connection.name === API_MAPPING.YARDI && 'isReadOnly' in item && item.isReadOnly;

  const mappingContextMenuItems: ContextMenuItem[] = [
    {
      label: t('common.edit'),
      subtitle: t('app.settingsApiDatevConnectionEdit'),
      icon: <PencilIcon />,
      onClick: () => {
        handleEdit(item, connection.name);
      },
      isDisabled: yardiReadOnly,
    },
    {
      label: t('common.delete'),
      subtitle: t('app.settingsApiDatevConnectionDelete'),
      icon: <TrashIcon />,
      onClick: () => {
        handleDelete(item);
      },
      stopPropagation: true,
      isDisabled: item.isMandatoryElement || yardiReadOnly,
    },
  ];
  const listItem = {
    id: item.id,
    name: (
      <div className="flex items-center gap-2 h-full">
        {item.name ?? '-'}
        {item.isMandatoryElement && (
          <span className="h-full">
            <FilledLockIcon className="h-2.5" />
          </span>
        )}
      </div>
    ),
    disabled: yardiReadOnly,
    description: item.description,
    icon: <SortingArrowsIcon className="rotate-90 h-6" />,
    cols: [
      {
        value:
          API_MAPPING.DATEV_PROPERTY_NAME in item
            ? (item as DatevProbisMappingReadModel).datevPropertyName
            : (item as YardiProbisMappingReadModel).yardiPropertyName,
        title: t(
          connection.name === API_MAPPING.DATEV
            ? 'app.settingsApiDatevConnectionDatevId'
            : 'app.settingsApiYardiProperty',
        ),
      },
      {
        value: item.probisPropertyName ?? '-',
        title: t('app.settingsApiDatevConnectionProbisObjectId'),
      },
      {
        value: item.userDefinedFieldName ?? '-',
        title: t('app.settingsApiDatevConnectionCustomField'),
      },
      {
        value: item.defaultValue ?? '-',
        title: t('app.settingsApiDatevConnectionDefaultValue'),
      },
    ],
    contextMenu: <ContextMenu items={mappingContextMenuItems} stopPropagation />,
    className: 'bg-white',
  };

  return (
    <li className={classNames('w-full')} key={`slide-over-list-item-${item.id}-${i}`}>
      <SlideOverSortableListItem
        item={listItem}
        gridCols="grid-cols-4 gap-8"
        level={2}
        earlyCompact={false}
        onClick={() => !yardiReadOnly && handleEdit(item, connection.name)}
      />
    </li>
  );
};
