import React from 'react';

export interface ProgressBarProps {
  progress: number; // Progress percentage (0-100)
  color?: string; // Optional color for the progress bar
  height?: string; // Optional height for the bar
  text?: string; // Optional text to display inside the progress bar
  endContent?: string; // Optional content to display at the end
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  color = 'bg-blue-500',
  height = 'h-6',
  text,
  endContent,
}) => {
  return (
    <span className={`w-full bg-gray-200 rounded-full overflow-hidden relative flex items-center ${height}`}>
      <span
        className={`transition-all duration-300 ease-in-out ${color} h-full rounded-full flex items-center justify-center px-2 text-white font-bold text-[12px]`}
        style={{ width: `${Math.min(Math.max(progress, 0), 100)}%` }}
      >
        {progress >= 60 && (endContent ? endContent + ' - ' : '')}
        {text ? `${text} - ${Math.trunc(progress)}%` : `${Math.trunc(progress)}%`}
      </span>
      {progress <= 60 && endContent && (
        <span className="ml-2 text-gray-700 font-semibold text-[12px]">{endContent}</span>
      )}
    </span>
  );
};

