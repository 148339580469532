import {
  DocumentViewer,
  DocumentPositionBoundingBoxReadModel,
  DocumentViewerFileDataGroup,
} from '@client/shared/toolkit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiEvalG702ResultReadModel } from '@client/shared/api';
import cn from 'classnames';
import { formatNumber } from '@client/shared/utilities';

export interface G702DocumentViewerProps {
  invoiceDocument?: AiEvalG702ResultReadModel;
  file: File | null;
  setFile: (file: File) => void;
  boxes?: (DocumentPositionBoundingBoxReadModel | null)[];
  pdfFile: string | null;
  imageWidth: number;
  imageHeight: number;
  allBoundingBoxes?: {
    boxes: number[][][];
    pages: number[];
    texts: string[][];
  } | null;
  loadedProjectId?: string;
  setIsFormValid?: (isValid: boolean) => void;
  onClose: () => void;
  className?: string;
  setIsLoading?: (isLoading: boolean) => void;
  rawResult?: any;
}

export const G702DocumentViewer = (props: G702DocumentViewerProps) => {
  const { invoiceDocument, file, pdfFile, boxes, imageHeight, imageWidth, allBoundingBoxes, className, rawResult } =
    props;
  const { t } = useTranslation();

  const [hoveredBox, setHoveredBox] = useState<number | null>(null);
  const [showResult, setShowResult] = useState(false);

  const result = invoiceDocument?.result;

  return (
    <div className={cn('flex flex-wrap', className)}>
      <div className={cn('w-full relative pb-8 xl:w-2/3')}>
        <div className="sticky top-0">
          <DocumentViewer
            menuOptions={{
              fullscreen: false,
            }}
            title={t('projectControl.invoice')}
            file={file}
            pdfFile={pdfFile}
            fileLoaded={invoiceDocument?.result !== null}
            loadingMessage={t('projectControl.uploadInvoiceLoadingIndicator')}
            uploadDescription={t('projectControl.invoiceUploadMessage')}
            boxes={boxes}
            hoveredBox={hoveredBox}
            setHoveredBox={setHoveredBox}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            allBoundingBoxes={allBoundingBoxes}
            showAllBoxes={false}
          />
        </div>
      </div>
      <div className="flex-none w-full xl:w-1/3 xl:pl-8 xl:min-w-[352px] flex flex-col gap-4 overflow-hidden text-xs">
        {result && result.owner && (
          <DocumentViewerFileDataGroup className="relative">
            <div>
              <div>
                <div className="font-semibold mb-2">
                  Owner
                </div>
                <div>
                  {result.owner?.name}
                </div>
                <div>
                  {result.owner?.address}
                </div>
              </div>
            </div>
        </DocumentViewerFileDataGroup>
        )}
        {result && result.contractor && (
          <DocumentViewerFileDataGroup className="relative">
            <div>
              <div>
                <div className="font-semibold mb-2">
                  Contractor
                </div>
                <div>
                  {result.contractor?.name}
                </div>
                <div>
                  {result.contractor?.address}
                </div>
              </div>
            </div>
        </DocumentViewerFileDataGroup>
        )}
        {result && (
          <DocumentViewerFileDataGroup className="relative">
            <div>
              <div>
                <div className="font-semibold mb-2">
                  Application for payment
                </div>
                <div className="flex flex-row">
                  <div className='flex-grow'>
                    Original contract sum
                  </div>
                  <div>
                    {formatNumber(result.originalContractSum_1, {maxDigits : 2, minDigits : 2})}
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className='flex-grow'>
                    Net change by change orders
                  </div>
                  <div>
                    {formatNumber(result.netChangeByChangeOrders_2, {maxDigits : 2, minDigits : 2})}
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className='flex-grow'>
                    Contract sum to date
                  </div>
                  <div>
                    {formatNumber(result.contractSumToDate_3, {maxDigits : 2, minDigits : 2})}
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className='flex-grow'>
                    Total completed and stored to date
                  </div>
                  <div>
                    {formatNumber(result.totalCompletedAndStored_4, {maxDigits : 2, minDigits : 2})}
                  </div>
                </div>
                <div className='mt-2'>
                  Retainage
                </div>
                <div className="flex flex-row">
                  <div className='flex-grow'>
                    ..from work completed
                  </div>
                  <div>
                    {formatNumber(result.retainageCompletedWork_5a, {maxDigits : 2, minDigits : 2})}
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className='flex-grow'>
                    ..from materials stored
                  </div>
                  <div>
                    {formatNumber(result.retainageStoredMaterial_5b, {maxDigits : 2, minDigits : 2})}
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className='flex-grow'>
                    {/* empty */}
                  </div>
                  <div className="border-t border-gray-500">
                    {formatNumber(result.retainageTotal_5c, {maxDigits : 2, minDigits : 2})}
                  </div>
                </div>
                <div className="flex flex-row mt-2">
                  <div className='flex-grow'>
                    Total earned less retainage
                  </div>
                  <div>
                    {formatNumber(result.totalEarnedLessRetainage_6, {maxDigits : 2, minDigits : 2})}
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className='flex-grow'>
                    Less previous payments
                  </div>
                  <div>
                    {formatNumber(result.lessPreviousPayments_7, {maxDigits : 2, minDigits : 2})}
                  </div>
                </div>
                <div className="flex flex-row my-2 font-semibold border-b border-gray-500">
                  <div className='flex-grow'>
                    Current payment due
                  </div>
                  <div>
                    {formatNumber(result.currentPaymentDue_8, {maxDigits : 2, minDigits : 2})}
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className='flex-grow'>
                    Balance to finish
                  </div>
                  <div>
                    {formatNumber(result.balanceToFinish_9, {maxDigits : 2, minDigits : 2})}
                  </div>
                </div>
              </div>
            </div>
        </DocumentViewerFileDataGroup>
        )}
        {result?.lineItems && (
          <DocumentViewerFileDataGroup className="relative">
              <div>
                <div>
                  <div className="font-semibold mb-2">
                    Continuation sheet
                  </div>
                  {result.lineItems.map((item, idx) => (
                    <div key={idx} className='mb-1'>
                      <div className='flex flex-row'>
                        <div className='flex-grow font-semibold'>
                          {item.code}
                        </div>
                        <div>
                        {formatNumber(item.thisPeriod, {maxDigits : 2, minDigits : 2})}
                        </div>
                      </div>
                      <div className='flex flex-row text-xs text-gray-600'>
                        <div className='flex-grow'>
                          {item.description}
                        </div>
                        <div>
                        {formatNumber(item.completedPercent, {maxDigits : 1, minDigits : 0})}%
                        </div>
                      </div>                        
                    </div>
                  ))}
                </div>
              </div>
          </DocumentViewerFileDataGroup>
        )}
        </div>
    </div>
  );
};
