import { type FilterGroup, type FilterOption, SearchFilter, SearchIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import React, { type PropsWithChildren } from 'react';

interface SettingsHeaderProps extends PropsWithChildren {
  setSearchText?: (value: string) => void
  searchText?: string
  filterGroups?: FilterGroup[];
  handleFilterSearch?: (value: string, filters: FilterOption[]) => void;
  toggledFilters?: string[]
}
export const SettingsHeader = (props: SettingsHeaderProps) => {
  const { t } = useTranslation()
  const {
    searchText = '',
    setSearchText,
    children,
    filterGroups,
    handleFilterSearch,
    toggledFilters
  } = props
  return (
    <div className="flex items-end justify-end border-b pb-2 flex-wrap w-full gap-x-4">
      <div className="text-2xl lg:text-4xl text-gray-300 flex-1">
        {children}
      </div>
      {setSearchText && !filterGroups?.length && (
        <div className="justify-end">
          <div className="relative text-gray-600">
            <SearchIcon className="absolute left-0 top-0 mt-3.5 ml-3.5 h-5 w-5" />
            <input
              value={searchText}
              type="search"
              placeholder={t('common.search') ?? ''}
              className="bg-gray-100 h-12 px-5 pl-10 rounded-full text-sm focus:outline-none border-none"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
      )}
      {setSearchText && filterGroups && filterGroups.length > 0 && (
        <SearchFilter
          filterGroups={filterGroups}
          handleSearch={(value, filters) => {
            if (handleFilterSearch) {
              handleFilterSearch(value, filters);
            } else {
              setSearchText(value);
            }
          }}
          updateSearchValue={setSearchText}
          searchValue={searchText ?? ''}
          className="h-12"
          toggledFilters={toggledFilters}
        />
      )}
    </div>
  )
}
