import cn from 'classnames';

export interface HierarchyIndicatorProps {
  className?: string;
  size?: string;
}
export const HierarchyIndicator = (props: HierarchyIndicatorProps) => {
  const { className, size = 'w-[0.5625rem] h-[0.5625rem]' } = props;

  return (
    <div className={cn('p-px absolute -mt-1 rounded-full bg-white shadow border-2 border-slate-400 z-[5]', size, className)} />
  )
}
