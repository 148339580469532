import cn from 'classnames';
import { SortDownIcon } from '../../icons';

export interface SlideOverSortableListHeaderItemProps {
  className?: string
  onClick?: () => void
  label: string
  asc: boolean | null
  alignRight?: boolean
}

export const SlideOverSortableListHeaderItem = (props: SlideOverSortableListHeaderItemProps) => {
  const {
    className,
    onClick,
    label,
    asc,
    alignRight = true
  } = props
  return (
    <div
      className={cn('flex md:gap-1 lg:gap-2.5 items-center cursor-pointer text-gray-400 whitespace-nowrap', alignRight ? 'justify-end' : '', className)}
      onClick={onClick}
    >
      {label && (<span className="truncate">{label}</span>)}
      <SortDownIcon className={cn('w-7',
        {
          'rotate-180': asc || asc === null,
          'opacity-30': asc === null
        }
      )}
      />
    </div>
  )
}

