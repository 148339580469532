import React, { useState, useEffect, PropsWithChildren, useMemo } from 'react';
import { useApiGetExternalApisQuery, ExternalApiReadModel } from '@client/shared/api';
import { Button, DATEV_LOGO_IMAGE, LoadingIndicator, LOGO_YARDI_IMAGE } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { SlideOver } from '@client/shared/toolkit';
import { ApiSlideOver } from './ApiSlideOver';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ProtectedRoute, ROUTES_CONFIG } from '@client/shared/permissions';
import { DatevWizardView } from './Datev';
import { DatevConnectionEditView } from './Datev';
import { API_MAPPING } from '@client/project/shared';

interface MarketplaceProps {
  searchText: string | undefined;
}

export const Marketplace = ({ searchText }: MarketplaceProps) => {
  const { t } = useTranslation();
  const [isApiSlideOverOpen, setIsApiSlideOverOpen] = useState(false);
  const [selectedApi, setSelectedApi] = useState<ExternalApiReadModel | null>(null);
  const { data: externalApis, isFetching } = useApiGetExternalApisQuery();

  const selectApi = (api: ExternalApiReadModel) => {
    setSelectedApi(api);
    setIsApiSlideOverOpen(true);
  };

  useEffect(() => {
    if (selectedApi !== externalApis?.find((x) => x.api.id === selectedApi?.api.id))
      setSelectedApi(externalApis?.find((x) => x.api.id === selectedApi?.api.id) ?? null);
  }, [externalApis, selectedApi]);

  const datevApiId = useMemo(() => {
    return externalApis?.find((apiItem) => apiItem.api.name === API_MAPPING.DATEV)?.api?.id ?? '';
  }, [externalApis]);

  return (
    <div className="m-5 grid grid-cols-2">
      {isFetching === true && <LoadingIndicator text={t('app.settingsUsersLoading') ?? ''} />}

      {externalApis
        ?.filter((x) => x.api.name.toLowerCase().includes(searchText?.toLowerCase() ?? ''))
        .map((item: ExternalApiReadModel, i) => <ApiCard key={i} api={item} selectApi={selectApi} />)}
      <SlideOver isOpen={isApiSlideOverOpen} onClose={() => setIsApiSlideOverOpen(false)}>
        <ApiSlideOver api={selectedApi} onClose={() => setIsApiSlideOverOpen(false)} />
      </SlideOver>
      {/* ------------ CHILD ROUTES -------------*/}
      <Routes>
        <Route
          path={ROUTES_CONFIG.SETTINGS_CONNECT_DATEV.name}
          element={
            <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_CONNECT_DATEV}>
              <DatevWizardView apiId={datevApiId} />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES_CONFIG.SETTINGS_CONNECT_DATEV_EDIT.name}
          element={
            <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_CONNECT_DATEV_EDIT}>
              <DatevConnectionEditView apiId={datevApiId} />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to='' />} />
      </Routes>
    </div>
  );
};

interface ApiCardProps extends PropsWithChildren {
  api: ExternalApiReadModel;
  selectApi?: (api: ExternalApiReadModel) => void;
}

const ApiCard = ({ api, selectApi, children }: ApiCardProps) => {
  const isDatev = api.api.name === API_MAPPING.DATEV;
  const isYardi = api.api.name === API_MAPPING.YARDI;

  const { t } = useTranslation();
  return (
    <div className="m-5 flex">
      <div className="h-28 w-28 border rounded-md flex justify-center items-center text-xs text-slate-600">
        {isDatev ? <img src={DATEV_LOGO_IMAGE} alt='DATEV Logo' /> : isYardi ? <img src={LOGO_YARDI_IMAGE} alt='YARDI Logo' className="p-1" /> : api.api.name}
      </div>
      <div className="pl-5 pt-2 min-h-20 w-full">
        <div className="font-bold text-lg">
          {isDatev ? t('api.datev') : isYardi ? t('api.yardi') : api.api.name}
          {api.api.isActive && <span className="ml-1 text-sm text-green-600">{t('app.ConnectActive')}</span>}
        </div>
        <div className="text-sm text-slate-400 line-clamp-2" title={t(api.api.description ?? '')}>{t(api.api.description ?? '')}</div>

        {children ? (
          children
        ) : (
          <Button
            variant={api.api.isEnabled ? 'primary' : 'secondary'}
            className="mt-3"
            onClick={() => (api.api.isEnabled && selectApi ? selectApi(api) : undefined)}
            disabled={!api.api.isEnabled}
          >
            {t('app.ConnectSetup')}
          </Button>
        )}
      </div>
    </div>
  );
};
