import { apiEndpointUrls, CoverImageSize, ProjectAssignmentInfo, ProjectReadModel, ShortProjectGroupReadModel, TaxonomyReadModel, TrustedProjectReadModel } from "@client/shared/api";
import {
  PROJECT_PLACEHOLDER_1_IMAGE,
  PROJECT_PLACEHOLDER_2_IMAGE,
  PROJECT_PLACEHOLDER_3_IMAGE,
  PROJECT_PLACEHOLDER_4_IMAGE,
  PROJECT_PLACEHOLDER_5_IMAGE,
  PROJECT_PLACEHOLDER_6_IMAGE,
  PROJECT_PLACEHOLDER_EMPTY_IMAGE
} from '@client/shared/toolkit/images/project-placeholder';

import {
  PARKING_PLACEHOLDER_IMAGE,
} from '@client/shared/toolkit';
const PROJECT_PLACEHOLDER_IMAGES = [
  PROJECT_PLACEHOLDER_1_IMAGE,
  PROJECT_PLACEHOLDER_2_IMAGE,
  PROJECT_PLACEHOLDER_3_IMAGE,
  PROJECT_PLACEHOLDER_4_IMAGE,
  PROJECT_PLACEHOLDER_5_IMAGE,
  PROJECT_PLACEHOLDER_6_IMAGE
];

const getProjectThumbnailPath = (name: string, size: CoverImageSize, projectId?: string, coverPictureId?: string | null, group = false) => {
    if (group) {
      return projectId && coverPictureId
        ? apiEndpointUrls.apiGetProjectGroupCover.replace(':groupId', projectId).replace(':imageId', coverPictureId).replace(':size', size)
        : getRandomProjectThumbnail(name);
    }
    return projectId && coverPictureId && size
      ? apiEndpointUrls.apiGetProjectCover.replace(':projectId', projectId).replace(':imageId', coverPictureId).replace(':imageSize', size)
      : getRandomProjectThumbnail(name);
};

const getRandomProjectThumbnail = (name: string) => {
  const number = (name.length % 6) + 1;
  return PROJECT_PLACEHOLDER_IMAGES[number - 1];
};

export const getTaxonomyThumbnail = (project?: ProjectReadModel, taxonomyItem?: TaxonomyReadModel) => {
  if (project == null) return PROJECT_PLACEHOLDER_EMPTY_IMAGE;

  const thumbnail = taxonomyItem?.files?.find((file) => file.fileType === 'Thumbnail');

  if (thumbnail) {
    return apiEndpointUrls.apiGetTaxonomyItemFile
            .replace(':projectId', project.payload.id)
            .replace(':calculationModelId', taxonomyItem?.calculationModelId ?? '')
            .replace(':taxonomyItemId', taxonomyItem?.itemId ?? '')
            .replace(':fileId', thumbnail.id);
  }

  if (!thumbnail && taxonomyItem?.itemType === 'Parking') return PARKING_PLACEHOLDER_IMAGE;

  const number = (project.payload.name.length % 6) + 1;

  return project.payload.coverPictureId
    ? getProjectThumbnail('Medium', project.payload, project.payload.coverPictureId)
    : PROJECT_PLACEHOLDER_IMAGES[number - 1];
};

export const getCrossTenantProjectThumbnail = (project?: TrustedProjectReadModel) => {
  if (project == null) return PROJECT_PLACEHOLDER_EMPTY_IMAGE;

  return project.coverPictureId
    ? apiEndpointUrls.apiGetCrossTenantProjectCover.replace(':crossTenantId', project.tenantId)
        .replace(':crossTenantProjectId', project.id)
        .replace(':imageId', project.coverPictureId)
    : getRandomProjectThumbnail(project.name);
};

export const getProjectThumbnail = (size: CoverImageSize, project?: ProjectAssignmentInfo | ShortProjectGroupReadModel, coverId: string | null = null, group = false) => {
  if (project == null) return PROJECT_PLACEHOLDER_EMPTY_IMAGE;

  return getProjectThumbnailPath(project.name, size, project.id, coverId, group);
};
