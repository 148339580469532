import { BillIcon, PriceTagUSDIcon } from "@client/shared/toolkit";
import { AmountFactorInput } from "./AmountFactorInput";
import { useTranslation } from "react-i18next";
import { CostElementReadmodel } from "@client/shared/api";
import { UnitFactorInput } from "./UnitFactorInput";
import { produce } from "immer";
import { useEffect, useState } from "react";

interface CostFormulaInputProps {
  value: CostElementReadmodel;
  onChange: (value: CostElementReadmodel) => void;
  disabled?: boolean;
  catalogElementId?: string;
  hasBudget: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const CostFormulaInput = ({ onChange, value: factor, disabled, catalogElementId, hasBudget, setIsLoading }: CostFormulaInputProps) => {
  const { t } = useTranslation();
  const [isLoadingAmountDataset, setIsLoadingAmountDataset] = useState(false);
  const [isLoadingUnitDataset, setIsLoadingUnitDataset] = useState(false);

  useEffect(() => {
    setIsLoading(isLoadingAmountDataset || isLoadingUnitDataset);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingAmountDataset, isLoadingUnitDataset]);

  const onChangeDecorated = (model: CostElementReadmodel) => {
    onChange(produce(model, (draft) => {
      draft.calculatedValue = calculateValue(draft);
    }));
  };

  const calculateValue = (model: CostElementReadmodel): number => {
    const isPercentage = model.amount.staticValue?.unit === '%';

    let amount = 0;
    switch (model.amount.type) {
      case 'Static':
        amount = model.amount.staticValue?.value ?? 0;
        break;
      case 'Formula':
        amount = model.amount?.formulaValue?.value ?? 0;
        break;
    }

    let unitPrice = 0;
    switch (model.unitPrice.type) {
      case 'Static':
        unitPrice = model.unitPrice.staticValue?.value ?? 0;
        break;
      case 'Formula':
        unitPrice = model.unitPrice.formulaValue?.value ?? 0;
        break;
    }

    return amount * unitPrice * (isPercentage ? 0.01 : 1);
  };

  return (
    <div className="relative">
      <div className="bg-white">
        <div className="divide-gray-100 divide-y">
          <AmountFactorInput
            label={t('projectCalculate.formulaLabelAmount')}
            icon={<BillIcon className="fill-black" />}
            factor={factor.amount}
            disabled={disabled}
            onChange={(amount) => onChangeDecorated({ ...factor, amount })}
            showFX={!hasBudget}
            catalogElementId={catalogElementId}
            catalogElementType='CostElement'
            setIsLoading={setIsLoadingAmountDataset}
          />
          <UnitFactorInput
            label={t('projectCalculate.formulaLabelUnitPrice')}
            icon={<PriceTagUSDIcon />}
            factor={factor.unitPrice}
            disabled={disabled}
            onChange={(unitPrice) => onChangeDecorated({ ...factor, unitPrice })}
            catalogElementId={catalogElementId}
            catalogElementType='CostElement'
            showFX={!hasBudget}
            setIsLoading={setIsLoadingUnitDataset}
          />
        </div>
      </div>
    </div>
  );
};
