import * as yup from 'yup';
import { InferType } from 'yup';
import { WidgetDashboardEditFormProps } from './WidgetEditFormDefault';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  AddButton,
  BaseSelect,
  BaseSelectOption,
  Form,
  FormField,
  FormRefHandle,
  SlideOver,
  SlideOverTitle,
  TextInput,
  TrashIcon,
} from '@client/shared/toolkit';
import { FinancialUsageWidgetColumnType } from '@client/shared/api';
import { FinancialElementsSelect } from '../../ElementSelects';
import cn from 'classnames';

export const WidgetDashboardEditFormFinancialUsageValidationSchema = yup.object({
  title: yup.string().required('validation.required'),
  size: yup.string().required('validation.required'),
  calculationModelId: yup.string().required('validation.required'),
  financingElements: yup
    .array()
    .of(yup.string().required('validation.required'))
    .min(1)
    .required('validation.required'),
  columns: yup
    .array()
    .of(yup.mixed<FinancialUsageWidgetColumnType>().required('validation.required'))
    .min(1)
    .max(3)
    .required('validation.required'),
});

export type WidgetDashboardEditFormFinancialUsageValidationValues = InferType<
  typeof WidgetDashboardEditFormFinancialUsageValidationSchema
>;

const DEFAULT_FINANCIAL_USAGE_COLUMN_TYPE: FinancialUsageWidgetColumnType = 'Total';

export const WidgetEditFormFinancialUsage = (props: WidgetDashboardEditFormProps) => {
  const { buttons, widget, handleSubmit, project, sizeOptions, selectedSize } = props;
  const { t } = useTranslation();

  const formRef = useRef<FormRefHandle<WidgetDashboardEditFormFinancialUsageValidationValues>>();
  const [columns, setColumns] = useState<FinancialUsageWidgetColumnType[]>([DEFAULT_FINANCIAL_USAGE_COLUMN_TYPE]);
  const [selectedFinancialElements, setSelectedFinancialElements] = useState<string[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const initialBudgetOptions: BaseSelectOption[] = useMemo(() => {
    return project.calculationModels.filter((model) => ['Version', 'Variant'].includes(model.type as string)).map((model) => {
      return {
        label: model.name,
        value: model.id
      };
    });
  }, [project.calculationModels]);

  useEffect(() => {
    setColumns(widget?.widget.additionalConfig?.FinancialUsage?.columns ?? [DEFAULT_FINANCIAL_USAGE_COLUMN_TYPE]);
    setSelectedFinancialElements(widget?.widget.additionalConfig?.FinancialUsage?.financingElements ?? []);

  }, [widget?.widget.additionalConfig?.FinancialUsage]);

  const defaultFormValues = useMemo(() => {
    return {
      title: widget?.widget.title ?? t('dashboard.widget.financialUsage.title'),
      size: selectedSize,
      calculationModelId: widget?.widget.additionalConfig?.FinancialUsage?.calculationModelId || initialBudgetOptions[initialBudgetOptions.length - 1].value,
      financingElements: widget?.widget.additionalConfig?.FinancialUsage?.financingElements ?? [],
      columns: widget?.widget.additionalConfig?.FinancialUsage?.columns ?? [DEFAULT_FINANCIAL_USAGE_COLUMN_TYPE],
    };
  }, [t, widget?.widget.title, selectedSize, widget?.widget.additionalConfig?.FinancialUsage]);

  const onAddColumn = () => {
    if (columns.length < 3) {
      const copy = [...columns];
      copy.push(DEFAULT_FINANCIAL_USAGE_COLUMN_TYPE);
      setColumns(copy);
      formRef.current?.setValue('columns', copy);
    }
  };

  const onUpdateColumn = (type: FinancialUsageWidgetColumnType, index: number) => {
    if (columns[index]) {
      const copy = [...columns];
      copy[index] = type;
      setColumns(copy);
      formRef.current?.setValue('columns', copy);
    }
  };

  const onDeleteColumn = (index: number) => {
    if (columns[index]) {
      const copy = [...columns];
      copy.splice(index, 1);
      setColumns(copy);
      formRef.current?.setValue('columns', copy);
    }
  };

  const columnsLimit = 3;

  useEffect(() => {
    formRef.current?.setValue('financingElements', selectedFinancialElements);
  }, [selectedFinancialElements]);

  return (
    <Form<WidgetDashboardEditFormFinancialUsageValidationValues>
      onSubmit={handleSubmit}
      validationSchema={WidgetDashboardEditFormFinancialUsageValidationSchema}
      defaultValues={defaultFormValues}
      className="flex flex-col flex-grow min-h-0"
      ref={formRef}
    >
      <SlideOver.Content className="p-8">
        <FormField name="title">
          {(control) => <TextInput label={t('dashboard.widget.edit.title')} {...control} />}
        </FormField>
        <FormField name="size">
          {(control) => <BaseSelect label={t('dashboard.widget.size')} options={sizeOptions} {...control} />}
        </FormField>
        <FormField name="calculationModelId">
          {(control) => <BaseSelect label={t('project.variant')} options={initialBudgetOptions} {...control} />}
        </FormField>
        <SlideOverTitle
          title={t('dashboard.widget.financialUsage.sources')}
          className="w-full flex gap-2 flex-wrap items-center justify-between"
        />
        <FinancialElementsSelect
          label={t('dashboard.widget.financialUsage.financialElements')}
          calculationModelId={formRef.current?.getValues().calculationModelId as string}
          selectedFinancialElements={selectedFinancialElements}
          setSelectedFinancialElements={setSelectedFinancialElements}
        />
        <SlideOverTitle
          title={t('dashboard.widget.financialUsage.columnsTitle')}
          className="w-full flex gap-2 flex-wrap items-center justify-between"
        >
          <span className="text-xs">{`${columns.length} / ${columnsLimit}`}</span>
        </SlideOverTitle>
        {columns.map((type, index) => (
          <FinancialUsageColumnForm
            key={`financial-usage-column-form-${index}`}
            type={type}
            index={index}
            onUpdate={(data: FinancialUsageWidgetColumnType) => onUpdateColumn(data, index)}
            canDelete={columns.length > 1}
            onDelete={() => onDeleteColumn(index)}
            handlePopoverVisibility={(isOpen) => setIsDropdownOpen(isOpen)}
          />
        ))}
        {columns.length < 2 && (
          <div className={cn('flex w-full justify-end items-center z-50 relative', { 'opacity-0 pointer-events-none': isDropdownOpen })}>
            <AddButton onClick={onAddColumn} className="-mt-4" />
          </div>
        )}
      </SlideOver.Content>
      {buttons}
    </Form>
  );
};

export interface FinancialUsageColumnFormProps {
  type: FinancialUsageWidgetColumnType;
  onUpdate: (type: FinancialUsageWidgetColumnType) => void;
  index: number;
  canDelete: boolean;
  onDelete: () => void;
  handlePopoverVisibility?: (isOpen: boolean) => void;
}

export const FinancialUsageColumnForm = (props: FinancialUsageColumnFormProps) => {
  const { type, index, canDelete, onDelete, onUpdate, handlePopoverVisibility } = props;
  const { t } = useTranslation();

  const options: BaseSelectOption[] = useMemo(() => {
    return [
      {
        value: 'Total',
        label: t('dashboard.widget.financialUsage.columnType.total'),
      },
      {
        value: 'TotalPercentage',
        label: t('dashboard.widget.financialUsage.columnType.totalPercentage'),
      }
    ];
  }, [t]);

  return (
    <div className="flex justify-between items-center max-w-full gap-2 mt-1">
      <BaseSelect
        className="flex-1 truncate"
        options={options}
        label={`${t('dashboard.widget.financialUsage.columnType.label')} ${index + 1}`}
        value={type}
        onChange={(val) => onUpdate(val as FinancialUsageWidgetColumnType)}
        handlePopoverVisibility={handlePopoverVisibility}
      />
      {canDelete && <TrashIcon className="text-gray-500 w-5 h-5 cursor-pointer flex-none" onClick={onDelete} />}
    </div>
  );
};
