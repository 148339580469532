import { Fragment, useEffect, useMemo, useState } from 'react';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { UserDefinedFieldsSearch } from '../UserDefinedFields';
import { useTranslation } from 'react-i18next';
import {
  TenantCatalogType
} from '@client/shared/api';
import { Button, CheckBox, HierarchyIndicator, TextHighlighter, TriangleIcon } from '@client/shared/toolkit';
import cn from 'classnames';

export interface GenericElementMultiSelectProps {
  selectedElements: string[];
  updateSelectedElements: (elements: string[]) => void;
  onClose: () => void;
  showControls?: boolean;
}

export type MultiSelectGroup = {
  id: string;
  elementId: string; // for udf search
  description?: string;
  code: string;
  children: MultiSelectGroup[];
  elements: MultiSelectGroup[];
  vat?: number;
  hasFormula?: boolean;
};

export interface ElementMultiSelectProps {
  elements: MultiSelectGroup[];
  elementIds: string[];
  selected: string[];
  type: TenantCatalogType;
  onClose: () => void;
  showControls?: boolean;
  updateSelectedElements: (elements: string[]) => void;
  showVat?: boolean;
  showFx?: boolean;
  useStore?: boolean;
}

/**
 * Multi select of elements of a specific tenant catalog type, e.g. Earnings.
 * Elements can be filtered by a search or UDFs.
 */
export const ElementMultiSelect = (props: ElementMultiSelectProps) => {
  const {
    elements,
    elementIds,
    selected,
    type,
    onClose,
    updateSelectedElements,
    showControls = false,
    showVat = false,
    showFx = true,
    useStore = true,
  } = props;

  const { t } = useTranslation();
  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();

  const [selectedElements, setSelectedElements] = useState<string[]>(selected);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [udfSearchResults, setUdfSearchResults] = useState<string[]>([]);
  const [isUdfFiltersSet, setIsUdfFiltersSet] = useState(false);

  const addOrRemoveExpandedId = (elementId: string) => {
    if (expandedIds.includes(elementId)) {
      setExpandedIds((prev) => prev.filter((x) => x !== elementId));
    } else {
      setExpandedIds((prev) => [...prev, elementId]);
    }
  };

  useEffect(() => {
    setSelectedElements(selected);
  }, [selected]);

  useEffect(() => {
    // directly save the selected items if no controls are shown
    if (!showControls && selectedElements !== selected) {
      updateSelectedElements(selectedElements);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedElements]);

  // filter by search value and udf filter results
  const filteredElements = useMemo(() => {
    const filtered = [...elements];
    if (searchValue) {
      const searchText = searchValue.toLowerCase();
      const filterElements = (
        data: MultiSelectGroup[],
        filteredIds: string[],
      ): {
        result: MultiSelectGroup[];
        ids: string[];
      } => {
        const filteredGroups: MultiSelectGroup[] = [];

        data.forEach((group) => {
          const groupCopy = { ...group };
          let matchesSearch =
            isUdfFiltersSet ? udfSearchResults.includes(group.elementId)
              : group.code?.toLowerCase().includes(searchText) || group.description?.toLowerCase().includes(searchText);
          if (group.children.length) {
            const filteredChildren = filterElements(groupCopy.children, filteredIds);
            groupCopy.children = filteredChildren.result;
            if (filteredChildren.ids?.length) {
              filteredIds = filteredChildren.ids;
            }
            matchesSearch = matchesSearch || groupCopy.children.length > 0;
          }
          if (group.elements.length) {
            const filteredElements = filterElements(groupCopy.elements, filteredIds);
            groupCopy.elements = filteredElements.result;
            if (filteredElements.ids.length) {
              filteredIds = filteredElements.ids;
            }
            matchesSearch = matchesSearch || groupCopy.elements.length > 0;
          }
          if (matchesSearch) {
            filteredGroups.push(groupCopy);
            filteredIds.push(groupCopy.id);
          }
        });
        return {
          result: filteredGroups,
          ids: filteredIds,
        };
      };
      return filterElements(filtered, []);
    }

    return {
      result: filtered,
      ids: [],
    };
  }, [elements, searchValue, udfSearchResults, isUdfFiltersSet]);

  useEffect(() => {
    setExpandedIds(filteredElements.ids);
  }, [filteredElements.ids]);

  const recursiveAddOrRemoveChildren = (group: MultiSelectGroup, action: 'add' | 'remove') => {
    if (action === 'add') {
      setSelectedElements((prev) => [...prev, group.id]);
      if (group.elements.length > 0) {
        setSelectedElements((prev) => [...prev, ...group.elements.map((x) => x.id)]);
      }
    } else if (action === 'remove') {
      setSelectedElements((prev) => prev.filter((x) => x !== group.id));
      if (group.elements.length > 0) {
        setSelectedElements((prev) => prev.filter((x) => !group.elements.find((y) => y.id === x)));
      }
    }

    if (group.children.length > 0) {
      group.children.forEach((child) => {
        recursiveAddOrRemoveChildren(child, action);
      });
    }
  };

  const renderRow = (group: MultiSelectGroup, level = 0, isElement = false, isLast = false) => {
    const elementId = group.id;
    const isExpanded = expandedIds.includes(elementId);

    return (
      <>
        <div className="flex items-center divide-y">
          {group.children.length > 0 || group.elements.length > 0 ? (
            <ElementMultiSelectToggle isExpanded={isExpanded} onToggle={() => addOrRemoveExpandedId(elementId)} />
          ) : (
            <div className="w-4 h-4 mr-2" />
          )}
          <div
            className={cn('bg-white w-full p-2 flex items-center truncate text-ellipsis justify-between', {
              'font-bold': level === 0,
            })}
          >
            <ElementMultiSelectHasElementsIndicator
              isInvisible={isElement ? false : !isExpanded || !group.elements.length}
              type={isElement ? (isLast ? 'last' : 'middle') : 'first'}
            />
            <ElementMultiSelectLabel
              vat={showVat ? group.vat : undefined}
              hasFormula={showFx ? !!group.hasFormula : false}
              description={group.description}
              code={group.code}
              searchValue={searchValue}
              className={isElement ? 'text-slate-600' : ''}
            />
            <div className="flex flex-none justify-end">
              <CheckBox
                checked={selectedElements.includes(elementId)}
                onChange={(value) => {
                  if (isElement) {
                    if (value) {
                      setSelectedElements((prev) => [...prev, elementId]);
                    } else {
                      setSelectedElements((prev) => prev.filter((x) => x !== elementId));
                    }
                  } else {
                    if (value) {
                      recursiveAddOrRemoveChildren(group, 'add');
                    } else {
                      recursiveAddOrRemoveChildren(group, 'remove');
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
        {isExpanded && (
          <>
            {/* Group elements */}
            {group.elements.length > 0 &&
              group.elements.map((groupElement, i) => (
                <Fragment key={`group-element-${groupElement.id}-${level}-${i}`}>
                  {renderRow(groupElement, level + 1, true, i === group.elements.length - 1)}
                </Fragment>
              ))}
            {/* Group children */}
            {group.children.length > 0 &&
              group.children.map((groupChild, i) => (
                <Fragment key={`group-child-group-${groupChild.id}-${level}-${i}`}>
                  {renderRow(groupChild, level + 1)}
                </Fragment>
              ))}
          </>
        )}
      </>
    );
  };

  return (
    <div
      className="flex flex-col h-full justify-between w-full"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          updateSelectedElements(selectedElements);
          onClose();
        }
      }}
      tabIndex={0}
    >
      <div className="w-full mb-2 pl-6">
        <UserDefinedFieldsSearch
          searchValue={searchValue}
          updateSearchResults={(result: string[]) => {
            setUdfSearchResults(result);
          }}
          handleSearchValueUpdate={(val) => {
            setSearchValue(val);
          }}
          setUdfFilters={(filters: string[]) => {
            setIsUdfFiltersSet(filters.length > 0);
          }}
          udfElementTypes={type === 'Costs' ? ['Cost'] : ['Earning']}
          searchDisabled={!loadedVariantId || !loadedProjectId}
          filterStore={type === 'Costs' ? 'Cost' : 'Earning'}
          useStore={useStore}
        />
      </div>
      <div className="overflow-y-auto h-full">
        {filteredElements.result.map((group) => (
          <Fragment key={`group-${group.id}`}>{renderRow(group)}</Fragment>
        ))}
      </div>

      {showControls && (
        <div className="flex justify-between items-center h-18 pt-2 border-t">
          <Button
            variant="text"
            className="mr-5"
            onClick={() => {
              if (selectedElements.length === elementIds.length) {
                setSelectedElements([]);
              } else {
                setSelectedElements(elementIds);
              }
            }}
          >
            {selectedElements.length === elementIds.length ? t('common.deselectAll') : t('common.selectAll')}
          </Button>
          <div className="flex gap-2">
            <Button variant="secondary" onClick={onClose}>
              {t('common.cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                updateSelectedElements(selectedElements);
                onClose();
              }}
            >
              {t('common.select')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export interface ElementMultiSelectToggleProps {
  onToggle: () => void;
  isExpanded: boolean;
}

export const ElementMultiSelectToggle = (props: ElementMultiSelectToggleProps) => {
  const { onToggle, isExpanded } = props;
  return (
    <div className="w-4 h-4 mr-2 flex items-center justify-center hover:bg-gray-100 cursor-pointer" onClick={onToggle}>
      {isExpanded ? (
        <TriangleIcon className="w-3 h-3 text-gray-500 rotate-90" />
      ) : (
        <TriangleIcon className="w-3 h-3 text-gray-500" />
      )}
    </div>
  );
};

export const ElementMultiSelectHasElementsIndicator = ({
  type,
  isInvisible = false,
}: {
  type: 'middle' | 'last' | 'first';
  isInvisible?: boolean;
}) => {
  return (
    <div className="w-2 mr-2 flex justify-center items-center">
      <div
        className={cn('relative w-2 flex-none', {
          invisible: isInvisible,
        })}
      >
        {type === 'last' ? (
          <div className="w-px h-5 absolute ml-1 -mt-5 bg-slate-400 z-0" />
        ) : type === 'middle' ? (
          <div className="w-px h-10 absolute ml-1 -mt-5 bg-slate-400 z-0" />
        ) : (
          <div className="w-px h-8 absolute ml-1 bg-slate-400 z-0" />
        )}
        <HierarchyIndicator />
      </div>
    </div>
  );
};

interface ElementMultiSelectLabelProps {
  description?: string;
  vat?: number;
  hasFormula: boolean;
  searchValue: string;
  code?: string;
  className?: string;
}

export const ElementMultiSelectLabel = (props: ElementMultiSelectLabelProps) => {
  const { className, vat, hasFormula, description, searchValue, code } = props;
  const { t } = useTranslation();
  return (
    <div className={cn('w-72 flex gap-2 flex-1', className)}>
      <div className="flex-none">
        <TextHighlighter
          text={code ?? ''}
          highlighted={searchValue
            .split(',')
            .filter((x) => x !== '')
            .map((x) => x.trim())}
        />
      </div>
      <div className="text-ellipsis truncate">
        <TextHighlighter
          text={description ? description : t('projectCalculate.unnamedElement')}
          highlighted={searchValue
            .split(',')
            .filter((x) => x !== '')
            .map((x) => x.trim())}
        />
      </div>
      {!!vat && vat > 0 && (
        <div className="flex-none">
          <TextHighlighter
            text={`(${vat}%)`}
            highlighted={searchValue
              .split(',')
              .filter((x) => x !== '')
              .map((x) => x.trim())}
          />
        </div>
      )}
      {hasFormula && <sup className="font-normal italic text-slate-600 flex-none">&nbsp;fx</sup>}
    </div>
  );
};
