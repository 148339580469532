import {
  AlignTextLeftDottedIcon,
  BankBuildingDottedIcon,
  ChecklistDottedIcon,
  ContractMenuIcon,
  FuturesDotterIcon,
  GearsDottedIcon,
  InitiateMoneyTransferDottedIcon,
  MapMarkerDottedIcon,
  Modal,
  PlaylistDottedIcon,
  PriceTagEuroDottedIcon,
  RiskMatrixCustomIcon,
  StorageDottedIcon,
  TrafficLightDottedIcon,
  ViewQuiltDottedIcon,
  WizardSelectList,
  WizardSelectListFilter,
  WizardSelectListFilterItem,
  WizardSelectListItemProps,
} from '@client/shared/toolkit';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DashboardConfigKeys,
  DashboardWidgetType,
  getWidgetAllowedSizesInLayout,
  getWidgetSizeConfigurationForType,
  WidgetSizeType,
} from './utils';
import { WidgetConfig } from './WidgetDashboard';
import { Layout, Layouts } from 'react-grid-layout';
import cn from 'classnames';

interface WidgetDashboardWizardProps {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (widget: WidgetConfig) => void;
  widgets: WidgetConfig[];
  wizardConfig: Layout | null;
  dashboardType: DashboardConfigKeys;
  gridLayout: Layouts | null;
}

export const WidgetDashboardWizard = (props: WidgetDashboardWizardProps) => {
  const { widgets, onSelect, isOpen, onClose, wizardConfig, gridLayout, dashboardType } = props;
  const { t } = useTranslation();
  const [sizeFilters, setSizeFilters] = useState<string[]>([]);
  const [scrollPosition, setScrollPosition] = useState(0);

  const hasShadow = useMemo(() => {
    return scrollPosition > 5;
  }, [scrollPosition]);

  useEffect(() => {
    if (wizardConfig && typeof wizardConfig.x !== 'undefined' && typeof wizardConfig.y !== 'undefined' && gridLayout) {
      // find the allowed sizes for that specific position
      let allowedSizes: WidgetSizeType[] = [];
      widgets.forEach((widget) => {
        const widgetAllowedSizes = getWidgetAllowedSizesInLayout(
          {
            widget: widget,
            layout: wizardConfig,
          },
          gridLayout,
          dashboardType,
        );
        allowedSizes = [...allowedSizes, ...widgetAllowedSizes];
      });
      const filters: string[] = [];
      const disabledSize = allowedSizes.find((size) => size.disabled);
      if (disabledSize) {
        allowedSizes.forEach((size) => {
          if (!size.disabled && !filters.includes(`${size.w}x${size.h}`)) {
            filters.push(`${size.w}x${size.h}`);
          }
        });
      }
      setSizeFilters(filters);
    } else {
      setSizeFilters([]);
    }
  }, [wizardConfig, gridLayout, dashboardType, widgets]);

  // TODO maybe this could be also in the widget configuration?
  const getWidgetIconForType = (widgetType: DashboardWidgetType) => {
    switch (widgetType) {
      case DashboardWidgetType.BudgetStatus:
        return <PriceTagEuroDottedIcon />;
      case DashboardWidgetType.CashOutPlan:
        return <InitiateMoneyTransferDottedIcon />;
      case DashboardWidgetType.FinancialUsage:
        return <ContractMenuIcon />;
      case DashboardWidgetType.KPIs:
        return <GearsDottedIcon />;
      case DashboardWidgetType.MapView:
        return <MapMarkerDottedIcon />;
      case DashboardWidgetType.ProjectInformation:
        return <ViewQuiltDottedIcon />;
      case DashboardWidgetType.ProjectUdfs:
        return <PlaylistDottedIcon />;
      case DashboardWidgetType.RiskMatrix:
        return <RiskMatrixCustomIcon />;
      case DashboardWidgetType.RiskMitigationList:
        return <PlaylistDottedIcon />;
      case DashboardWidgetType.Tasks:
        return <ChecklistDottedIcon />;
      case DashboardWidgetType.TextBox:
        return <AlignTextLeftDottedIcon />;
      case DashboardWidgetType.Timeline:
        return <PlaylistDottedIcon />;
      case DashboardWidgetType.TrafficLight:
        return <TrafficLightDottedIcon />;
      case DashboardWidgetType.ProjectClock:
        return <StorageDottedIcon />;
      case DashboardWidgetType.Waterfall:
        return <FuturesDotterIcon />;
      default:
        return '';
    }
  };

  const items: WizardSelectListItemProps[] = useMemo(() => {
    const widgetListItems: WizardSelectListItemProps[] = [];
    if (widgets.length) {
      widgets.forEach((widget) => {
        const allowedWidgetSizes = getWidgetSizeConfigurationForType(widget.type, dashboardType);
        const sizesForFilter = allowedWidgetSizes?.map((size) => `${size.w}x${size.h}`);
        if (
          !sizeFilters.length ||
          (sizesForFilter && sizeFilters && sizeFilters.some((item) => sizesForFilter.includes(item)))
        ) {
          widgetListItems.push({
            title: widget.title ? t(widget.title) : '',
            subtitle: widget.description ? t(widget.description) : '',
            icon: getWidgetIconForType(widget.type),
            handleClick: () => {
              onSelect(widget);
            },
            iconSize: 'w-auto h-[45px]',
            inBox: true,
          });
        }
      });
    }
    return widgetListItems;
  }, [t, widgets, onSelect, sizeFilters, dashboardType]);

  const availableFilterSizes: WizardSelectListFilterItem[] = useMemo(() => {
    const widgetListItems: string[] = [];
    if (widgets.length) {
      widgets.forEach((widget) => {
        const allowedWidgetSizes = getWidgetSizeConfigurationForType(widget.type, dashboardType);
        const sizesForFilter = allowedWidgetSizes?.map((size) => `${size.w}x${size.h}`);
        sizesForFilter?.forEach((label) => {
          if (!widgetListItems.includes(label)) {
            widgetListItems.push(label);
          }
        });
      });
    }
    return widgetListItems.map((label) => {
      return {
        label: label,
        selected: sizeFilters.includes(label),
      };
    });
  }, [widgets, sizeFilters, dashboardType]);

  const updateSizeFilter = (label: string) => {
    const copy = [...sizeFilters];
    const foundIndex = copy.indexOf(label);
    if (foundIndex >= 0) {
      copy.splice(foundIndex, 1);
    } else {
      copy.push(label);
    }
    setSizeFilters(copy);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header
        title={t('dashboard.widget.library.title')}
        description={t('dashboard.widget.library.description')}
      />
      <Modal.Content hasPadding={false} setScrollPosition={setScrollPosition}>
        {widgets.length > 1 && (
          <div
            className={cn(
              'mb-3 pb-3 bg-gray-100 flex gap-2.5 sticky top-0 w-full transition-shadow duration-300 px-12 z-10',
              { 'shadow-lg': hasShadow },
            )}
          >
            <WizardSelectListFilter onToggle={updateSizeFilter} items={availableFilterSizes} />
          </div>
        )}
        <WizardSelectList items={items} grid gridCols="grid-cols-4" className="pb-10 px-12" />
      </Modal.Content>
    </Modal>
  );
};
