import { useGetCurrency } from '@client/project/store';
import { FinancingElementFeeReadModel } from '@client/shared/api';
import {
  BaseSelect,
  ContextMenu,
  DatePicker,
  BaseSelectOption,
  ContextMenuItem,
  EditNodeIcon,
  NumberInput,
  TextInput, ReceiveCashIcon,
  PercentageIcon,
} from '@client/shared/toolkit';
import { formatNullableDateOnly } from '@client/shared/utilities';
import { TrashIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

interface IProps {
  item: FinancingElementFeeReadModel;
  index: number;
  totalFinancingAmount: number;
  catalogOptions: BaseSelectOption[];
  onItemChange: (item: FinancingElementFeeReadModel, index: number) => void;
  onItemDeleted: (item: FinancingElementFeeReadModel) => void;
  disabled?: boolean;
}
export const FinancingFeeElement = ({ item, index, totalFinancingAmount, catalogOptions, onItemChange, onItemDeleted, disabled }: IProps) => {
  const { t } = useTranslation();
  const currentCurrency = useGetCurrency()

  const contextItems: ContextMenuItem[] = [
    {
      label: t('projectCalculate.financingDeleteTitle'),
      subtitle: t('projectCalculate.financingDeleteSubTitle'),
      icon: <TrashIcon />,
      onClick: () => onItemDeleted(item),
    },
  ];

  const amountPercentage = useMemo(() => {
    return parseFloat((((item.amount ?? 0) / totalFinancingAmount) * 100).toFixed(2));
  }, [item.amount, totalFinancingAmount]);

  return (
    <div className="flex flex-row items-center bg-white divide-x mr-2">
      <div className="w-2/12">
        <DatePicker
          label={t('projectCalculate.financingLabelPaymentOn')}
          value={new Date(item.date)}
          onChange={(date) => onItemChange({ ...item, date: formatNullableDateOnly(date) ?? '' }, index)}
          disabled={disabled}
        />
      </div>

      <div className="w-3/12">
        <TextInput
          label={t('projectCalculate.financingLabelDescription')}
          value={item.description}
          onChange={(description) => onItemChange({ ...item, description }, index)}
          disabled={disabled}
        />
      </div>

      <div className="w-2/12 flex flex-row items-center">
        <NumberInput
          label={t('projectCalculate.financingLabelAmount')}
          value={item.amount ?? 0}
          onChange={(amount) => {
            if (amount !== item.amount) {
              onItemChange({ ...item, amount: amount ?? 0 }, index);
            }
          }}
          step={1}
          icon={<ReceiveCashIcon />}
          disabled={disabled}
        />
        <div className="text-xxs pr-1">{currentCurrency}</div>
      </div>

      <div className="w-2/12 flex flex-row items-center">
        <NumberInput
          label={t('projectCalculate.financingLabelPercentage')}
          value={amountPercentage}
          onChange={(percentage) => {
            if (amountPercentage !== percentage) {
              const amount = (totalFinancingAmount * (percentage ?? 0)) / 100;
              onItemChange({ ...item, amount }, index);
            }
          }}
          icon={<PercentageIcon />}
          disabled={disabled}
        />
      </div>

      <div className="w-3/12 flex flex-row items-center">
        <div className="w-11/12">
          <BaseSelect
            label={t('projectCalculate.groupLabelGroupSelector')}
            options={catalogOptions}
            value={item.costCatalogElementId ?? ''}
            className="min-w-full"
            onChange={(selected) => {
              onItemChange({ ...item, costCatalogElementId: selected }, index);
            }}
            icon={<EditNodeIcon />}
            disabled={disabled}
          />
        </div>

        {!disabled && (
          <div className="w-1/12">
            <div className="w-4 text-gray-400 hover:text-gray-600">
              <ContextMenu items={contextItems} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
