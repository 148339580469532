import React, { PropsWithChildren, useMemo } from 'react';
import { Badge, DocumentIcon, ListItem, SortDownIcon, StatusBadge } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@client/shared/utilities';
import cn from 'classnames';
import { AiEvalDocumentReadModel } from '@client/shared/api';

export interface InvoiceDocumentListItemProps extends PropsWithChildren {
  invoiceDocument: AiEvalDocumentReadModel;
  showNet?: boolean;
}

export const InvoiceDocumentListItem = (props: InvoiceDocumentListItemProps) => {
  const { invoiceDocument, children } = props;
  const { t } = useTranslation()

  const stateLabel = useMemo(() => {
    switch (invoiceDocument.state) {
      case 'Failed':
        return t('projectControl.invoiceDocumentStatusFailed')
      case 'Canceled':
        return t('projectControl.invoiceDocumentStatusCanceled');
      case 'Succeeded':
        return t('projectControl.invoiceDocumentStatusSucceeded')
      case 'Processing':
        return t('projectControl.invoiceDocumentStatusProcessing')
      case 'Unprocessed':
        return t('projectControl.invoiceDocumentStatusUnprocessed')
      case 'PageCountExceeded':
          return t('error.ai_eval_document.document_pagecount_exceeded')
      case 'FileSizeExceeded':
        return t('error.ai_eval_document.document_maxfilesize_exceeded')
    }
  }, [t, invoiceDocument.state])

  const stateBadge = useMemo(() => {
    switch (invoiceDocument.state) {
      case 'Canceled':
      case 'Failed':
      case 'PageCountExceeded':
      case 'FileSizeExceeded':
        return 'danger'
      case 'Succeeded':
        return 'success'
      case 'Processing':
        return 'warning'
      case 'Unprocessed':
        return 'disabled'
    }
  }, [invoiceDocument.state])

  const typeBadge = useMemo(() => {
    switch (invoiceDocument.docType) {
      case 'Generic':
        return t('ai_eval.doc_type.generic')
      case 'G702':
        return t('ai_eval.doc_type.g702')
    }
  }, [invoiceDocument.docType, t])

  return (
    <div className="flex flex-wrap w-full md:divide-x">
      <div className="w-full md:w-4/12 flex-none flex gap-3.5 justify-between md:pr-3 pb-3 md:pb-0 items-center">
        <div className="flex gap-3.5 items-center flex-none w-8/12">
          <DocumentIcon className="hidden md:block flex-none w-6 h-6" />
          <div className="truncate">
            <span className="font-bold text-[15px]">{invoiceDocument.filename}</span>
            {invoiceDocument.docType !== 'Generic' && (
              <Badge text={typeBadge} className="bg-gray-400 text-white ml-2" variant="custom" />
            )}
            <span className="flex flex-wrap text-[11px] text-gray-500 leading-none">
              {invoiceDocument.processed ? t('projectControl.invoiceDocumentStatusProcessed') : ''}
            </span>
          </div>
        </div>
        <span>{formatDate(invoiceDocument.created)}</span>
      </div>
      <div className="flex items-center px-4 flex-none">
        <StatusBadge status={stateBadge} />
        {stateLabel}
      </div>
      {invoiceDocument?.uploadByContract?.id && (
        <div className="hidden lg:flex flex-col justify-center px-4">
          <span className="font-bold text-[15px]">{invoiceDocument.uploadByContract.name}</span>
          <span className="flex flex-wrap text-[11px] text-gray-500 leading-none font-bold">{invoiceDocument.uploadByContract.code}</span>
        </div>
      )}
      {children}
    </div>
  );
};

export interface InvoiceDocumentListSortHeaderProps extends PropsWithChildren {
  onHandleSort: (index: number) => void
  sortValues: (boolean | null)[]
}

// TODO
export const InvoiceDocumentListSortHeader = (props: InvoiceDocumentListSortHeaderProps) => {
  const { t } = useTranslation();
  const { children, onHandleSort, sortValues } = props;

  // TODO translations
  const leftHeader = useMemo(() => {
    return [
      t('projectControl.ContractorAndInvoiceNumberLabel'),
      t('projectControl.ReceiptAndDueLabel')
    ]
  }, [t])

  const rightHeader = useMemo(() => {
    return [
      t('projectControl.ContractLabel'),
      t('projectControl.InvoiceAmountLabel'),
      t('projectControl.RetentionLabel'),
      t('projectControl.PaymentReleaseLabel')
    ]
  }, [t])

  return (
    <ListItem bgColor="" shadow={false} padding={false}>
      <div className="flex flex-wrap w-full divide-x pt-2 px-3 text-[13px] text-gray-500">
        <div className="w-4/12 flex-none flex gap-3.5 flex-wrap justify-between pr-3">
          {leftHeader.map((label, i) => (
            <InvoiceDocumentListSortHeaderItem
              key={`invoice-sort-header-left-${i}`}
              label={label}
              onClick={() => onHandleSort(i)}
              asc={sortValues[i]}
              alignRight={false}
            />
          ))}
        </div>
        <div className="w-8/12 flex-none grid grid-cols-5 pl-3">
          {rightHeader.map((label, i) => (
            <InvoiceDocumentListSortHeaderItem
              key={`invoice-sort-header-right-${i}`}
              label={label}
              onClick={() => onHandleSort(i + leftHeader.length)}
              asc={sortValues[i + leftHeader.length]}
              alignRight={i !== 0}
            />
          ))}
        </div>
        {children}
      </div>
    </ListItem>
  );
};

export interface InvoiceDocumentListSortHeaderItemProps {
  onClick: () => void
  label: string
  asc: boolean | null
  alignRight?: boolean
}

export const InvoiceDocumentListSortHeaderItem = (props: InvoiceDocumentListSortHeaderItemProps) => {
  const {
    onClick,
    label,
    asc,
    alignRight = true
  } = props
  return (
    <span className={cn('flex gap-2.5 items-center cursor-pointer', alignRight ? 'justify-end' : '')} onClick={onClick}>
      {label}
      <SortDownIcon className={cn('w-7',
        {
            'rotate-180': asc || asc === null,
            'opacity-30': asc === null
          }
        )}
      />
    </span>
  )
}
