import {
  ApiDeleteCalculationModelApiArg,
  ApiDeleteCalculationModelDeliveryPhasesApiArg,
  ApiDeleteCalculationModelDeliveryPhasesApiResponse,
  ApiDeleteCalculationModelMilestonesApiArg,
  ApiDeleteCalculationModelMilestonesApiResponse,
  ApiGetCalculationModelApiArg,
  ApiGetCalculationModelApiResponse,
  ApiGetCalculationModelCostsApiArg,
  ApiGetCalculationModelCostsApiResponse,
  ApiGetCalculationModelDeliveryPhasesApiArg,
  ApiGetCalculationModelDeliveryPhasesApiResponse,
  ApiGetCalculationModelEarningsApiArg,
  ApiGetCalculationModelEarningsApiResponse,
  ApiGetCalculationModelFinancingApiArg,
  ApiGetCalculationModelFinancingApiResponse,
  ApiGetCalculationModelMilestonesApiArg,
  ApiGetCalculationModelMilestonesApiResponse,
  ApiGetCalculationModelRisksApiArg,
  ApiGetCalculationModelRisksApiResponse,
  ApiPostCreateCalculationModelDeliveryPhasesApiArg,
  ApiPostCreateCalculationModelDeliveryPhasesApiResponse,
  ApiPostCreateCalculationModelMilestonesApiArg,
  ApiPostCreateCalculationModelMilestonesApiResponse,
  ApiPostUpdateCalculationModelCashFlowSettingsApiArg,
  ApiPostUpdateCalculationModelCashFlowSettingsApiResponse,
  ApiPostUpdateCalculationModelDeliveryPhasesApiArg,
  ApiPostUpdateCalculationModelDeliveryPhasesApiResponse,
  ApiPostUpdateCalculationModelMilestonesApiArg,
  ApiPostUpdateCalculationModelMilestonesApiResponse,
} from '../apiGenerated';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ApiTagTypes } from '../apiTagTypes';

export const enhancedApiCalculateEndpoints = {
  /*------------------ PROVIDES TAGS --------------------*/
  apiGetCalculationModelMilestones: {
    providesTags: (
      _result: ApiGetCalculationModelMilestonesApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetCalculationModelMilestonesApiArg,
    ) => [{ type: ApiTagTypes.CalculationModelMilestones, id: args.calculationModelId }],
  },
  apiGetCalculationModelDeliveryPhases: {
    providesTags: (
      _result: ApiGetCalculationModelDeliveryPhasesApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetCalculationModelDeliveryPhasesApiArg,
    ) => [{ type: ApiTagTypes.CalculationModelDeliveryPhases, id: args.calculationModelId }],
  },
  apiGetCalculationModel: {
    providesTags: (
      _result: ApiGetCalculationModelApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetCalculationModelApiArg,
    ) => [
      {
        type: ApiTagTypes.CalculationModel,
        id: args.calculationModelId,
      },
      {
        type: ApiTagTypes.CalculationModel,
        id: args.projectId,
      },
    ],
  },
  apiGetCalculationModelCosts: {
    providesTags: (
      result: ApiGetCalculationModelCostsApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetCalculationModelCostsApiArg,
    ) => [
      ...(result?.payload?.catalogElements?.map((dto): { type: ApiTagTypes.CostElement; id: string } => ({
        type: ApiTagTypes.CostElement,
        id: dto.id,
      })) ?? []),
      {
        type: ApiTagTypes.CostsCalculationModel,
        id: args.calculationModelId
      },
      {
        type: ApiTagTypes.CostsCalculationModel,
        id: args.projectId
      },
    ],
  },
  apiGetCalculationModelEarnings: {
    providesTags: (
      result: ApiGetCalculationModelEarningsApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetCalculationModelEarningsApiArg,
    ) => [
      ...(result?.payload?.earnings?.map((dto): { type: ApiTagTypes.EarningsElement; id: string } => ({
        type: ApiTagTypes.EarningsElement,
        id: dto.group?.groupId ?? dto.earningsElement?.id ?? '0',
      })) ?? []),
      { type: ApiTagTypes.EarningsCalculationModel, id: args.calculationModelId },
    ],
  },
  apiGetCalculationModelRisks: {
    providesTags: (
      result: ApiGetCalculationModelRisksApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetCalculationModelRisksApiArg,
    ) => [
      ...(result?.payload?.riskElements?.map((dto): { type: ApiTagTypes.RiskElement; id: string } => ({
        type: ApiTagTypes.RiskElement,
        id: dto.group?.groupId ?? dto.riskElement?.riskElementId ?? '0',
      })) ?? []),
      { type: ApiTagTypes.RisksCalculationModel, id: args.calculationModelId },
    ],
  },
  apiGetCalculationModelFinancing: {
    providesTags: (
      result: ApiGetCalculationModelFinancingApiResponse,
      _error: FetchBaseQueryError,
      args: ApiGetCalculationModelFinancingApiArg,
    ) => [
      ...(result?.payload?.financingGroups
        ?.flatMap((dto) => dto.financingElements)
        .map((dto): { type: ApiTagTypes.FinancingElement; id: string } => ({
          type: ApiTagTypes.FinancingElement,
          id: dto.id,
        })) ?? []),
      { type: ApiTagTypes.FinancingCalculationModel, id: args.calculationModelId },
    ],
  },
  /*------------------ INVALIDATES TAGS --------------------*/
  apiDeleteCalculationModel: {
    invalidatesTags: (
      _result: ApiDeleteCalculationModelDeliveryPhasesApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteCalculationModelApiArg,
    ) => (error == null ? [{ type: ApiTagTypes.Project, id: args.projectId }] : []),
  },
  apiPostCreateCalculationModelDeliveryPhases: {
    invalidatesTags: (
      _result: ApiPostCreateCalculationModelDeliveryPhasesApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateCalculationModelDeliveryPhasesApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            {
              type: ApiTagTypes.CalculationModelDeliveryPhases,
              id: args.calculationModelId,
            },
            { type: ApiTagTypes.Project, id: args.projectId },
          ]
        : [],
  },
  apiPostUpdateCalculationModelDeliveryPhases: {
    invalidatesTags: (
      _result: ApiPostUpdateCalculationModelDeliveryPhasesApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateCalculationModelDeliveryPhasesApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.CalculationModelDeliveryPhases, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.RisksCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.EarningsCalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.FinancingCalculationModel, id: args.calculationModelId },
          ]
        : [],
  },
  apiDeleteCalculationModelDeliveryPhases: {
    invalidatesTags: (
      _result: ApiDeleteCalculationModelDeliveryPhasesApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteCalculationModelDeliveryPhasesApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.CalculationModelDeliveryPhases, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
          ]
        : [],
  },
  apiPostCreateCalculationModelMilestones: {
    invalidatesTags: (
      _result: ApiPostCreateCalculationModelMilestonesApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostCreateCalculationModelMilestonesApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.CalculationModelMilestones, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.CalculationModelDeliveryPhases, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostUpdateCalculationModelMilestones: {
    invalidatesTags: (
      _result: ApiPostUpdateCalculationModelMilestonesApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateCalculationModelMilestonesApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.CalculationModelMilestones, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.CalculationModelDeliveryPhases, id: args.calculationModelId },
          ]
        : [],
  },
  apiDeleteCalculationModelMilestones: {
    invalidatesTags: (
      _result: ApiDeleteCalculationModelMilestonesApiResponse,
      error: FetchBaseQueryError,
      args: ApiDeleteCalculationModelMilestonesApiArg,
    ) =>
      error == null
        ? [
            { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
            { type: ApiTagTypes.CalculationModelMilestones, id: args.calculationModelId },
            { type: ApiTagTypes.Project, id: args.projectId },
            { type: ApiTagTypes.BaseReport, id: args.projectId },
            { type: ApiTagTypes.CalculationModelDeliveryPhases, id: args.calculationModelId },
          ]
        : [],
  },
  apiPostUpdateCalculationModelCashFlowSettings: {
    invalidatesTags: (
      _result: ApiPostUpdateCalculationModelCashFlowSettingsApiResponse,
      error: FetchBaseQueryError,
      args: ApiPostUpdateCalculationModelCashFlowSettingsApiArg,
    ) =>
      error == null
        ? [
          { type: ApiTagTypes.CalculationModel, id: args.calculationModelId },
          { type: ApiTagTypes.CostsCalculationModel, id: args.calculationModelId },
          { type: ApiTagTypes.Report, id: args.calculationModelId },
          { type: ApiTagTypes.CashflowSettings, id: args.calculationModelId },
        ]
        : [],
  }
};
