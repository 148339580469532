import { InvoiceElementData, InvoiceState, InvoiceType, InvoiceValueDistributionType } from '@client/shared/api';
import { Disclosure } from '@headlessui/react';
import cn from 'classnames';
import { LabeledValue, ToggleButton } from '@client/shared/toolkit';
import { ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatDate, formatNumber, isEmpty, partitionArray } from '@client/shared/utilities';

interface InvoiceImportRecordProps {
  element: InvoiceElementData;
  level?: number;
}

export const InvoiceImportRecord = ({ element, level = 0 }: InvoiceImportRecordProps) => {
  const { t } = useTranslation();

  const allContractUserDefinedFieldKeys = Object.keys(element.data.userDefinedFields);
  const userDefinedFieldKeySegments = partitionArray(allContractUserDefinedFieldKeys, 3);

  const translateInvoiceType = (invoiceType : InvoiceType | undefined | null) => {
    switch (invoiceType){
      case 'AdvancePayment':
        return t('projectControl.invoiceTypeAdvancePayment');
      case 'Single':
        return t('projectControl.invoiceTypeSingle');
      case 'Partial':
        return t('projectControl.invoiceTypePartial');
      case 'PartialFinal':
        return t('projectControl.invoiceTypePartialFinal');
      case 'Final':
        return t('projectControl.invoiceTypeFinal');
      case 'Warranty':
        return t('projectControl.invoiceTypeWarranty');
    }

    return '-';
  }

  const translateInvoiceState = (invoiceState : InvoiceState | undefined | null) => {
    switch (invoiceState){
      case 'Pending':
        return t('projectControl.statePending');
      case 'Audited':
        return t('projectControl.stateAudited');
      case 'Approved':
        return t('projectControl.stateApproved');
      case 'Paid':
        return t('projectControl.statePaid');
    }

    return '-';
  }

  const translateDistributionType = (distributionType : InvoiceValueDistributionType | undefined | null) => {
    switch (distributionType){
      case 'Manual':
        return t('projectControl.invoiceTitleDistributionTypeManual');
      case 'Equal':
        return t('projectControl.invoiceTitleDistributionTypeEqual');
      case 'ByTitleForecast':
        return t('projectControl.invoiceTitleDistributionTypeByTitleForecast');
      case 'ByPreviousInvoice':
        return t('projectControl.invoiceTitleDistributionTypeByPreviousInvoice');
    }

    return '-';
  }  

  return (
    <Disclosure as="div" className="w-full" defaultOpen={false}>
      {({ open }) => (
        <>
          <Disclosure.Button as="div">
            <div
              className={cn('flex items-center h-[38px] hover:text-black hover:bg-gray-50 relative', {
                'border-b font-bold text-sm text-gray-900': level === 0,
                'text-xs text-gray-500': level > 0,
              })}
            >
              <ToggleButton className="absolute z-[5] -left-6 top-0 h-full" open={open} />
              <div className="pl-2 w-full h-full flex flex-row cursor-pointer items-center">
                <div className="flex flex-grow" style={level > 1 ? { marginLeft: `${level * 0.6}rem` } : undefined}>
                  {element.data.invoiceCode}
                </div>
                {element.errors && element.errors.length > 0 && (
                  <div className="mr-2 flex justify-end text-red-600">
                    <ExclamationCircleIcon className="w-6" />
                  </div>
                )}
              </div>
            </div>
          </Disclosure.Button>
          <Disclosure.Panel>
            <div className="text-xs mb-2 ml-2">
              <div className="text-center mb-2">
                {element.errors.map((err, index) => (
                  <div key={index} className="text-red-500">
                    {t(err)}
                  </div>
                ))}
              </div>
              <div className='ml-2'>
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={element.data.contractCode ?? '-'}>{t('projectContract.contractCode')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={element.data.invoiceCode ?? '-'}>{t('projectControl.invoiceCode')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={element.data.externalCode ?? '-'}>{t('projectControl.invoiceExternalCode')}</LabeledValue>
                  </div>                
                </div>
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={element.data.costElementCode ?? '-'}>{t('projectCalculate.costElement')}</LabeledValue>
                  </div>                  
                  <div className="w-2/3">
                    <LabeledValue value={element.data.calculationScheme ?? '-'}>{t('projectControl.viewIcs')}</LabeledValue>
                  </div>
                </div>                
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={isEmpty(element.data.vat) ? '-' : `${formatNumber(element.data.vat)} %`}>{t('common.vat')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={formatCurrency(element.data.claim)}>{t('projectControl.invoiceClaim')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={element.data.invoicingPartyCode ?? '-'}>{t('projectControl.invoicingParty')}</LabeledValue>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={element.data.invoiceRecipientCode ?? '-'}>{t('projectControl.invoiceRecipient')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={formatCurrency(element.data.invoiceValue)}>{t('projectControl.invoiceValue')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={translateDistributionType(element.data.distributionType)}>{t('projectControl.invoiceValueDistributionType')}</LabeledValue>
                  </div>
                </div>
                <div className="flex flex-row">
                <div className="w-1/3">
                    <LabeledValue value={translateInvoiceType(element.data.type)}>{t('projectControl.invoiceType')}</LabeledValue>
                  </div>                  
                  <div className="w-1/3">
                    <LabeledValue value={translateInvoiceState(element.data.state)}>{t('projectControl.state')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={formatDate(element.data.invoiceDate)}>{t('projectControl.invoiceDate')}</LabeledValue>
                  </div>                  
                </div>
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={formatDate(element.data.dateOfReceipt)}>{t('projectControl.dateOfReceipt')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={formatDate(element.data.dueDate)}>{t('projectControl.dueDate')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={formatDate(element.data.dueDate)}>{t('projectControl.cashDiscountDate')}</LabeledValue>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={formatDate(element.data.dateOfAudit)}>{t('projectControl.dateOfAudit')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={formatDate(element.data.dateOfApproval)}>{t('projectControl.dateOfApproval')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={formatDate(element.data.paymentDate)}>{t('projectControl.paymentDate')}</LabeledValue>
                  </div>
                </div>
                <div className="flex flex-row">
                  <LabeledValue value={element.data.comment ?? '-'}>{t('common.comment')}</LabeledValue>
                </div>
                <div className="my-1">{t('projectControl.invoiceTitles')}</div>                
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <LabeledValue value={element.data.invoiceTitleCode ?? '-'}>{t('projectControl.invoiceTitleCode')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={isEmpty(element.data.invoiceTitleVat) ? '-' : `${formatNumber(element.data.invoiceTitleVat)} %`}>{t('common.vat')}</LabeledValue>
                  </div>
                  <div className="w-1/3">
                    <LabeledValue value={formatCurrency(element.data.invoiceTitleValue)}>{t('projectControl.invoiceValue')}</LabeledValue>
                  </div>
                </div>                                
                {allContractUserDefinedFieldKeys.length > 0 && <div className="my-1">{t('common.userDefinedFields')}</div>}
                {userDefinedFieldKeySegments.map((keys, segmentIndex) => (
                  <div key={segmentIndex} className="flex flex-row">
                    {keys.map((key, userDefinedFieldIndex) =>(
                      <div key={`invoice-user-defined-field${userDefinedFieldIndex}`} className="w-1/3">
                        <LabeledValue value={element.data.userDefinedFields[key] ?? '-'}>{key}</LabeledValue>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};