import {
  TimeLineDataContext,
  TimeLineElementsProps,
  TimeLinePositionedContainer,
  TimeLineView,
  TimeLineElementSlideOver,
  TimeLineMarker,
  TimeLineTooltip,
} from '../index';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { parseISO } from 'date-fns';
import cn from 'classnames';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { SlideOver } from '@client/shared/toolkit';
import { formatDate } from '@client/shared/utilities';

export interface TimeLineBodyElementProps {
  year: number;
  data: TimeLineElementsProps;
  view: TimeLineView;
  timelineStartDate: Date;
  timelineEndDate: Date;
  expandedIds?: string[];
  plannedToDate?: number;
  paymentToDate?: number;
  totalPayment?: number;
  totalPlanned?: number;
  dueDate?: string | null;
}

export const TimeLineBodyElement = ({
  year,
  data,
  timelineStartDate,
  timelineEndDate,
  expandedIds = [],
  plannedToDate,
  paymentToDate,
  totalPayment = 0,
  totalPlanned = 0,
  dueDate
}: TimeLineBodyElementProps) => {
  const { toggledYears } = useContext(TimeLineDataContext);
  const { t } = useTranslation();
  const elementRef = useRef<HTMLDivElement>(null);

  const [elementLength, setElementLength] = useState<string | number>('100%');
  const [financingStatus, setFinancingStatus] = useState<'green' | 'red' | ''>('');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [todayLength, setTodayLength] = useState<string | number>('100%');
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);

  const isToggled = useMemo(() => {
    return toggledYears.includes(year);
  }, [toggledYears, year]);

  const end = useMemo(() => {
    if (data.timelineElement?.endDate) {
      const endDate = parseISO(data.timelineElement.endDate.substring(0, 10));
      if (endDate.getFullYear() > year) {
        return new Date(year, 11, 31);
      }
      return endDate;
    }
    return null;
  }, [data?.timelineElement?.endDate, year]);
  const start = useMemo(() => {
    if (data.timelineElement?.startDate) {
      const startDate = parseISO(data.timelineElement.startDate.substring(0, 10));
      if (startDate.getFullYear() < year) {
        return new Date(year, 0, 1);
      }
      return startDate;
    }
    return null;
  }, [data?.timelineElement?.startDate, year]);

  const updateLength = useCallback(() => {
    // elementId is only set for costs but not for financing
    const wrapperDivs = document.querySelectorAll(`.timeline-element[data-tl-element="${data.elementId ?? data.id}"]`);
    let maxWidth = elementRef?.current?.scrollWidth ?? 0;
    if (wrapperDivs.length) {
      maxWidth = 0;
      wrapperDivs.forEach((wrapperDiv) => {
        maxWidth += wrapperDiv.scrollWidth;
      });
    }
    setElementLength(maxWidth ? `${maxWidth}px` : '100%');
  }, [data.elementId, data.id]);
  // update the length if years are toggled or the expanded ids change
  useEffect(() => {
    updateLength();
  }, [toggledYears, expandedIds, updateLength, data.timelineElement]);
  useEffect(() => {
    updateLength();
  }, [updateLength]);

  const tlEndDate = useMemo(() => {
    if (data.timelineElement?.endDate) {
      return parseISO(data.timelineElement.endDate.substring(0, 10));
    }
    return undefined;
  }, [data.timelineElement?.endDate]);

  const tlStartDate = useMemo(() => {
    if (data.timelineElement?.startDate) {
      return parseISO(data.timelineElement.startDate.substring(0, 10));
    }
    return undefined;
  }, [data.timelineElement?.startDate]);

  const today = useMemo(() => {
    return dueDate && new Date(dueDate) ? new Date(dueDate) : new Date();
  }, [dueDate]);

  const isShouldTimelines = useMemo(() => {
    if (tlEndDate && plannedToDate && typeof paymentToDate !== 'undefined') {
      const widthIs = (100 / plannedToDate) * paymentToDate; // should value is 100%
      const isLabel = t('projectCalculate.timeLine.isLabel');
      const shouldLabel = t('projectCalculate.timeLine.shouldLabel');
      const sum = data.timelineElement?.justCalculated;
      const showLabel = !isToggled;

      // tl is in the future
      if (tlStartDate && tlStartDate > today) {
        setFinancingStatus('');
        return;
      }

      // tl is in the past
      if (tlEndDate <= today) {
        setFinancingStatus('');
        // tl is in the past and IS = 100% -> light grey, no values
        if (paymentToDate === 1) {
          return null;
        } else {
          setFinancingStatus('red');
          // tl is in the past and IS < 100% -> red with IS percentage value
          return (
            <>
              {/* SHOULD (100%) */}
              <IsShouldTimeLine percentage={100} type="should" status="red" showLabel={false} />
              {/* IS */}
              <IsShouldTimeLine
                percentage={paymentToDate * 100}
                type="is"
                status="red"
                label={isLabel}
                width={paymentToDate * 100}
                sum={sum}
                showLabel={showLabel}
                maxWidth={elementLength}
              />
            </>
          );
        }
      } else {
        // threshold value is 5%
        // tl IS and SHOULD difference <= 5% -> medium grey + percentage value of IS value
        if (paymentToDate >= plannedToDate - 0.05 && paymentToDate <= plannedToDate + 0.05) {
          setFinancingStatus('');
          return (
            <IsShouldTimeLine
              percentage={paymentToDate * 100}
              type="is"
              width={100} // we just show as it would be 100%
              sum={sum}
              showLabel={showLabel}
              maxWidth={elementLength}
            />
          );
        } else if (start && end) {
          // tl IS and SHOULD difference > 5% -> red if IS < SHOULD, green if IS >= SHOULD + show both percentage values
          if (paymentToDate < plannedToDate) {
            setFinancingStatus('red');
            return (
              <>
                {/* SHOULD */}
                <IsShouldTimeLine
                  percentage={plannedToDate * 100}
                  type="should"
                  label={shouldLabel}
                  status="red"
                  width={100}
                  showLabel={showLabel}
                  maxWidth={elementLength}
                />
                {/* IS */}
                <IsShouldTimeLine
                  percentage={paymentToDate * 100}
                  type="is"
                  label={isLabel}
                  status="red"
                  width={widthIs}
                  sum={sum}
                  showLabel={showLabel}
                  maxWidth={elementLength}
                />
              </>
            );
          }
          if (paymentToDate >= plannedToDate) {
            setFinancingStatus('green');
            return (
              <>
                {/* SHOULD */}
                <IsShouldTimeLine
                  percentage={plannedToDate * 100}
                  type="should"
                  label={shouldLabel}
                  status="green"
                  width={100}
                  showLabel={showLabel}
                  maxWidth={elementLength}
                />
                {/* IS */}
                <IsShouldTimeLine
                  percentage={paymentToDate * 100}
                  type="is"
                  label={isLabel}
                  status="green"
                  width={widthIs}
                  maxWidth={elementLength}
                  sum={sum}
                  showLabel={showLabel}
                />
              </>
            );
          }
        }
      }
    }
    return null;
  }, [
    plannedToDate,
    paymentToDate,
    t,
    start,
    end,
    elementLength,
    today,
    data.timelineElement,
    tlEndDate,
    tlStartDate,
    isToggled,
  ]);

  // for is and should the length until today or deadline
  useEffect(() => {
    const duedateMarkerPos = document.getElementById('due-date-marker')?.getBoundingClientRect().left;
    const todayMarkerPos = document.getElementById('today-marker')?.getBoundingClientRect().left;
    let width = 0;
    if ((todayMarkerPos || duedateMarkerPos) && elementRef.current?.getBoundingClientRect().left && elementRef.current?.clientWidth) {
      if (duedateMarkerPos) {
        width = duedateMarkerPos - elementRef.current?.getBoundingClientRect().left;
      } else if (todayMarkerPos) {
        width = todayMarkerPos - elementRef.current?.getBoundingClientRect().left;
      }
      width = width > elementRef.current.clientWidth ? elementRef.current.clientWidth : width;
      setTodayLength(width ?? '100%');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current, end, today, elementLength, toggledYears]);

  const startLabel = useMemo(() => {
    const timeline = data.timelineElement;
    if (timeline) {
      if (timeline.startReferenceDisplayName) {
        if (timeline.startOffset) {
          return (
            <>
              <span className="font-bold">{timeline.startReferenceCode}</span>&nbsp;
              {timeline.startReferenceDisplayName}, + {timeline.startOffset}{' '}
              {t('common.month', { count: timeline.startOffset })}
            </>
          );
        }
        return (
          <>
            <span className="font-bold">{timeline.startReferenceCode}</span>&nbsp;
            {timeline.startReferenceDisplayName}
          </>
        );
      }
      if (timeline.startDate) {
        return formatDate(timeline.startDate);
      }
    }
    return '';
  }, [data.timelineElement, t]);

  const endLabel = useMemo(() => {
    const timeline = data.timelineElement;
    if (timeline) {
      if (timeline.endReferenceDisplayName) {
        if (timeline.endOffset) {
          return (
            <>
              <span className="font-bold">{timeline.endReferenceCode}</span>&nbsp;
              {timeline.endReferenceDisplayName}, + {timeline.endOffset}{' '}
              {t('common.month', { count: timeline.endOffset })}
            </>
          );
        }
        return (
          <>
            <span className="font-bold">{timeline.endReferenceCode}</span>&nbsp;
            {timeline.endReferenceDisplayName}
          </>
        );
      }
      if (timeline.duration) {
        return `+ ${timeline.duration} ${t('common.month', { count: timeline.duration })}`;
      }
      if (timeline.endDate) {
        return formatDate(timeline.endDate);
      }
    }
    return '';
  }, [data.timelineElement, t]);

  return useMemo(() => {
    if (!data.timelineElement || !start || !end) return null;
    return (
      <>
        <TimeLinePositionedContainer
          timeLineStartDate={timelineStartDate}
          timeLineEndDate={timelineEndDate}
          date={start}
          endDate={end}
          className={cn(
            'w-full text-slate-500 mt-4',
            // data.timelineElement.justCalculated ? 'border-slate-300' : 'border-slate-500',
            {
              // issue with borders (weird random gap), so just add transparent border here and use the actual phase size label as top line
              //'border-transparent': start && start > timelineStartDate
            }
          )}
          wrapperRef={wrapperRef}
        >
          {/* this one is the actual timeline element size used as the line for the beginning */}
          {tlStartDate && tlStartDate >= timelineStartDate && tlStartDate <= timelineEndDate && (
            <div
              data-tl={data.elementId}
              className={cn(
                'absolute -top-1 left-0 h-1 transition-colors duration-300 w-full leading-tight',
                data.timelineElement.justCalculated ? 'bg-slate-300' : 'bg-slate-500',
                { 'cursor-pointer': data.elementType === 'costElement' && data.financeTimeline }
                /// 'before:w-[0.5625rem] before:h-[0.5625rem] after:w-[0.5625rem] after:h-[0.5625rem]',
                // data.timelineElement.justCalculated ? 'after:border-slate-300 before:border-slate-300' : 'after:border-slate-500 before:border-slate-500',
                //{
                //  // Marker dots

                //  'before:absolute before:left-0 before:top-1/2 before:p-px before:rounded-full before:bg-white before:shadow before:border-2 before:z-30 before:-translate-x-1/2 before:-translate-y-1/2':
                //    true,
                //  'after:absolute after:left-full after:top-1/2 after:p-px after:rounded-full after:bg-white after:shadow after:border-2 after:z-30 after:-translate-x-1/2 after:-translate-y-1/2':
                //    true,
                //  'before:border-red-700': financingStatus === 'red',
                //  'before:border-green-700': financingStatus === 'green',
                //  'after:border-green-700': totalPayment >= totalPlanned,
                //  'before:border-slate-300': data.timelineElement.justCalculated && financingStatus === '',
                //  'before:border-slate-500': !data.timelineElement.justCalculated && financingStatus === '',
                //  'after:border-slate-300':
                //    data.timelineElement.justCalculated &&
                //    (financingStatus === '' || (financingStatus === 'red' && tlEndDate && tlEndDate > today)),
                //  'after:border-slate-500':
                //    !data.timelineElement.justCalculated &&
                //    (financingStatus === '' || (financingStatus === 'red' && tlEndDate && tlEndDate > today)),
                //  'after:border-red-700': financingStatus === 'red' && tlEndDate && tlEndDate <= today,
                //}
              )}
              style={{ width: elementLength }}
              ref={elementRef}
              onClick={() =>
                (data.elementType === 'costElement' || data.elementType === 'riskElement' || data.elementType === 'earningElement') && data.financeTimeline ? setIsSlideOverOpen(true) : undefined
              }
            >
              {(data.elementType === 'costElement' || data.elementType === 'riskElement' || data.elementType === 'earningElement') && (
                <TimeLineTooltip>
                  <div className="text-xs whitespace-nowrap">
                    {data.financeTimeline
                      ? t('projectCalculate.timeLine.transactionPlanAvailable')
                      : t('projectCalculate.timeLine.noTransactionPlanAvailable')}
                  </div>
                </TimeLineTooltip>
              )}
              {/* Marker Dots */}
              <TimeLineMarker
                className={cn({
                  'border-red-700': financingStatus === 'red',
                  'border-green-700': financingStatus === 'green',
                  'border-slate-300': data.timelineElement.justCalculated && financingStatus === '',
                  'border-slate-500': !data.timelineElement.justCalculated && financingStatus === '',
                })}
              >
                {startLabel && <div className="text-xs whitespace-nowrap">{startLabel}</div>}
              </TimeLineMarker>
              <TimeLineMarker
                position="right"
                data-financing-status={financingStatus}
                className={cn({
                  'border-green-700': totalPayment >= totalPlanned,
                  'border-slate-300':
                    data.timelineElement.justCalculated &&
                    (financingStatus === '' || (financingStatus === 'red' && tlEndDate && tlEndDate > today)),
                  'border-slate-500':
                    !data.timelineElement.justCalculated &&
                    (financingStatus === '' || (financingStatus === 'red' && tlEndDate && tlEndDate > today)),
                  'border-red-700': financingStatus === 'red' && tlEndDate && tlEndDate <= today,
                })}
              >
                {endLabel && <div className="text-xs whitespace-nowrap">{endLabel}</div>}
              </TimeLineMarker>
              {/* IS and SHOULD Timelines */}
              {isShouldTimelines && (
                <div
                  className={cn(
                    'is-should-tl absolute top-0 left-0 h-1 transition-colors duration-300 w-full z-20',
                    {
                      'cursor-pointer': (data.elementType === 'costElement' || data.elementType === 'riskElement' || data.elementType === 'earningElement') && data.financeTimeline,
                    }
                  )}
                  style={{ width: todayLength }}
                  onClick={() =>
                    (data.elementType === 'costElement' || data.elementType === 'riskElement' || data.elementType === 'earningElement') && data.financeTimeline ? setIsSlideOverOpen(true) : undefined
                  }
                >
                  {isShouldTimelines}
                </div>
              )}
            </div>
          )}
          <div
            data-tl-element={data.elementId ?? data.id}
            className={classNames(
              'timeline-element absolute -top-1 left-0 w-full h-1 z-10',
              data.timelineElement.justCalculated ? 'bg-slate-300' : 'bg-slate-500',
              {
                'cursor-pointer': (data.elementType === 'costElement' || data.elementType === 'riskElement' || data.elementType === 'earningElement') && data.financeTimeline,
              }
            )}
            onClick={() =>
              (data.elementType === 'costElement' || data.elementType === 'riskElement' || data.elementType === 'earningElement') && data.financeTimeline ? setIsSlideOverOpen(true) : undefined
            }
          />
        </TimeLinePositionedContainer>

        <SlideOver isOpen={isSlideOverOpen} onClose={() => setIsSlideOverOpen(false)}>
          <TimeLineElementSlideOver onClose={() => setIsSlideOverOpen(false)} element={data} />
        </SlideOver>
      </>
    );
  }, [
    timelineStartDate,
    timelineEndDate,
    start,
    end,
    data,
    elementLength,
    isShouldTimelines,
    financingStatus,
    todayLength,
    totalPayment,
    totalPlanned,
    tlEndDate,
    tlStartDate,
    today,
    isSlideOverOpen,
    endLabel,
    startLabel,
    t,
  ]);
};

interface IsShouldTimeLineProps {
  percentage: number;
  label?: string;
  status?: 'red' | 'green';
  type: 'should' | 'is';
  showLabel?: boolean;
  width?: number;
  maxWidth?: string | number;
  sum?: boolean;
}

const IsShouldTimeLine = (props: IsShouldTimeLineProps) => {
  const { percentage, label, status = '', type, showLabel = true, width, maxWidth, sum = false } = props;

  return (
    <div
      className={cn('absolute left-0 top-0 w-full h-1', {
        'bg-slate-400': status === '',
        'bg-red-400': status === 'red' && type === 'should',
        'bg-red-700': status === 'red' && type === 'is',
        'bg-green-700': status === 'green' && type === 'should',
        'bg-green-400': status === 'green' && type === 'is',
        'z-10': status === 'green' && type === 'should',
      })}
      style={{
        width: `${width ? width : percentage}%`,
        maxWidth: maxWidth,
      }}
    >
      {showLabel && (
        <div
          className={cn('absolute top-0 right-0 whitespace-nowrap', {
            'text-red-400': status === 'red' && type === 'should',
            'text-red-700': status === 'red' && type === 'is',
            'text-green-700': status === 'green' && type === 'should',
            'text-green-400': status === 'green' && type === 'is',
            '-translate-y-full': type === 'is',
          })}
        >
          {label && sum && <span className="text-[8px] uppercase">&Oslash; {label}</span>}
          {label && !sum && <span className="text-[8px] uppercase">{label}</span>}
          <span className="text-[11px] font-bold italic">{`${percentage.toFixed(0)}%`}</span>
        </div>
      )}
    </div>
  );
};
