import {
  AgreementDottedIcon,
  BaseSelectOption, ComboSelect, ComboSelectOption, ContractIcon, FormField,
  LoadingIndicator,
  WizardSlideContent, WizardSlideHeader
} from '@client/shared/toolkit';
import { SelectContractReadModel, useApiGetSelectableContractsQuery } from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

interface InvoiceCreateWithContractSlidesProps {
  error: boolean;
  setSelectedContract: (contract: SelectContractReadModel | undefined) => void;
  selectedContract?: SelectContractReadModel;
}

export const InvoiceCreateWithContractSlides = (props: InvoiceCreateWithContractSlidesProps) => {
  const {
    error,
    setSelectedContract,
    selectedContract
  } = props;

  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const { data: fetchedContracts, isFetching: isFetchingContracts } = useApiGetSelectableContractsQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId,
    },
  );

  const [contractOptions, setContractOptions] = useState<ComboSelectOption[]>([]);

  useEffect(() => {
    if (typeof fetchedContracts !== 'undefined') {
      // filter out synced contracts, as they cannot be used
      const options: BaseSelectOption[] = [];

      fetchedContracts?.forEach((contract) => {
        options.push({
          value: contract.id,
          label: `${contract.code} - ${contract.name}`,
        })
      });

      setContractOptions(options);
    }
  }, [fetchedContracts]);

  return (
    <>
      {isFetchingContracts && (
        <LoadingIndicator text={t('projectContract.fetchingContractsLoadingIndicator')} />
      )}
      <WizardSlideHeader
        icon={<AgreementDottedIcon className="w-full h-full" />}
        title={t('projectControl.createWizard.selectContract')}
        subTitle={t('projectControl.createWizard.selectContractDescription')}
      />
      <WizardSlideContent>
        <FormField name="contractId">
          {(control) => (
            <ComboSelect
              icon={<ContractIcon className="h-6 w-6" />}
              label={t('projectContract.contract')}
              nullable
              options={contractOptions}
              {...control}
              onChange={(contractId) => {
                const contract = fetchedContracts?.find((x) => x.id === contractId);
                setSelectedContract(contract);
                control.onChange(contractId);
              }}
              showValidation={error || control.showValidation}
              isValidationValid={!error && control.value && control.isValidationValid}
              helperText={error ? t('projectControl.createWizard.contractRequiredMessage') : control.isValidationValid ? control.helperText : undefined}
              helperTextClassName="bg-transparent"
            />
          )}
        </FormField>
        {selectedContract && (
          <table className="text-sm mt-2">
            <tbody>
              <tr>
                <th className="font-bold">{t('projectContract.contractor')}:&nbsp;</th>
                <td>{selectedContract.contractorName ? `${selectedContract.contractorCompanyName} - ${selectedContract.contractorName}` : '-'}</td>
              </tr>
              <tr>
                <th className="font-bold">{t('projectContract.contractClient')}:&nbsp;</th>
                <td>{selectedContract.clientName ? `${selectedContract.clientCompanyName} - ${selectedContract.clientName}` : '-'}</td>
              </tr>
            </tbody>
          </table>
        )}
      </WizardSlideContent>
    </>
  )
};
