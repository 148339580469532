import {
  useApiGetUserDefinedFieldsDefinitionQuery,
  useApiProjectGetUserDefinedFieldsDefinitionQuery,
  useApiPostSearchMutation,
  UserDefinedFieldElementType,
  SearchFilter as Filter,
  SearchIn,
} from '@client/shared/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FilterGroup, FilterOption, SearchFilter } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import {
  useLoadedProjectId,
  useLoadedVariantId,
  useToggledProjectFilters,
  setToggledFilters,
  useToggledCostFilters,
  useToggledTaxonomyFilters,
  useToggledContractFilters,
  useToggledContractTitleFilters,
} from '@client/project/store';
import { useDispatch } from 'react-redux';

export interface UserDefinedFieldsSearchProps {
  searchValue: string;
  className?: string;
  udfElementTypes: UserDefinedFieldElementType[];
  filterGroups?: FilterGroup[];
  handleSearchValueUpdate: (val: string) => void;
  isApplyDisabled?: boolean;
  searchClassName?: string;
  searchBgColor?: string;
  searchDisabled?: boolean;
  updateSearchResults?: (result: string[]) => void;
  filterStore: SearchIn;
  projectSpecific?: boolean;
  setUdfFilters?: (filters: string[]) => void;
  useStore?: boolean;
}

export const UserDefinedFieldsSearch = (props: UserDefinedFieldsSearchProps) => {
  const {
    searchValue,
    className,
    udfElementTypes,
    filterGroups = [],
    handleSearchValueUpdate,
    isApplyDisabled = false,
    searchClassName,
    searchBgColor,
    searchDisabled = false,
    updateSearchResults,
    filterStore,
    projectSpecific = true,
    setUdfFilters,
    useStore = true,
  } = props;

  const { t } = useTranslation();
  const loadedVariantId = useLoadedVariantId();
  const loadedProjectId = useLoadedProjectId();
  const dispatch = useDispatch();

  const toggledProjectFilters = useToggledProjectFilters();
  const toggledCostFilters = useToggledCostFilters();
  const toggledTaxonomyFilters = useToggledTaxonomyFilters();
  const toggledContractFilters = useToggledContractFilters();
  const toggledContractTitleFilters = useToggledContractTitleFilters();

  const [customFieldGroups, setCustomFieldGroups] = useState<FilterGroup[]>([]);

  const { data: udfResponseData, isFetching: isLoadingUdf } = useApiGetUserDefinedFieldsDefinitionQuery(undefined, {
    skip: !udfElementTypes.length || projectSpecific,
  });

  const { data: projectSpecificUdfResponseData, isFetching: isLoadingProjectSpecificUdf } =
    useApiProjectGetUserDefinedFieldsDefinitionQuery(
      {
        projectId: loadedProjectId ?? 'unset',
      },
      { skip: !udfElementTypes.length || !projectSpecific },
    );

  const udfData = projectSpecific ? projectSpecificUdfResponseData : udfResponseData;

  const getUserDefinedFieldFilterGroupTitle = useCallback(
    (type: UserDefinedFieldElementType) => {
      switch (type) {
        case 'Cost':
          return t('projectCalculate.userDefinedFieldsCostFilterTitle');
        case 'Risk':
          return t('projectCalculate.userDefinedFieldsRiskFilterTitle');
        case 'Earning':
          return t('projectCalculate.userDefinedFieldsEarningFilterTitle');
        case 'Project':
          return t('projectCalculate.userDefinedFieldsProjectFilterTitle');
        case 'Taxonomy':
          return t('projectCalculate.userDefinedFieldsTaxonomyFilterTitle');
        case 'Contract':
          return t('projectCalculate.userDefinedFieldsContractFilterTitle');
        case 'ContractTitle':
          return t('projectCalculate.userDefinedFieldsContractTitleFilterTitle');
        default:
          return t('projectCalculate.userDefinedFieldsFilterTitle');
      }
    },
    [t],
  );

  useEffect(() => {
    if (udfData?.userDefinedFieldsDefinition && udfElementTypes.length) {
      const groups: FilterGroup[] = [];
      const udfFilters: { [key: string]: FilterOption[] } = {};

      udfData.userDefinedFieldsDefinition.forEach((udf) => {
        if (udfElementTypes.includes(udf.elementType)) {
          if (!udfFilters[udf.elementType]) {
            udfFilters[udf.elementType] = [];
          }
          udfFilters[udf.elementType].push({
            id: udf.id,
            label: udf.name,
            type: 'UserDefinedField',
            fieldType: udf.fieldType,
            listItems: udf.listItems,
          });
        }
      });

      udfElementTypes.forEach((type) => {
        groups.push({
          options: udfFilters[type] ?? [],
          visible: true,
          title: getUserDefinedFieldFilterGroupTitle(type),
        });
      });
      setCustomFieldGroups(groups);
    }
  }, [udfData, t, getUserDefinedFieldFilterGroupTitle, udfElementTypes]);

  const allFilterGroups = useMemo(() => {
    return filterGroups.concat(...customFieldGroups);
  }, [filterGroups, customFieldGroups]);

  const [searchText, setSearchText] = useState<string>(searchValue);
  const [filters, setFilters] = useState<FilterOption[]>([]);
  const [getSearchResults, { isLoading: isLoadingSearch }] = useApiPostSearchMutation();
  const [searchResults, setSearchResults] = useState<string[]>([]);

  const submitSearch = async (currentFilters: FilterOption[], searchValue: string | null) => {
    const isProjectSearch = udfElementTypes.includes('Project');
    if (searchValue?.trim()) {
      try {
        const response = await getSearchResults({
          body: {
            searchIn: udfElementTypes,
            values: searchValue
              .trim()
              .split(',')
              .filter((x) => x !== '')
              .map((x) => x.trim()),
            searchFilters: currentFilters as Filter[],
            calculationModelId: isProjectSearch ? undefined : loadedVariantId,
            projectId: isProjectSearch ? undefined : loadedProjectId,
          },
        }).unwrap();
        if (response.readModels.filter((x) => x.ids.length > 0).length > 0) {
          const ids: string[] = [];
          response.readModels.forEach((readModel) => {
            ids.push(...readModel.ids);
          });
          setSearchResults(ids);
        } else {
          setSearchResults([]);
        }
      } catch (e) {
        // left blank intentionally
      }
    }
  };

  const handleSearch = useCallback(
    (val: string, filters: FilterOption[]) => {
      setSearchText(val);
      handleSearchValueUpdate(val);
      setFilters(filters);
      if (useStore) {
        dispatch(setToggledFilters({ filters: filters, type: filterStore }));
      }
      submitSearch(filters, val);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleSearchValueUpdate, filterStore, dispatch, loadedVariantId, loadedProjectId],
  );

  const updateSearchValue = useCallback((val: string) => {
    setSearchText(val);
  }, []);

  useEffect(() => {
    if (updateSearchResults && searchResults) {
      updateSearchResults(searchResults);
    }
  }, [searchResults, updateSearchResults]);

  useEffect(() => {
    const filterSet = useStore ? (() => {
      switch (filterStore) {
        case 'Project':
          return toggledProjectFilters;
        case 'Cost':
          return toggledCostFilters;
        case 'Taxonomy':
          return toggledTaxonomyFilters;
        case 'Contract':
          return toggledContractFilters;
        case 'ContractTitle':
          return toggledContractTitleFilters;
        default:
          return [];
      }
    })() : [];

  if (customFieldGroups.length && allFilterGroups.length && filterSet.length && filterSet !== filters) {
    const selectedOptions: Filter[] = [];
    const selectedFilters = filterSet.map((filter) => filter.id);
    // List items must be set as search text
    let loadedSearchText = '';
    allFilterGroups.forEach((group) => {
      group.options.forEach((option) => {
        if (selectedFilters.includes(option.id)) {
          selectedOptions.push({
            id: option.id,
            type: 'UserDefinedField',
          });
        }
        option.listItems?.forEach((item) => {
          if (selectedFilters.includes(item.listItemId) && loadedSearchText !== item.label) {
            loadedSearchText = loadedSearchText ? `${loadedSearchText}, ${item.label}` : item.label;
          }
        });
      });
    });
    setFilters(filterSet);
    if (!isLoadingUdf && !isLoadingProjectSpecificUdf && loadedSearchText && searchText !== loadedSearchText) {
      setSearchText(loadedSearchText);
      handleSearchValueUpdate(loadedSearchText);
      submitSearch(selectedOptions, loadedSearchText);
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [
  customFieldGroups,
  allFilterGroups,
  toggledProjectFilters,
  toggledCostFilters,
  toggledTaxonomyFilters,
  filterStore,
  useStore,
]);

const toggledFilters = useMemo(() => {
  return filters.map((tf) => tf.id);
}, [filters]);

useEffect(() => {
  if (setUdfFilters) {
    setUdfFilters(toggledFilters);
  }
}, [toggledFilters, setUdfFilters]);

return (
  <SearchFilter
    filterGroups={allFilterGroups}
    handleSearch={handleSearch}
    updateSearchValue={updateSearchValue}
    searchValue={searchValue}
    isApplyDisabled={isApplyDisabled}
    searchClassName={searchClassName}
    searchBgColor={searchBgColor}
    className={className}
    searchDisabled={searchDisabled}
    toggledFilters={toggledFilters}
    isLoading={isLoadingUdf || isLoadingProjectSpecificUdf || isLoadingSearch}
  />
);
};
