import { BaseSelectOption, Button, LoadingIndicator, SlideOver, TrashIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { Widget } from './WidgetDashboard';
import { useCallback, useMemo } from 'react';
import {
  WidgetDashboardEditFormFinancialUsageValidationValues,
  WidgetDashboardEditFormKpiValidationValues,
  WidgetDashboardEditFormMapViewValidationValues,
  WidgetDashboardEditFormProjectClockValidationValues,
  WidgetDashboardEditFormProjectInformationValidationValues,
  WidgetDashboardEditFormProps,
  WidgetDashboardEditFormRiskMitigationListValidationValues,
  WidgetDashboardEditFormTasksValidationValues,
  WidgetDashboardEditFormTextBoxValidationValues,
  WidgetDashboardEditFormTrafficLightsValidationValues,
  WidgetDashboardEditFormValidationValues,
  WidgetDashboardEditFormWaterfallValidationValues,
  WidgetEditFormCashOutPlan,
  WidgetEditFormDefault,
  WidgetEditFormFinancialUsage,
  WidgetEditFormKPIs,
  WidgetEditFormMapView,
  WidgetEditFormProjectClock,
  WidgetEditFormProjectInformation,
  WidgetEditFormProjectUdfs,
  WidgetEditFormRiskMitigationList,
  WidgetEditFormTasks,
  WidgetEditFormTextBox,
  WidgetEditFormTrafficLights,
  WidgetEditFormWaterfall,
} from './WidgetEditForms';
import {
  DashboardConfigKeys,
  DashboardWidgetType,
  getWidgetAllowedSizesInLayout,
  getWidgetConfigurationForType,
  prepareWidgetsToSave,
  prepareWidgetToSave,
} from './utils';
import {
  ProjectReadModel,
  UpsertCustomDashboardPayload,
  UpsertReportDashboardPayload,
  useApiDeleteCustomDashboardWidgetMutation,
  useApiDeleteReportDashboardWidgetMutation,
  useApiPostCreateReportDashboardMutation,
  useApiPostUpdateCustomDashboardMutation,
  useApiPostUpdateReportDashboardMutation,
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { useLoadedProjectId } from '@client/project/store';
import { Layouts } from 'react-grid-layout';
import { WidgetEditFormBudgetStatus } from './WidgetEditForms/WidgetEditFormBudgetStatus';

interface WidgetDashboardEditProps {
  dashboardType: DashboardConfigKeys;
  isOpen: boolean;
  onClose: () => void;
  onAfterLeave: () => void;
  onSaved: () => void;
  widget: Widget | null;
  gridLayout: Layouts | null;
  layout: Widget[];
  project: ProjectReadModel;
  dashboardId?: string;
  dashboardName?: string;
}

export type WidgetDashboardEditFormValues =
  | WidgetDashboardEditFormValidationValues
  | WidgetDashboardEditFormFinancialUsageValidationValues
  | WidgetDashboardEditFormKpiValidationValues
  | WidgetDashboardEditFormMapViewValidationValues
  | WidgetDashboardEditFormProjectInformationValidationValues
  | WidgetDashboardEditFormRiskMitigationListValidationValues
  | WidgetDashboardEditFormTasksValidationValues
  | WidgetDashboardEditFormTextBoxValidationValues
  | WidgetDashboardEditFormTrafficLightsValidationValues
  | WidgetDashboardEditFormProjectClockValidationValues
  | WidgetDashboardEditFormWaterfallValidationValues;
export const WidgetDashboardEdit = (props: WidgetDashboardEditProps) => {
  const {
    dashboardType,
    widget,
    isOpen,
    onClose,
    onAfterLeave,
    onSaved,
    dashboardId,
    dashboardName,
    gridLayout,
    layout,
    project,
  } = props;

  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();

  const [deleteWidget, { isLoading: isDeletingWidget }] = useApiDeleteCustomDashboardWidgetMutation();
  const [deleteReportWidget, { isLoading: isDeletingReportWidget }] = useApiDeleteReportDashboardWidgetMutation();
  const [updateCustomDashboard, { isLoading: isUpdatingCustomDashboard }] = useApiPostUpdateCustomDashboardMutation();
  const [updateReportDashboard, { isLoading: isUpdatingReportDashboard }] = useApiPostUpdateReportDashboardMutation();
  const [createReportDashboard, { isLoading: isCreatingReportDashboard }] = useApiPostCreateReportDashboardMutation();

  const widgetConfig = useMemo(() => {
    return widget ? getWidgetConfigurationForType(widget?.widget.type) : null;
  }, [widget]);

  const allowedSizes = useMemo(() => {
    if (widget && gridLayout) {
      return getWidgetAllowedSizesInLayout(widget, gridLayout, dashboardType);
    }
    return [];
  }, [widget, gridLayout, dashboardType]);

  const sizeOptions: BaseSelectOption[] = useMemo(() => {
    return allowedSizes.map((size, index) => {
      return {
        label: `${size.w}x${size.h}`,
        value: index.toString(),
        disabled: size.disabled,
      };
    });
  }, [allowedSizes]);

  const selectedSize = useMemo(() => {
    const defaultSize = widgetConfig?.defaultSize;
    const widgetHeight = widget?.layout.h ?? 1;
    const widgetWidth = widget?.layout.w ?? 1;

    const foundIndex = allowedSizes.findIndex(({ w, h, disabled }) => 
      defaultSize 
        ? `${w}x${h}` === defaultSize && !disabled
        : h === widgetHeight && w === widgetWidth
    );
    
    return foundIndex >= 0 ? String(foundIndex) : '0';
  }, [allowedSizes, widgetConfig?.defaultSize, widget?.layout.h, widget?.layout.w]);

  const onDelete = useCallback(async () => {
    if (widget?.widget.id && dashboardId && loadedProjectId) {
      if (dashboardType === 'report') {
        try {
          await safeMutation(
            deleteReportWidget,
            {
              projectId: loadedProjectId,
              dashboardId: dashboardId,
              widgetId: widget.widget.id,
            },
            isDeletingReportWidget,
          );
          onClose();
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          await safeMutation(
            deleteWidget,
            {
              projectId: loadedProjectId,
              dashboardId: dashboardId,
              widgetId: widget.widget.id,
            },
            isDeletingWidget,
          );
          onClose();
        } catch (e) {
          console.error(e);
        }
      }
    }
  }, [
    widget?.widget.id,
    dashboardId,
    loadedProjectId,
    deleteWidget,
    isDeletingWidget,
    onClose,
    dashboardType,
    deleteReportWidget,
    isDeletingReportWidget,
  ]);

  const handleSubmit = useCallback(
    async (data: WidgetDashboardEditFormValues) => {
      if (loadedProjectId && widget) {
        const sizeIndex = parseInt(data.size);
        const selectedSize = allowedSizes[sizeIndex];
        const updatedWidget = prepareWidgetToSave(data, widget, selectedSize);
        try {
          if (dashboardType === 'report') {
            if (dashboardId) {
              await safeMutation(
                updateReportDashboard,
                {
                  projectId: loadedProjectId,
                  dashboardId: dashboardId,
                  body: {
                    name: dashboardName,
                    ...prepareWidgetsToSave(layout, updatedWidget, 'report'),
                  } as UpsertReportDashboardPayload,
                },
                isUpdatingReportDashboard,
              );
            } else {
              await safeMutation(
                createReportDashboard,
                {
                  projectId: loadedProjectId,
                  body: {
                    name: dashboardName ?? t('reporting.customReportTitle'),
                    ...prepareWidgetsToSave(layout, updatedWidget, 'report'),
                  } as UpsertReportDashboardPayload,
                },
                isCreatingReportDashboard,
              );
            }

            onSaved && onSaved();
          } else if (dashboardId && dashboardName) {
            await safeMutation(
              updateCustomDashboard,
              {
                projectId: loadedProjectId,
                dashboardId: dashboardId,
                body: {
                  name: dashboardName,
                  ...prepareWidgetsToSave(layout, updatedWidget),
                } as UpsertCustomDashboardPayload,
              },
              isUpdatingCustomDashboard,
            );

            onSaved && onSaved();
          }
        } catch (e) {
          console.error(e);
        }
      }
    },
    [
      loadedProjectId,
      dashboardId,
      dashboardName,
      widget,
      allowedSizes,
      dashboardType,
      updateReportDashboard,
      layout,
      isUpdatingReportDashboard,
      updateCustomDashboard,
      isUpdatingCustomDashboard,
      createReportDashboard,
      isCreatingReportDashboard,
      t,
      onSaved,
    ],
  );

  const buttons = useMemo(() => {
    if (!widget) return '';
    return (
      <SlideOver.Controls>
        {widget.widget.id && (
          <div className="w-full flex justify-between">
            <Button variant="danger" className="h-full" onClick={onDelete}>
              <TrashIcon className="w-4 h-4 text-white" />
            </Button>
          </div>
        )}
        <div className="flex">
          <Button variant="secondary" className="mr-2" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <Button variant="primary" className="mr-2" formSubmit>
            {widget.widget.id ? t('common.save') : t('common.create')}
          </Button>
        </div>
      </SlideOver.Controls>
    );
  }, [widget, onClose, t, onDelete]);

  const widgetEditForm = useMemo(() => {
    if (widget) {
      const editFormProps: WidgetDashboardEditFormProps = {
        buttons: buttons,
        widget: widget,
        title: widget?.widget.title ?? (widgetConfig?.title ? t(widgetConfig.title) : ''),
        handleSubmit: handleSubmit,
        sizeOptions: sizeOptions,
        selectedSize: selectedSize,
        project: project,
        dashboardType: dashboardType,
      };
      switch (widget?.widget.type) {
        case DashboardWidgetType.BudgetStatus:
          return <WidgetEditFormBudgetStatus {...editFormProps} />
        case DashboardWidgetType.CashOutPlan:
          return <WidgetEditFormCashOutPlan {...editFormProps} />;
        case DashboardWidgetType.FinancialUsage:
          return <WidgetEditFormFinancialUsage {...editFormProps} />;
        case DashboardWidgetType.KPIs:
          return <WidgetEditFormKPIs {...editFormProps} />;
        case DashboardWidgetType.MapView:
          return <WidgetEditFormMapView {...editFormProps} />;
        case DashboardWidgetType.ProjectInformation:
          return <WidgetEditFormProjectInformation {...editFormProps} />;
        case DashboardWidgetType.ProjectUdfs:
          return <WidgetEditFormProjectUdfs {...editFormProps} />;
        case DashboardWidgetType.RiskMitigationList:
          return <WidgetEditFormRiskMitigationList {...editFormProps} />;
        case DashboardWidgetType.Tasks:
          return <WidgetEditFormTasks {...editFormProps} />;
        case DashboardWidgetType.TextBox:
          return <WidgetEditFormTextBox {...editFormProps} />;
        case DashboardWidgetType.TrafficLight:
          return <WidgetEditFormTrafficLights {...editFormProps} />;
        case DashboardWidgetType.ProjectClock:
          return <WidgetEditFormProjectClock {...editFormProps} />;
        case DashboardWidgetType.Waterfall:
          return <WidgetEditFormWaterfall {...editFormProps} />;
        default:
          return <WidgetEditFormDefault {...editFormProps} />;
      }
    }
    return '';
  }, [buttons, t, widget, sizeOptions, selectedSize, widgetConfig?.title, handleSubmit, project, dashboardType]);

  if (!widget) {
    return null;
  }

  return (
    <SlideOver isOpen={isOpen} onClose={onClose} onAfterLeave={onAfterLeave}>
      <SlideOver.Header
        title={widget.widget.title ?? (widgetConfig?.title ? t(widgetConfig.title) : t('common.widget'))}
        subTitle={t('dashboard.widget.edit.subtitle')}
        backgroundClassName="bg-gray-900"
        onClose={onClose}
      />
      {(isUpdatingCustomDashboard || isUpdatingReportDashboard || isCreatingReportDashboard) && (
        <LoadingIndicator mode="overlay" />
      )}
      {widgetEditForm}
    </SlideOver>
  );
};
