import { FormattedCurrency } from '../..';
import { Card, ProgressBar } from '@client/shared/toolkit';
import { formatCurrency } from '@client/shared/utilities';
import { useGetCurrency } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ContractAdvancePaymentRepaymentSummaryReadModel } from '@client/shared/api';

export interface InvoicePrepaymentStatusProps {
  advancePaymentSummary?: ContractAdvancePaymentRepaymentSummaryReadModel;
}

interface PaymentStats {
  totalRepaid: number;
  outstandingBalance: number;
  progressPercentage: number;
}

const InvoicePrepaymentStatus = (props: InvoicePrepaymentStatusProps) => {
  const { advancePaymentSummary } = props;
  const currentCurrency = useGetCurrency();
  const { t } = useTranslation();

  // State to hold computed payment statistics.
  const [paymentStats, setPaymentStats] = useState<PaymentStats>({
    totalRepaid: 0,
    outstandingBalance: 0,
    progressPercentage: 0,
  });

  // Compute payment statistics based directly on advancePaymentSummary.
  useEffect(() => {
    if (advancePaymentSummary) {
      const totalAdvancePayment = advancePaymentSummary.advancePaymentNet ?? 0;
      const repayments = advancePaymentSummary.repaymentNet ?? 0;
      const totalRepaid = Math.abs(repayments);
      const outstandingBalance = totalAdvancePayment - totalRepaid;
      const progressPercentage =
        totalAdvancePayment > 0 ? Math.trunc((totalRepaid / totalAdvancePayment) * 100) : 0;
      setPaymentStats({ totalRepaid, outstandingBalance, progressPercentage });
    } else {
      setPaymentStats({ totalRepaid: 0, outstandingBalance: 0, progressPercentage: 0 });
    }
  }, [advancePaymentSummary]);

  return (
    <>
      <br />
      <div style={{ marginTop: '-15px', marginBottom: '-5px' }}>
        <div className="text-[11px] text-gray-500">
          {t('ics.deductionNameAdvancePaymentRepaid')}
        </div>
        <div className="w-full relative flex flex-row">
          <ProgressBar
            progress={paymentStats.progressPercentage}
            endContent={
              formatCurrency(paymentStats.outstandingBalance, { currency: currentCurrency })
            }
          />
        </div>
        <div style={{ marginTop: '5px' }}>
          <Card>
            <div
              className="text-[11px] text-gray-600"
              style={{
                marginRight: '-15px',
                marginTop: '-5px',
                marginBottom: '-5px',
                marginLeft: '-10px',
              }}
            >
              {t('ics.deductionNameTotalAdvancePaymentAmount') + ' '}
              <span className="font-bold text-[12px]">
                <FormattedCurrency amount={advancePaymentSummary?.advancePaymentNet ?? 0} />
              </span>
              <br />
              {t('ics.deductionNameTotalRepaidAmount') + ' '}
              <span className="font-bold text-[12px]">
                <FormattedCurrency amount={paymentStats.totalRepaid} />
              </span>
              <br />
              {t('ics.deductionNameOutstandingAdvancePayment') + ' '}
              <span className="font-bold text-[12px]">
                <FormattedCurrency amount={paymentStats.outstandingBalance} />
              </span>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default InvoicePrepaymentStatus;
