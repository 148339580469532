import { FormattedCurrency } from '../../FormattedCurrency';
import React, { PropsWithChildren, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import {
  BudgetOverrunWarningReadModel,
  InvoiceCalculationRuleReadModel,
  InvoiceReadModel,
  useApiGetAdvancePaymentRepaymentSummaryQuery,
  useApiPostUpdateInvoiceCalculationRuleMutation,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import {
  getIcsDeductionName,
  getIcsDeductionTypeLabel,
  getInvoiceCoverSheetDeductionGroupLabel,
  getIcsVatRenderedInfo,
} from '../../../utils';
import cn from 'classnames';
import { formatDistanceToNow, parseISO } from 'date-fns';
import {
  DocumentViewerFileDataInlineEdit,
  ErrorIcon,
  GuideDialog,
  GuideDialogControls,
  LoadingIndicator,
  NumberInput,
  PercentageIcon,
} from '@client/shared/toolkit';
import { ProjectTaxPickerInput } from '../../ProjectTaxPickerInput';
import { formatNumber, safeMutation } from '@client/shared/utilities';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { InvoiceEditContext } from '..';
import InvoicePrepaymentStatus from './InvoicePrepaymentStatus';

export interface InvoiceCalculationProps {
  invoice: InvoiceReadModel;
  isLastInvoice?: boolean;
  budgetOverrun: BudgetOverrunWarningReadModel | undefined;
  budgetAssigned: boolean;
  canEdit: boolean;
  showForecast?: boolean
}
export const InvoiceCalculation = (props: InvoiceCalculationProps) => {
  const {
    invoice,
    budgetOverrun,
    budgetAssigned,
    canEdit,
    showForecast = false,
    // isLastInvoice
  } = props;
  const { t } = useTranslation();

  let vatRendered = false;
  let prevSum = invoice.valueNet;

  /* const paymentGroup = useMemo(() => {
    return invoice.invoiceCalculationRuleGroups.find((group) => group.type === 'Payment')
  }, [invoice.invoiceCalculationRuleGroups]) */

  const vatRenderedInfo = useMemo(() => {
    return getIcsVatRenderedInfo(invoice.invoiceCalculationRuleGroups);
  }, [invoice.invoiceCalculationRuleGroups]);

  /* const getGroupCalculationRulesSum = useCallback((vatRendered: boolean, calculationRules: InvoiceCalculationRuleReadModel[], groupIndex: number) => {
    let sum = 0;
    const groupVatRendered = groupIndex > vatRenderedInfo.groupIndex;
    calculationRules.forEach((rule, ruleIndex) => {
      if (rule.type !== 'Subtotal') {
        sum += ruleIndex < vatRenderedInfo.ruleIndex && !groupVatRendered ? rule.calculationResultGross : rule.calculationResultNet;
      }
    });
    return sum;
  }, [vatRenderedInfo]); */

  const contractTitleForecastTotalNet = useMemo(() => {
    return invoice.invoiceTitles.reduce((acc, title) => {
      return acc + (title.contractTitleForecastNet ?? 0);
    }, 0);
  }, [invoice.invoiceTitles]);

  const titlePaymentIncreaseTotalNet = useMemo(() => {
    return invoice.invoiceTitles.reduce((acc, title) => {
      return acc + (title.cumulativeTitlePaymentIncreaseNet ?? 0);
    }, 0);
  }, [invoice.invoiceTitles]);

  const expectedApprovedPaymentTotalNet = useMemo(() => {
    return invoice.invoiceTitles.reduce((acc, title) => {
      return acc + (title.expectedApprovedPaymentNet ?? 0);
    }, 0);
  }, [invoice.invoiceTitles]);

  const cumulativeTitleTotalPercentage = useMemo(() => {
    return (invoice.valueNet / contractTitleForecastTotalNet) * 100
  }, [contractTitleForecastTotalNet, invoice.valueNet]);

  const titlePaymentIncreaseTotalPercentage = useMemo(() => {
    return (titlePaymentIncreaseTotalNet / contractTitleForecastTotalNet) * 100
  }, [contractTitleForecastTotalNet, titlePaymentIncreaseTotalNet]);

  const expectedApprovedPaymentTotalPercentage = useMemo(() => {
    return (expectedApprovedPaymentTotalNet / contractTitleForecastTotalNet) * 100
  }, [contractTitleForecastTotalNet, expectedApprovedPaymentTotalNet]);

  const { guideDialogData, setGuideDialogData } = useContext(InvoiceEditContext);

  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);

  return (
    <>
      <div className="flex justify-between items-center mt-4 mb-2 font-bold text-[18px]">
        {t('projectControl.sumOfPayments')}
      </div>

      {showForecast ? <div className="flex flex-col">
        {invoice.type === 'Single' &&
          <div className="flex gap-2 font-light text-[11px] text-slate-400">
            <span className="w-7/12 flex flex-wrap justify-end">
              <span title={t('projectControl.invoiceTitleExpectedApproved')}>{t('projectControl.invoiceTitleExpApr')}&nbsp;</span>
              <span className="font-semibold">{formatNumber(expectedApprovedPaymentTotalPercentage, { maxDigits: 2 })}%</span>
            </span>
            <span className="w-5/12 flex flex-wrap justify-end">
              <span title={t('projectControl.invoiceTitleExpectedApproved')}>{t('projectControl.invoiceTitleExpApr')}&nbsp;</span>
              <span className="font-semibold"><FormattedCurrency amount={expectedApprovedPaymentTotalNet} options={{ maxDigits: 2, minDigits: 2 }} /></span>
            </span>
          </div>
        }

        <div className="flex gap-2 items-end">
          <span className="w-5/12"><FormattedCurrency amount={contractTitleForecastTotalNet} options={{ maxDigits: 2, minDigits: 2 }}  /></span>
          {showForecast && <span className="w-2/12 flex flex-wrap justify-end font-bold">{formatNumber(cumulativeTitleTotalPercentage, { maxDigits: 2 })}%</span>}
          <span className="w-5/12 flex flex-wrap justify-end font-bold"><FormattedCurrency amount={invoice.valueNet} options={{ maxDigits: 2, minDigits: 2 }} /></span>
        </div>

        <div className="flex gap-2 font-light text-[11px] text-slate-400">
          {invoice.type !== 'Single' && <span className="w-4/12">
            {`${t('projectControl.invoiceTitleForecast')} ${t(('projectControl.invoiceTitleOnlyBillablePos'))}`}
          </span>}
          {invoice.type !== 'Single' && <span className="w-3/12 flex flex-wrap justify-end">
            <span title={t('projectControl.invoiceTitleIncrement')}>{t('projectControl.invoiceTitleIncr')}&nbsp;</span>
            <span className="font-semibold">{formatNumber(titlePaymentIncreaseTotalPercentage, { maxDigits: 2 })}%</span>
          </span>}
          {invoice.type !== 'Single' && <span className="w-5/12 flex flex-wrap justify-end font-semibold"><FormattedCurrency amount={titlePaymentIncreaseTotalNet} options={{ maxDigits: 2, minDigits: 2 }} /></span>}
        </div>
      </div> :
        <div className="flex flex-wrap gap-2 justify-end font-bold">
          <FormattedCurrency amount={invoice.valueNet} options={{ maxDigits: 2, minDigits: 2 }} />
        </div>
      }

      <GuideDialog
        show={budgetAssigned && guideDialogData?.showDialog && guideDialogData?.elements.includes('budget')}
        targetElement={targetElement}
        guideDialogData={guideDialogData}
        setGuideDialogData={setGuideDialogData}
        showCloseButton
        watermark="warning"
        placement="bottom-start"
        title={<span className="text-red-700">{t('projectInvoice.amountOverrunsBudget')}</span>}
        description={
          <div className="flex flex-col gap-2">
            <div>{t('projectInvoice.amountOverrunsBudgetDescription')}</div>
            <div className="flex">
              <span className="font-bold mr-1">{t('projectInvoice.availableBudget')}</span>
              <span className="break-all">
                <FormattedCurrency amount={budgetOverrun?.availableBudget ?? 0} options={{ maxDigits: 2, minDigits: 2 }} />
              </span>
            </div>
            <div className="flex">
              <span className="font-bold mr-1">{t('projectInvoice.invoiceAmount')}</span>
              <span className="break-all">
                <FormattedCurrency amount={budgetOverrun?.totalValue ?? 0} options={{ maxDigits: 2, minDigits: 2 }} />
              </span>
            </div>
            <div className="flex">
              <span className="font-bold mr-1">{t('projectInvoice.overrunAmount')}</span>
              <span className={cn('break-all', { 'text-red-700': budgetOverrun?.isBudgetOverrun })}>
                <FormattedCurrency amount={Math.abs(budgetOverrun?.restBudget ?? 0)} options={{ maxDigits: 2, minDigits: 2 }}  />
              </span>
            </div>
          </div>
        }
        controls={<GuideDialogControls guideDialogData={guideDialogData} setGuideDialogData={setGuideDialogData} />}
      />
      {budgetAssigned && budgetOverrun?.isBudgetOverrun && (
        <div ref={setTargetElement}>
          <ErrorIcon
            className="w-4 text-red-700"
            onMouseEnter={() => setGuideDialogData({ ...guideDialogData, showDialog: true })}
          />
        </div>
      )}
      <div className="flex flex-col gap-5 mt-6">
        {invoice.invoiceCalculationRuleGroups.map((group, groupIndex) => {
          // whether we need to show the group sum or not
          // let showSum = true
          // tmp store the old prevSum
          const previous = prevSum;
          // override the prevSum with the current group sum
          prevSum =
            groupIndex >= vatRenderedInfo.groupIndex ? group.calculationResultGross : group.calculationResultNet;

          return (
            <div
              className="w-full flex flex-col divide-y-2"
              key={`invoice-calculation-rule-group-${group.calculationRuleGroupId}`}
            >
              <div className="flex justify-between items-center pb-2 w-full">
                <span className="text-lg">{getInvoiceCoverSheetDeductionGroupLabel(group.name)}</span>
                {/* Use the tmp stored prev sum */}
                <span className="font-bold text-[15px]">
                  <FormattedCurrency amount={previous} options={{ maxDigits: 2, minDigits: 2 }} />
                </span>
              </div>

              {group.calculationRules.length > 0 ? (
                <div className="pt-2 w-full flex flex-col gap-2">
                  {group.calculationRules.map((rule, ruleIndex) => {
                    if (rule.type === 'Vat') {
                      vatRendered = true;
                    }
                    // TODO after working correctly, reinsert this again
                    /* if (rule.type === 'CashDiscount') {
                      const isLast = groupIndex === invoice.invoiceCalculationRuleGroups.length - 1 && ruleIndex === group.calculationRules.length - 1
                      /* if (isLast) {
                        showSum = false
                      }
                      return (
                        <InvoiceCalculationCashDiscountSection
                          key={`invoice-calculation-rule-${rule.calculationRuleId}`}
                          rule={rule}
                          isLast={isLast}
                          invoice={invoice}
                          vatRendered={vatRendered}
                          paymentGroupValue={vatRendered ? paymentGroup?.calculationResultGross ?? 0 : paymentGroup?.calculationResultNet ?? 0 }
                          isLastInvoice={isLastInvoice}
                        />
                      )
                    } */
                    return (
                      <InvoiceCalculationRow
                        key={`invoice-calculation-rule-${rule.calculationRuleId}`}
                        name={getIcsDeductionName(rule.name)}
                        /* everything before VAT is net, below it's the gross value (for vat those values are the same) */
                        value={vatRendered ? rule.calculationResultGross : rule.calculationResultNet}
                        rule={rule}
                        invoiceId={invoice.id}
                        calculationRuleGroupId={group.calculationRuleGroupId}
                        editable={invoice.state === 'Pending' && canEdit}
                        contractId={invoice.contractId ?? ''} 
                        displayGrossValues={vatRendered}
                      />
                    );
                  })}
                </div>
              ) : (
                <span className="text-gray-500 pt-2">-</span>
              )}
              <div className="flex justify-between items-center pt-2 mt-2">
                <span className="font-bold text-[13px]">{t('ics.deductionGroupLabelSum')}</span>
                <span className="font-bold text-[15px]">
                  <FormattedCurrency amount={vatRendered ? group.calculationResultGross : group.calculationResultNet} options={{ maxDigits: 2, minDigits: 2 }} />
                </span>
                {/* <span className="font-bold text-[15px]"><FormattedCurrency amount={getGroupCalculationRulesSum(vatRendered, group.calculationRules, groupIndex)} /></span> */}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

interface InvoiceCalculationRowProps extends PropsWithChildren {
  name: string | ReactNode;
  value?: number;
  className?: string;
  centered?: boolean;
  editable?: boolean;
  rule: InvoiceCalculationRuleReadModel;
  invoiceId: string;
  calculationRuleGroupId: string;
  contractId?: string;
  displayGrossValues?: boolean | undefined;
}

export const InvoiceCalculationRow = (props: InvoiceCalculationRowProps) => {
  const {
    name,
    value,
    className,
    centered = true,
    children,
    editable = true,
    rule,
    invoiceId,
    calculationRuleGroupId,
    contractId,
    displayGrossValues = false,
  } = props;

  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [update, { isLoading }] = useApiPostUpdateInvoiceCalculationRuleMutation();

  const [allowChangeMode, setAllowChangeMode] = useState(true);

  const [overridePercentage, setOverridePercentage] = useState<undefined | null | number>(undefined);
  const [overrideValue, setOverrideValue] = useState<undefined | null | number>(undefined);
  const [overrideVat, setOverrideVat] = useState<undefined | null | number>(undefined);

  const { setBudgetRelevantFieldUpdated } = useContext(InvoiceEditContext);

  /**
   * Can edit Percentage:
   *
   * Type == Deduction
   * Type == Retention
   * Type == CashDiscount
   * Type == Repayment
   */
  const factorIsDisabled = useMemo(() => {
    return !(rule.type === 'Deduction'
            || rule.type === 'Retention'
            ||  rule.type === 'CashDiscount'
            ||  rule.type === 'Repayment');
  }, [rule.type]);

  /**
   * Can edit Value:
   *
   * calculation rule doesn't have a reference
   * Type == Repayment
   * Type == Deduction
   * Type == Retention
   * Type == CashDiscount
   */
  const valueIsDisabled = useMemo(() => {
    if (
      rule.referenceType !== 'None'
      || rule.partialInvoiceReferenceType !== 'None'
      || rule.partialFinalInvoiceReferenceType !== 'None'
      || rule.finalInvoiceReferenceType !== 'None'
    ) {
      return true;
    }

    return !(
      rule.type === 'Deduction'
      || rule.type === 'Retention'
      || rule.type === 'CashDiscount'
      || rule.type === 'Repayment');

  }, [
    rule.finalInvoiceReferenceType,
    rule.partialFinalInvoiceReferenceType,
    rule.partialInvoiceReferenceType,
    rule.referenceType,
    rule.type,
  ]);

  /**
   * Can edit Vat:
   *
   * Type == Deduction
   * Type == Retention
   * Type == CashDiscount
   * Type == Discount
   */
  const vatIsDisabled = useMemo(() => {
    return (
      rule.type !== 'Deduction' && rule.type !== 'Retention' && rule.type !== 'CashDiscount' && rule.type !== 'Discount'
    );
  }, [rule.type]);

  const typeLabel = useMemo(() => {
    return getIcsDeductionTypeLabel(rule.type);
  }, [rule.type]);

  const isEditable = useMemo(() => {
    return editable && rule.type !== 'Vat';
  }, [editable, rule.type]);

  const handleUpdate = async () => {
    if (loadedProjectId && loadedVariantId) {
      try {
        await safeMutation(
          update,
          {
            invoiceId: invoiceId,
            calculationRuleGroupId: calculationRuleGroupId,
            calculationRuleId: rule.calculationRuleId,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: {
              vat: typeof overrideVat !== 'undefined' && overrideVat !== rule.vat ? overrideVat : rule.vat,
              percentage:
                typeof overridePercentage !== 'undefined' && overridePercentage !== rule.percentage
                  ? overridePercentage
                  : rule.percentage,
              value: typeof overrideValue !== 'undefined' && overrideValue !== rule.value ? overrideValue : rule.value,
            },
          },
          isLoading,
        );
        setBudgetRelevantFieldUpdated(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const { data: advancePaymentSummary, refetch } = useApiGetAdvancePaymentRepaymentSummaryQuery(
    {
      contractId: contractId ?? '',
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: rule.type !== 'Repayment' }
  );
 
  useEffect(() => {
    if (rule.type === 'Repayment' && contractId) {
      refetch();
    }
  }, [advancePaymentSummary, contractId, refetch, rule.type]);

  // Recalculate the repayment value from the factor when rule.type === 'Repayment'
  useEffect(() => {
    if (
      rule.type === 'Repayment' &&
      advancePaymentSummary?.contrat?.advancePaymentNet != null
    ) {
      const calculatedValue =
        advancePaymentSummary.contrat.advancePaymentNet * (overridePercentage / 100);
      setOverrideValue(calculatedValue);
    }
  }, [overridePercentage, advancePaymentSummary, rule.type]);


  // Compute the outstanding balance from the fetched summary.
  const outstandingBalance = useMemo(() => {
    if (advancePaymentSummary?.contrat) {
      const totalAdvancePayment = advancePaymentSummary.contrat.advancePaymentNet ?? 0;
      const repayments = advancePaymentSummary.contrat.repaymentNet ?? 0;
      return totalAdvancePayment - Math.abs(repayments);
    }
    return Infinity; // fallback if data is not available
  }, [advancePaymentSummary]);

  // Compute the dynamic border class only when rule.type is 'Repayment'
  const borderColor = useMemo(() => {
    if (rule.type === 'Repayment' && overrideValue != null) {
      return Math.abs(overrideValue) > outstandingBalance 
        ? 'border-l-4 border-red-500' 
        : 'border-l-4 border-green-500';
    }
    return '';
  }, [rule.type, overrideValue, outstandingBalance]);

  if (isEditable) {
    return (
      <>
        {isLoading && <LoadingIndicator mode="overlay-window" />}
        <DocumentViewerFileDataInlineEdit
          marginX=""
          marginY="my-0"
          allowChangeMode={allowChangeMode}
          closeOnBlur={false}
          updateEditMode={async (editMode) => {
            // for debugging
            /* if (editMode) {
              console.log(rule, 'rule');
            } */
            if (
              !editMode &&
              ((typeof overridePercentage !== 'undefined' &&
                overridePercentage !== null &&
                overridePercentage !== rule.percentage) ||
                (typeof overrideVat !== 'undefined' && overrideVat !== null && overrideVat !== rule.vat) ||
                (typeof overrideValue !== 'undefined' && overrideValue !== null && overrideValue !== rule.value))
            ) {
              await handleUpdate();
              setOverridePercentage(undefined);
              setOverrideValue(undefined);
              setOverrideVat(undefined);
            }
          }}
          toggleContent={
            <>
              <span className="font-medium">
                {name} {typeLabel !== name && <>({typeLabel})</>}
              </span>
              {rule.type !== 'Discount' && (
                <NumberInput
                  className="mt-2"
                  label={t('ics.deductionFactor')}
                  icon={<PercentageIcon className="h-6 w-6" />}
                  value={overridePercentage ?? rule.percentage}
                  min={rule.type === 'Repayment' ? undefined : undefined}
                  max={rule.type === 'Repayment' ? 100 : undefined}
                  onChange={(value) => {
                    if (rule.type === 'Repayment') {
                      setOverridePercentage(Math.min(100, Math.max(0, value ?? 0)));
                    } else {
                      setOverridePercentage(value);
                    }
                  }}
                  onBlur={() => {
                    if (rule.type === 'Repayment') {
                      setOverridePercentage(Math.min(100, Math.max(0, overridePercentage ?? (rule.percentage ?? 0))));
                    }
                  }}
                  disabled={factorIsDisabled}
                  decimalScale={4}
                />              
              )}

              <NumberInput
                className={`mt-0.5 ${borderColor}`}
                label={t('ics.deductionValueAmount')}
                value={overrideValue ?? rule.value}
                onChange={(value) => {
                    setOverrideValue(value);
                }}
                disabled={valueIsDisabled}
                forceNegative={rule.type === 'Repayment'}
                decimalScale={2}
              />

              <ProjectTaxPickerInput
                isNullable
                nullLabel={t('ics.useTaxRateFromInvoice')}
                value={typeof overrideVat !== 'undefined' ? overrideVat : rule.vat ?? undefined}
                onChange={setOverrideVat}
                handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                disabled={vatIsDisabled}
                className="mt-0.5"
              />
              {rule.type === 'Repayment' && (
                <InvoicePrepaymentStatus 
                  advancePaymentSummary={advancePaymentSummary?.contrat}
                />
              )}
            </>
          }
        >
          <div className={cn('flex justify-between text-[15px]', centered ? 'items-center' : className)}>
            {name}
            {typeof value !== 'undefined' && (
              <span className="font-bold">
                <FormattedCurrency amount={value} options={{ maxDigits: 2, minDigits: 2 }} />
              </span>
            )}
            {children}
          </div>
        </DocumentViewerFileDataInlineEdit>
      </>
    );
  }
  return (
    <div className={cn('flex justify-between text-[15px]', centered ? 'items-center' : className)}>
      {name}
      {typeof value !== 'undefined' && (
        <span className="font-bold">
          <FormattedCurrency amount={value} options={{ maxDigits: 2, minDigits: 2 }} />
        </span>
      )}
      {children}
    </div>
  );
};

interface InvoiceCalculationCashDiscountSectionProps {
  rule: InvoiceCalculationRuleReadModel;
  isLast: boolean;
  invoice: InvoiceReadModel;
  paymentGroupValue: number;
  vatRendered: boolean;
  isLastInvoice?: boolean;
}

export const InvoiceCalculationCashDiscountSection = (props: InvoiceCalculationCashDiscountSectionProps) => {
  const { rule, isLast, invoice, paymentGroupValue, vatRendered, isLastInvoice = true } = props;
  const { t } = useTranslation();

  const skontoInfo = useMemo(() => {
    return (
      <>
        <div className="flex justify-between items-center text-[13px] font-bold mb-2">
          <span>{t('ics.exampleOutputValue')}</span>
          <span className="font-bold">
            <FormattedCurrency amount={paymentGroupValue} options={{ maxDigits: 2, minDigits: 2 }} />
          </span>
        </div>
        <InvoiceCalculationRow
          name={
            <div className="flex flex-col gap-1 leading-none">
              {t('ics.minusCashDiscount')}
              {invoice.cashDiscountDate && (
                <span className="font-normal text-xs">
                  {parseISO(invoice.cashDiscountDate) > new Date()
                    ? t('ics.cashDiscountUntil', {
                      date: formatDistanceToNow(invoice.cashDiscountDate, { addSuffix: true, includeSeconds: false }),
                    })
                    : formatDistanceToNow(invoice.cashDiscountDate, { addSuffix: true, includeSeconds: false })}
                </span>
              )}
            </div>
          }
          value={vatRendered ? rule.calculationResultGross : rule.calculationResultNet}
          className="font-bold leading-none"
          centered={false}
          rule={rule}
          invoiceId={invoice.id}
          calculationRuleGroupId="todo"
          editable={invoice.state === 'Pending'}
        />
      </>
    );
  }, [t, paymentGroupValue, invoice.cashDiscountDate, vatRendered, invoice.id, rule, invoice.state]);

  if (isLast) {
    return (
      <div className={cn('bg-primary -ml-6 -mr-6 text-white p-6 mt-4', isLastInvoice ? '-mb-[40px]' : '')}>
        {skontoInfo}
      </div>
    );
  }

  return skontoInfo;
};
