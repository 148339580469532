import { useTranslation } from 'react-i18next';
import {
  LoadingIndicator,
  WidgetContainer,
  WidgetContainerContent,
  WidgetContainerTitle,
} from '@client/shared/toolkit';
import { DashboardWidgetProps } from '../WidgetDashboard';
import cn from 'classnames';
import { ReactNode, useCallback, useMemo, useEffect, useState } from 'react';
import {
  FinancialUsageWidgetColumnType,
  FinancingSourceElementReadModel,
  FinancingSourcesTotalReadModel,
  FinancingSourcesUsesReportReadModel,
  FinancingUsesReadModel,
  FinancingUsesTotalReadModel,
  useApiPostFinancingSourcesReportMutation,
} from '@client/shared/api';
import { FormattedCurrency } from '../../FormattedCurrency';
import { i18n } from '@client/shared/utilities';
import { useLoadedProjectId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';

const getLabelForColumnType = (type: FinancialUsageWidgetColumnType) => {
  switch (type) {
    case 'Total':
      return i18n.t('dashboard.widget.financialUsage.columnType.total');
    case 'TotalPercentage':
      return i18n.t('dashboard.widget.financialUsage.columnType.totalPercentage');
    default:
      return '';
  }
};

const getFinancialSourceValueForColumnType = (type: FinancialUsageWidgetColumnType, data: FinancingSourceElementReadModel | FinancingUsesReadModel) => {
  switch (type) {
    case 'Total':
      return <FormattedCurrency amount={'value' in data ? data.value : data.costValue} />;
    case 'TotalPercentage':
      return `${data.percentage} %`;
    default:
      return '';
  }
};

export const DashboardWidgetFinancialUsage = (props: DashboardWidgetProps) => {
  const { widget } = props;
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();

  const [postFinancingSourcesReport, { isLoading }] = useApiPostFinancingSourcesReportMutation();
  const [financingData, setFinancingData] = useState<FinancingSourcesUsesReportReadModel | null>(null);

  useEffect(() => {
    if (loadedProjectId && widget?.additionalConfig?.FinancialUsage?.financingElements) {
      const getFinancingData = async () => {
        const resp = await safeMutation(
          postFinancingSourcesReport,
          {
            projectId: loadedProjectId ?? '',
            calculationModelId: widget?.additionalConfig?.FinancialUsage?.calculationModelId ?? '',
            body: {
              financingElementIds: widget?.additionalConfig?.FinancialUsage?.financingElements ?? []
            },
          },
          isLoading,
        );
        setFinancingData(resp ?? null);
      };

      try {
        getFinancingData();
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const sources = useMemo(() => {
    const sources: FinancingSourceElementReadModel[] = [];
    Object.values(financingData?.sources ?? {}).forEach(sourceGroup => {
      sources.push(...sourceGroup.sources);
    });
    return sources;
  }, [financingData]);

  return (
    <WidgetContainer>
      <WidgetContainerTitle>
        {widget.title ? widget.title : t('dashboard.widget.financialUsage.title')}
      </WidgetContainerTitle>
      <WidgetContainerContent className="flex-1 overflow-y-auto">
        {(isLoading) && <LoadingIndicator mode="overlay" />}
        {widget?.additionalConfig?.FinancialUsage?.columns && financingData && (
          <>
            <DashboardWidgetFinancialUsageTable type="sources" columns={widget?.additionalConfig?.FinancialUsage?.columns} dataSource={sources} totalsSource={financingData?.totalSources} />
            {financingData?.uses && financingData?.uses.length > 0 && (
              <>
                <div className="border-t -ml-3 -mr-3 mt-3 mb-3"></div>
                <DashboardWidgetFinancialUsageTable type="uses" columns={widget?.additionalConfig?.FinancialUsage?.columns} dataSource={financingData?.uses} totalsSource={financingData?.totalUses} />
              </>
            )}
          </>
        )}
      </WidgetContainerContent>
    </WidgetContainer>
  );
};

interface DashboardWidgetFinancialUsageRowProps {
  className: string;
  name: string;
  values: (string | ReactNode)[];
  row: number;
}

const DashboardWidgetFinancialUsageRow = (props: DashboardWidgetFinancialUsageRowProps) => {
  const { className, name, values, row } = props;
  return (
    <div className={cn('grid text-[13px] py-1 px-3 items-center gap-1', className)}>
      <div className="font-medium text-ellipsis overflow-hidden" title={name}>
        {name || '-'}
      </div>
      {values.map((value, i) => (
        <div className="text-right" key={`budget-status-row-${row}-value-${i}`}>
          {value}
        </div>
      ))}
    </div>
  );
};

interface DashboardWidgetFinancialUsageTableProps {
  type: 'sources' | 'uses';
  columns: FinancialUsageWidgetColumnType[];
  dataSource: FinancingSourceElementReadModel[] | FinancingUsesReadModel[];
  totalsSource: FinancingSourcesTotalReadModel | FinancingUsesTotalReadModel;
}

const DashboardWidgetFinancialUsageTable = (props: DashboardWidgetFinancialUsageTableProps) => {
  const { type, columns, dataSource, totalsSource } = props;
  const { t } = useTranslation();

  const gridCols = useMemo(() => {
    const columnsLength = columns.length;
    return columnsLength === 3 ? 'grid-cols-4' : columnsLength === 2 ? 'grid-cols-3' : 'grid-cols-2';
  }, [columns.length]);

  const getSumValueForColumnType = useCallback(
    (type: FinancialUsageWidgetColumnType, total: { total?: number; costTotal?: number; percentage?: number }) => {
      switch (type) {
        case 'Total':
          return <FormattedCurrency amount={'total' in total ? total.total : total.costTotal ?? 0} />;
        case 'TotalPercentage':
          return `${total.percentage ?? 0} %`;
        default:
          return '';
      }
    },
    [],
  );

  const getValuesForElement = useCallback(
    (source: FinancingSourceElementReadModel | FinancingUsesReadModel) => {
      return columns?.map((value) =>
        getFinancialSourceValueForColumnType(value, source)
      ) ?? [];
    },
    [columns],
  );

  return (
    <>
      <div className={cn('grid text-slate-400 text-[11px] gap-1 border-b pb-1 px-3', gridCols)}>
        <div>{t(`dashboard.widget.financialUsage.${type}`)}</div>
        {columns.map((value, i) => (
          <div className="text-right" key={`financial-usage-header-row-column-${i}`}>
            {getLabelForColumnType(value)}
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-1 divide-y">
        {dataSource.map((dataItem, i) => (
          <DashboardWidgetFinancialUsageRow
            key={`financial-usage-widget-${i}`}
            name={`${dataItem.description}`}
            values={getValuesForElement(dataItem)}
            className={gridCols}
            row={i}
          />
        ))}
      </div>
      <div className={cn('grid gap-1 border-t-4 border-double py-2 px-3 items-center', gridCols)}>
        <div className="font-bold text-[13px] leading-none truncate">
          {t(`dashboard.widget.financialUsage.row.sum${type.charAt(0).toUpperCase() + type.slice(1)}`)}
        </div>
        {columns.map((value, i) => (
          <div className="font-bold text-lg leading-none text-right" key={`financial-usage-footer-row-column-${i}`}>
            {getSumValueForColumnType(value, totalsSource)}
          </div>
        ))}
      </div>
    </>
  );
};