import {
  LoadingIndicator,
  NumberInput,
  PercentageIcon,
  StocksIcon
} from '@client/shared/toolkit';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectCalculationModelCostsSelect, ProjectUdfListSelect } from '../FeatureElement';

interface VatReturnInputProps {
  className?: string;
  vatReturnPercentage: number;
  setVatReturnPercentage?: (value: number) => void;
  returnDelayMonths?: number | null;
  setReturnDelayMonths?: (value: number | null | undefined) => void;
  selectedCostElementIds: string[];
  setSelectedCostElementIds: (value: string[]) => void;
  selectedUDF: string;
  setSelectedUDF: (value: string) => void;
  selectedUDFLabels: string[];
  setSelectedUDFLabels: (value: string[]) => void;
  type: 'create' | 'edit';
  disabled?: boolean;
}

export const VatReturnInput = ({
  className,
  vatReturnPercentage,
  setVatReturnPercentage,
  returnDelayMonths,
  setReturnDelayMonths,
  selectedCostElementIds,
  setSelectedCostElementIds,
  selectedUDF,
  setSelectedUDF,
  selectedUDFLabels,
  setSelectedUDFLabels,
  type,
  disabled,
}: VatReturnInputProps) => {
  const { t } = useTranslation();

  const [isFetchingUDFs, setIsFetchingUDFs] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  return (
    <div className={classNames('flex flex-wrap divide-y', className)}>
      {(isFetching || isFetchingUDFs) && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
      <div
        className={classNames('w-full flex', {
          'flex-col divide-y': type === 'edit',
          'flex-row divide-x': type === 'create',
        })}
      >
        <div
          className={classNames('flex w-1/2 divide-x', {
            'w-full': type === 'edit',
          })}
        >
          <NumberInput
            label={t('projectCalculate.vatReturnModal.vatReturnPercentage')}
            value={vatReturnPercentage}
            onChange={(value) => {
              if (setVatReturnPercentage) setVatReturnPercentage(value ?? 0);
            }}
            icon={<PercentageIcon />}
            disabled={disabled}
          />
          <NumberInput
            label={t('projectCalculate.vatReturnModal.vatReturnDelayMonths')}
            value={returnDelayMonths}
            onChange={(value) => {
              if (setReturnDelayMonths) setReturnDelayMonths(value ?? 0);
            }}
            icon={<StocksIcon />}
            disabled={disabled}
          />
        </div>
        <ProjectCalculationModelCostsSelect
          selectedElementIds={selectedCostElementIds}
          setSelectedElementIds={setSelectedCostElementIds}
          setIsFetching={setIsFetching}
          className={type === 'create' ? 'w-1/2' : 'w-full'}
          useStore={false}
        />
      </div>
      <div
        className={classNames('w-full flex flex-wrap', {
          'divide-y': type === 'edit',
          'divide-x': type === 'create' ,
        })}
      >
        <ProjectUdfListSelect
          selectedUDF={selectedUDF}
          setSelectedUDF={setSelectedUDF}
          setIsFetchingUDFs={setIsFetchingUDFs}
          selectedUDFLabels={selectedUDFLabels}
          setSelectedUDFLabels={setSelectedUDFLabels}
          disabled={disabled}
          widthClass={type === 'create' ? 'w-1/2' : 'w-full'}
        />
      </div>
    </div>
  );
};
