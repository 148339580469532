import {
  Button,
  DocumentPositionBoundingBoxReadModel,
  LoadingIndicator,
  SlideOver,
  SlideOverOnCloseProps,
  SlideOverTabOptions,
  SlideOverWithTabs,
} from '@client/shared/toolkit';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceDocumentFileDataRef, InvoiceEditContext } from '..';
import { useApiGetAiEvalDocumentPdfFileQuery, useApiGetAiEvalG702ResultQuery } from '@client/shared/api';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { G702DocumentViewer } from '../G702DocumentViewer';

export const G702DocumentReview = (props: SlideOverOnCloseProps) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const { documentId } = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [pdfFile, setPdfFile] = useState<string | null>(null);
  const [boxes] = useState<(DocumentPositionBoundingBoxReadModel | null)[]>([]);
  const [imageWidth] = useState(0);
  const [imageHeight] = useState(0);
  const [allData] = useState<{
    boxes: number[][][];
    pages: number[];
    texts: string[][];
  } | null>(null);

  const { unsavedData, isValid, setIsValid } = useContext(InvoiceEditContext);
  const invoiceDocumentFileDataRef = useRef<InvoiceDocumentFileDataRef>(null);
  const [isLoading, setIsLoading] = useState(false);

  const saveDocument = async () => {
    try {
      setIsValid(false);
      return await invoiceDocumentFileDataRef.current?.saveInvoice();
    } catch (e) {
      console.error(e);
    }
  };

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  if (!documentId) {
    navigate(ROUTES_CONFIG.PROJECT_CONTROL.name);
  }

  const { data: fetchedFile, isFetching: isFetchingFile } = useApiGetAiEvalDocumentPdfFileQuery(
    {
      documentId: documentId ?? '',
      projectId: loadedProjectId ?? 'unset',
    },
    {
      skip: !documentId || !loadedProjectId,
    },
  );

  const {
    data: processedData,
    isFetching: isFetchingProcessedData,
    isError,
  } = useApiGetAiEvalG702ResultQuery(
    {
      documentId: documentId ?? '',
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !documentId || !loadedProjectId,
    },
  );

  useEffect(() => {
    if (fetchedFile) {
      setPdfFile(fetchedFile);
    }
  }, [fetchedFile]);

  useEffect(() => {
    if (isError || (processedData && processedData?.document.state !== 'Succeeded')) {
      navigate(ROUTES_CONFIG.PROJECT_CONTROL.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processedData, isError]);

  const tabOptions: SlideOverTabOptions[] = useMemo(() => {
    return [
      {
        header: t('projectControl.invoiceTabAudit'),
        name: 'audit',
        panel: (
          <G702DocumentViewer
            invoiceDocument={processedData}
            file={file}
            pdfFile={pdfFile}
            setFile={setFile}
            boxes={boxes}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            allBoundingBoxes={allData}
            loadedProjectId={loadedProjectId ?? ''}
            setIsFormValid={setIsValid}
            onClose={() => onClose(true)}
            setIsLoading={setIsLoading}
            rawResult={processedData?.rawJson}
          />
        ),
      },
    ];
  }, [
    t,
    file,
    pdfFile,
    boxes,
    imageWidth,
    imageHeight,
    allData,
    onClose,
    loadedProjectId,
    processedData,
    setIsValid,
  ]);

  const fileData = useMemo(() => {
    return processedData?.result;
  }, [processedData?.result]);

  const slideOverTitle = useMemo(() => {
    if (!fileData) {
      return processedData?.document?.docType;
    }
    return (
      <>
        <div className="flex text-2xl">
          G702
          {/* {fileData.contractorName && <span className="whitespace-nowrap truncate">{fileData.contractorName}</span>} */}
        </div>
        {/* {(fileData.invoiceDetails.number || fileData.contractorName) && (
          <div className="text-gray-500 text-[13px]">
            {fileData.invoiceDetails.number && (
              <span className="font-bold">{fileData.invoiceDetails.number}&nbsp;</span>
            )}
            {fileData.customerName && (
              <span className="whitespace-nowrap truncate">&nbsp;•&nbsp;{fileData.customerName}</span>
            )}
          </div>
        )} */}
      </>
    );
  }, [fileData, processedData?.document?.docType]);

  return (
    <SlideOverWithTabs
      tabOptions={tabOptions}
      onClose={onClose}
      title={slideOverTitle}
      subtitle={!documentId ? t('projectControl.createNewInvoiceDocument') : ''}
    >
      {(isLoading || isFetchingFile || isFetchingProcessedData) && (
        <LoadingIndicator text={t('projectControl.fetchingInvoiceLoadingIndicator')} mode="overlay" />
      )}
      <SlideOver.Controls className="items-center">
        {!isValid && (
          <span className="text-sm italic text-red-700 mr-4">
            {t('projectControl.createInvoiceInvalidFormMessage')}
          </span>
        )}
        <div className="flex items-center flex-wrap lg:flex-nowrap justify-end">
          {unsavedData.length > 0 && (
            <div className="flex-none md:flex-1 flex-wrap w-full md:w-auto order-1 md:order-0 text-right mr-4 mt-2 md:mt-0 flex justify-end gap-2">
              {unsavedData.length > 0 && (
                <span className="text-sm italic text-secondary leading-none">
                  {t('projectControl.editInvoiceUnsavedDataMessage')}
                </span>
              )}
            </div>
          )}
        </div>
        <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
          {t('common.cancel')}
        </Button>
        <Button
          variant="primary"
          className="mr-2"
          disabled={unsavedData.length === 0 || !isValid}
          onClick={() => {
            saveDocument();
          }}
        >
          {t('common.save')}
        </Button>
      </SlideOver.Controls>
    </SlideOverWithTabs>
  );
};
