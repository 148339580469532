import {
  Form,
  FormField,
  FormRefHandle,
  SlideOver,
  SlideOverOnCloseProps,
  PercentageIcon, NumberInput, TextInput, Button, Modal
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import * as yup from 'yup';
import { InferType } from 'yup';
import { TagIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { ContractDeletePerformancePercentageModal } from './ContractDeletePerformancePercentageModal';

// TODO from api
export type PerformancePercentageReadModel = {
  id: string;
  name: string;
  percentage: number;
  createdAt: string;
  restValue: number;
}

export const ContractCreatePerformancePercentageFormValidationSchema = yup.object({
  name: yup.string().required('validation.required'),
  percentage: yup.number().required('validation.required')
});

type ContractCreatePerformancePercentageFormValidationValues = InferType<typeof ContractCreatePerformancePercentageFormValidationSchema>;

interface ContractCreatePerformancePercentageSlideOverProps extends SlideOverOnCloseProps {
  performancePercentage?: PerformancePercentageReadModel | null;
}

export const ContractCreatePerformancePercentageSlideOver = (props: ContractCreatePerformancePercentageSlideOverProps) => {
  const { onClose, performancePercentage } = props;
  const { t } = useTranslation();
  const formRef = useRef<FormRefHandle<ContractCreatePerformancePercentageFormValidationValues>>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleSubmit = async (data: ContractCreatePerformancePercentageFormValidationValues) => {
    // TODO
    console.log(data, 'Data');
  }

  const isEditing = performancePercentage?.id;

  const defaultValues = {
    name: '',
    percentage: 0
  }

  return (
    <>
      <SlideOver.Header
        onClose={onClose}
        title={t('projectContract.completionLevel.performancePercentage.percentage')}
        subTitle={t('projectContract.completionLevel.performancePercentage.create.subtitle')}
        backgroundClassName="bg-gray-700"
      />
      <Form<ContractCreatePerformancePercentageFormValidationValues>
        defaultValues={defaultValues}
        ref={formRef}
        className="w-full flex flex-col flex-grow min-h-0"
        onSubmit={handleSubmit}
        validationSchema={ContractCreatePerformancePercentageFormValidationSchema}
      >
        <SlideOver.Content className="p-8 overflow-y-auto">
          <div className="flex flex-grow flex-col gap-0.5">
            <FormField name="name">
              {(control) => (
                <TextInput
                  label={t('projectContract.completionLevel.performancePercentage.name')}
                  icon={<TagIcon />}
                  {...control}
                />
              )}
            </FormField>
            <FormField name="percentage">
              {(control) => (
                <NumberInput
                  label={t('projectContract.completionLevel.performancePercentage.percentage')}
                  icon={<PercentageIcon />}
                  min={0}
                  max={100}
                  {...control}
                />
              )}
            </FormField>
          </div>
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className={classNames('w-full flex justify-end', { 'justify-between': isEditing })}>
            {isEditing && (
              <Button
                variant="warning"
                onClick={() => {
                  setIsDeleteModalOpen(true);
                  onClose(false);
                }}
              >
                {t('common.delete')}
              </Button>
            )}

            <div className="flex">
              <Button
                variant="secondary"
                className="mr-2"
                onClick={() => {
                  onClose(false);
                }}
              >
                {t('common.close')}
              </Button>
              <Button
                variant="primary"
                className="mr-2"
                formSubmit={true}
              >
                {t(isEditing ? 'common.update' : 'common.add')}
              </Button>
            </div>
          </div>
        </SlideOver.Controls>
      </Form>
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        {performancePercentage && (
          <ContractDeletePerformancePercentageModal item={performancePercentage} onClose={() => setIsDeleteModalOpen(false)} />
        )}
      </Modal>
    </>
  )
}
