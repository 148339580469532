import {
  // useApiGetTenantCompaniesQuery,
  useApiGetTenantCompaniesPagedQuery,
  CompanyReadModel,
  CompanyPersonReadModel,
  CompanyBranchReadModel,
  CompanyAssignmentReadModel,
} from '@client/shared/api';
import {
  LevelToggle,
  ContextMenuItem,
  FloatingActionButton,
  LoadingIndicator,
  Modal,
  SlideOver,
  AddIcon,
  Pagination
} from '@client/shared/toolkit';
import React, { Ref, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
  EditPartnerBranchSlideOver,
  CompanyDeleteModal,
  ContactDetailsSlideOver,
  PartnerCompaniesProjectEditSlideOver,
} from '.';
import { useTranslation } from 'react-i18next';
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import { PartnerCompanyImportSlideOver } from './import';
import { SettingsHeader } from '../SettingsHeader';
import { PrivatePersonDetailsSlideOver } from './PrivatePersonDetailsSlideOver';
import { PartnerCompanyEditSlideOver } from './PartnerCompanyEditSlideOver';
import { PartnerCompanyList } from './PartnerCompanyList';
import { SettingsSearchHeaderRef } from '../SettingsSearchHeader';
import { setExpandedCompanyIds, useExpandedCompanyIds } from '@client/project/store';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ProtectedRoute, ROUTES_CONFIG } from '@client/shared/permissions';
import { PartnerCompanyView } from './PartnerCompanyView';

export const PartnerCompanies = React.forwardRef((props, ref: Ref<SettingsSearchHeaderRef>) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const expandedCompanyIds = useExpandedCompanyIds();

  const [searchText, setSearchText] = useState<string>('');
  const [isAdd, setIsAdd] = useState<boolean>(false);

  const amountPerPage = 8;
  // const [startIndex, setStartIndex] = useState(0);
  // const [endIndex, setEndIndex] = useState(amountPerPage);
  const [selectedPage, setSelectedPage] = useState(1);

  /* const updateShownItems = useCallback((startIndex: number, endIndex: number) => {
    setStartIndex(startIndex);
    setEndIndex(endIndex);
  }, []); */

  const [openEditSlideOver, setOpenEditSlideOver] = useState<
    | 'contact'
    | 'branch'
    | 'tenantTrust'
    | 'privatePerson'
    | 'createCompany'
    | 'deleteCompany'
    | 'addBranch'
    | 'import'
    | null
  >(null);

  const [selectedCompany, setSelectedCompany] = useState<CompanyReadModel | undefined>();
  const [selectedContact, setSelectedContact] = useState<CompanyPersonReadModel | null>(null);
  const [selectedBranch, setSelectedBranch] = useState<CompanyBranchReadModel | null>(null);
  const [selectedProject, setSelectedProject] = useState<CompanyAssignmentReadModel | null>(null);
  const [filters, setFilters] = useState<string[]>([]);

  //const { data, isFetching } = useApiGetTenantCompaniesQuery();
  const { data, isFetching } = useApiGetTenantCompaniesPagedQuery({ page: selectedPage, pageSize: amountPerPage, search: searchText});

  const tenantCompanies = useMemo(() => {
    return data?.data ?? [];
  }, [data?.data]);

  // update the selected company and selected branch for the slide overs
  useEffect(() => {
    if (data) {
      if (selectedCompany) {
        const foundUpdatedCompany = data.data.find((comp) => comp.id === selectedCompany.id);
        if (foundUpdatedCompany) {
          setSelectedCompany(foundUpdatedCompany);
        }
      }
      if (selectedBranch || selectedContact) {
        let foundUpdatedBranch: CompanyBranchReadModel | undefined = undefined;
        let foundUpdatedPerson: CompanyPersonReadModel | undefined = undefined;
        data.data.forEach((comp) => {
          if (comp.branches && selectedBranch) {
            if (!foundUpdatedBranch) {
              foundUpdatedBranch = comp.branches?.find((b) => b.id === selectedBranch.id);
            }
          }
          if (comp.persons && selectedContact) {
            if (!foundUpdatedPerson) {
              foundUpdatedPerson = comp.persons?.find((p) => p.id === selectedContact.id);
            }
          }
        });
        if (selectedBranch && foundUpdatedBranch) {
          const updatedBranch = foundUpdatedBranch as CompanyBranchReadModel;
          setSelectedBranch(updatedBranch);
          if (selectedProject && updatedBranch.projectAssignments.length) {
            const foundProject = updatedBranch.projectAssignments.find(
              (project) => project.project.projectId === selectedProject.project.projectId,
            );
            if (foundProject) {
              setSelectedProject(foundProject);
            } else {
              setSelectedProject(null);
            }
          } else {
            setSelectedProject(null);
          }
        }
        if (selectedContact && foundUpdatedPerson) {
          setSelectedContact(foundUpdatedPerson);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const contextItems: ContextMenuItem[] = [
    {
      label: t('common.create'),
      subtitle: t('app.companiesAddCompany'),
      icon: <AddIcon />,
      onClick: () => onAddCompany(),
    },
    {
      label: t('common.create'),
      subtitle: t('app.companiesAddPrivatePerson'),
      icon: <AddIcon />,
      onClick: () => onAddPrivatePerson(),
    },
    {
      label: t('common.upload'),
      subtitle: t('app.companiesUpload'),
      icon: <CloudArrowUpIcon />,
      onClick: () => {
        setOpenEditSlideOver('import');
      },
    },
  ];

  const resetSlideOver = () => {
    setOpenEditSlideOver(null);
    setSelectedBranch(null);
    setSelectedContact(null);
    setSelectedCompany(undefined);
    setSelectedProject(null);
  };

  const onEdit = (company: CompanyReadModel) => {
    setSelectedCompany(company);

    if (company.type === 'Company') {
      setOpenEditSlideOver('createCompany');
    } else {
      setOpenEditSlideOver('privatePerson');
    }
    setIsAdd(false);
  };

  const onShow = (company: CompanyReadModel) => {
    setSelectedCompany(company);

    if (company.type === 'Company') {
      navigate(ROUTES_CONFIG.SETTINGS_PARTNER_COMPANY_VIEW.path.replace(':companyId', company.id ?? ''));
    } else {
      setOpenEditSlideOver('privatePerson');
    }
    setIsAdd(false);
  };

  const onAddCompany = () => {
    setOpenEditSlideOver('createCompany');
    setIsAdd(true);
  };

  const onAddPrivatePerson = () => {
    setOpenEditSlideOver('privatePerson');
    setIsAdd(true);
  };

  const onAddBranch = (company: CompanyReadModel) => {
    setSelectedCompany(company);
    setOpenEditSlideOver('addBranch');
    setIsAdd(true);
  };

  const onDelete = (company: CompanyReadModel) => {
    setSelectedCompany(company);
    setOpenEditSlideOver('deleteCompany');
  };

  const expandedIds = useMemo(() => {
    // no expanded ids set, yet, so we return all branches and company ids (persons cannot be toggled)
    if (!expandedCompanyIds) {
      const ids: string[] = [];
      tenantCompanies.forEach((company) => {
        ids.push(company.id);
        if (company.branches.length) {
          company.branches.forEach((branch) => {
            ids.push(branch.id);
          });
        }
      });
      return ids;
    }
    return expandedCompanyIds;
  }, [tenantCompanies, expandedCompanyIds]);

  // const filteredCompanies = useMemo(() => {
  //   const filteredByFilters = tenantCompanies.filter((company) => {
  //     if (filters.length === 0) return true;
  //     return (
  //       filters.includes('tenantTrust') &&
  //       company.branches.some((branch) => branch.projectAssignments.some((p) => p.tenantTrust))
  //     );
  //   }).sort((a, b) => a.name.localeCompare(b.name, undefined, {sensitivity: 'case'}));

  //   if (searchText && searchText.trim()) {
  //     const searchVal = searchText.trim().toLowerCase();
  //     return filteredByFilters.filter((x) => {
  //       return (
  //         x.name.toLowerCase().includes(searchVal) ||
  //         (x.taxId && x.taxId.toLowerCase().includes(searchVal)) ||
  //         (x.companyRegistrationNumber && x.companyRegistrationNumber.toLowerCase().includes(searchVal)) ||
  //         x.branches.find((branch) => {
  //           return (
  //             branch.name.toLowerCase().includes(searchVal) ||
  //             (branch.email && branch.email.toLowerCase().includes(searchVal)) ||
  //             (branch.phone && branch.phone.toLowerCase().includes(searchVal)) ||
  //             (branch.mobile && branch.mobile.toLowerCase().includes(searchVal)) ||
  //             (branch.creditorId && branch.creditorId.toLowerCase().includes(searchVal)) ||
  //             (branch.debitorId && branch.debitorId.toLowerCase().includes(searchVal)) ||
  //             (branch.spvId && branch.spvId.toLowerCase().includes(searchVal)) ||
  //             (branch.address &&
  //               ((branch.address.street && branch.address.street.toLowerCase().includes(searchVal)) ||
  //                 (branch.address.postalCode && branch.address.postalCode.toLowerCase().includes(searchVal)) ||
  //                 (branch.address.city && branch.address.city.toLowerCase().includes(searchVal))))
  //           );
  //         }) ||
  //         x.persons.find((p) => {
  //           return (
  //             p.fullname.toLowerCase().includes(searchVal) ||
  //             (p.email && p.email.toLowerCase().includes(searchVal)) ||
  //             (p.phone && p.phone.toLowerCase().includes(searchVal)) ||
  //             (p.mobile && p.mobile.toLowerCase().includes(searchVal))
  //           );
  //         })
  //       );
  //     });
  //   }
  //   return filteredByFilters;
  // }, [filters, searchText, tenantCompanies]);

  const collapse = useCallback(
    (level: number) => {
      const ids: string[] = [];
      if (level > 1) {
        tenantCompanies.forEach((company) => {
          ids.push(company.id);
          if (level > 2 && company.branches.length) {
            company.branches.forEach((branch) => {
              ids.push(branch.id);
            });
          }
        });
      }
      dispatch(setExpandedCompanyIds(ids));
    },
    [tenantCompanies, dispatch],
  );

  useEffect(() => {
    if (filters.includes('tenantTrust')) {
      const ids: string[] = [];
      tenantCompanies.forEach((company) => {
        if (company.branches.some((branch) => branch.projectAssignments.some((p) => p.tenantTrust))) {
          ids.push(company.id);
          company.branches.forEach((branch) => {
            if (branch.projectAssignments.some((p) => p.tenantTrust)) {
              ids.push(branch.id);
            }
          });
        }
      });
      dispatch(setExpandedCompanyIds(ids));
    }
  }, [filters, tenantCompanies, dispatch]);

  const filterGroups = useMemo(() => {
    return [
      {
        options: [{ label: t('uploadPortal.uploadPortalActive'), type: 'tenantTrust', id: 'tenantTrust' }],
        visible: true,
        title: t('uploadPortal.title')
      }
    ]
  }, [t])

  useImperativeHandle(ref, () => ({
    handleOnCollapse: collapse,
    setSearchText: (text: string) => setSearchText(text),
    getSearchText: () => searchText ?? '',
    getFilters: () => filters,
    setFilters: (filter: string[]) => setFilters(filter),
    getFilterGroups: () => filterGroups,
    isPartnerCompanies: true,
  }));

  /* const visibleCompanies = useMemo(() => {
    return tenantCompanies.slice(startIndex, endIndex);
  }, [tenantCompanies, startIndex, endIndex]); */

  return (
    <div className="w-full">
      {isFetching === true && <LoadingIndicator text={t('app.settingsCompaniesLoading') ?? ''} />}
      <div className="flex items-end">
        <SettingsHeader
          searchText={searchText}
          setSearchText={setSearchText}
          /* filterGroups={filterGroups}
          handleFilterSearch={(value, filters) => {
            setFilters(filters.map((filter) => filter.id));
            setSearchText(value);
          }}
          toggledFilters={filters} */
        >
          <div className="flex justify-between">
            {t('app.companiesContacts')}
            <LevelToggle
              buttonClassName="bg-white hover:bg-slate-500 text-slate-500 hover:text-white focus:ring w-10 flex items-center justify-center"
              handleOnCollapse={collapse}
              variant="custom"
            />
          </div>
        </SettingsHeader>
      </div>

      <div className="relative h-full my-8">
        {tenantCompanies.map((company, i) => (
          <PartnerCompanyList
            key={`company-list-${company.id}`}
            company={company}
            searchText={searchText}
            className={i > 0 ? 'mt-8' : ''}
            onAdd={onAddBranch}
            onEdit={onEdit}
            onDelete={onDelete}
            onShow={onShow}
            onEditContact={(contact) => {
              setSelectedCompany(company);
              setSelectedContact(contact);
              setOpenEditSlideOver('contact');
            }}
            onEditBranch={(branch) => {
              setSelectedCompany(company);
              setSelectedBranch(branch);
              setOpenEditSlideOver('branch');
            }}
            onShowTenantTrust={(project, branch, company) => {
              setSelectedCompany(company);
              setSelectedBranch(branch);
              setSelectedProject(project);
              setOpenEditSlideOver('tenantTrust');
            }}
            expandedIds={expandedIds}
          />
        ))}

        {/* {filteredCompanies.length > amountPerPage && ( */}
          <Pagination
            totalItems={data?.totalCount ?? 0}
            //setShownItems={updateShownItems}
            setSelectedPage={(page) => setSelectedPage(page)}
            amountPerPage={amountPerPage}
          />
        {/* )} */}
      </div>

      <FloatingActionButton menuItems={contextItems} position="fixed bottom-16 right-24" />

      {/* COMPANY - IMPORT */}
      <SlideOver
        isOpen={openEditSlideOver === 'import'}
        variant="large"
        onClose={() => setOpenEditSlideOver(null)}
        onAfterLeave={resetSlideOver}
      >
        <PartnerCompanyImportSlideOver onClose={() => setOpenEditSlideOver(null)} />
      </SlideOver>

      {/* COMPANY - CREATE / EDIT */}
      <SlideOver
        isOpen={openEditSlideOver === 'createCompany'}
        onClose={() => setOpenEditSlideOver(null)}
        onAfterLeave={resetSlideOver}
      >
        <PartnerCompanyEditSlideOver company={selectedCompany} onClose={() => setOpenEditSlideOver(null)} />
      </SlideOver>

      {/* PRIVATE PERSON - CREATE / EDIT */}
      <SlideOver
        isOpen={openEditSlideOver === 'privatePerson'}
        onClose={() => setOpenEditSlideOver(null)}
        onAfterLeave={resetSlideOver}
      >
        <PrivatePersonDetailsSlideOver
          isAddMode={isAdd}
          company={selectedCompany}
          onClose={() => setOpenEditSlideOver(null)}
        />
      </SlideOver>

      {/* BRANCH - ADD */}
      <SlideOver
        isOpen={openEditSlideOver === 'addBranch'}
        onClose={() => setOpenEditSlideOver(null)}
        onAfterLeave={resetSlideOver}
      >
        <EditPartnerBranchSlideOver
          isAddMode={isAdd}
          company={selectedCompany}
          onClose={() => setOpenEditSlideOver(null)}
        />
      </SlideOver>

      {/* COMPANY - DELETE */}
      <Modal
        isOpen={openEditSlideOver === 'deleteCompany'}
        onClose={() => setOpenEditSlideOver(null)}
        onAfterLeave={resetSlideOver}
      >
        <CompanyDeleteModal company={selectedCompany} onClose={() => setOpenEditSlideOver(null)} />
      </Modal>

      {/* CONTACT - EDIT */}
      <SlideOver
        isOpen={openEditSlideOver === 'contact'}
        onClose={() => setOpenEditSlideOver(null)}
        onAfterLeave={resetSlideOver}
      >
        <ContactDetailsSlideOver
          isAdd={false}
          company={selectedCompany}
          contact={selectedContact}
          onClose={() => setOpenEditSlideOver(null)}
        />
      </SlideOver>

      {/* BRANCH - EDIT */}
      <SlideOver
        isOpen={openEditSlideOver === 'branch'}
        onClose={() => setOpenEditSlideOver(null)}
        onAfterLeave={resetSlideOver}
      >
        <EditPartnerBranchSlideOver
          isAddMode={false}
          branch={selectedBranch ?? undefined}
          company={selectedCompany}
          onClose={() => setOpenEditSlideOver(null)}
        />
      </SlideOver>

      {/* TENANT TRUST */}
      <PartnerCompaniesProjectEditSlideOver
        isOpen={openEditSlideOver === 'tenantTrust'}
        onClose={() => setOpenEditSlideOver(null)}
        company={selectedCompany}
        project={selectedProject}
        branch={selectedBranch}
        resetProject={resetSlideOver}
      />

      <Routes>
        {/* COMPANY - VIEW */}
        <Route
          path={ROUTES_CONFIG.SETTINGS_PARTNER_COMPANY_VIEW.name}
          element={
            <ProtectedRoute routeConfig={ROUTES_CONFIG.SETTINGS_PARTNER_COMPANY_VIEW}>
              <PartnerCompanyView />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    </div>
  );
});
