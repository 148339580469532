import * as yup from 'yup';
import { InferType } from 'yup';

export const ContractEditFormValidationSchema = yup.object({
  id: yup.string().required('validation.required'),
  code: yup.string().required('validation.required'),
  name: yup.string().required('validation.required'),
  description: yup.string().optional().nullable(),
  contractDate: yup.date().optional().nullable(),
  vat: yup
    .number()
    .typeError('validation.number')
    .test('range', 'validation.rangeHundred', (value) => (value as number) >= 0 && (value as number) <= 100)
    .optional(),
  discount: yup
    .number()
    .optional()
    .typeError('validation.number')
    .test('range', 'validation.rangeHundred', (value) => !value || ((value as number) >= 0 && (value as number) <= 100)),
  dueDateDeadline: yup
    .number()
    .optional()
    .integer()
    .typeError('validation.number')
    .test('range', 'validation.rangeHundred', (value) => !value || (value as number) >= 0),
  cashDiscountDeadline: yup
    .number()
    .optional()
    .integer()
    .typeError('validation.number')
    .test('range', 'validation.rangeHundred', (value) => !value || (value as number) >= 0),
  contractorId: yup.string().optional().nullable(),
  clientId: yup.string().optional().nullable(),
  commitmentId: yup.string().optional().nullable(),
  cumulatedInvoiceCalculationScheme: yup.string().optional().nullable(),
  singleInvoiceCalculationScheme: yup.string().optional().nullable(),
  advancePaymentInvoiceCalculationScheme: yup.string().optional().nullable(),
  workflowDefinitionId: yup.string().optional().nullable(),
  permissionGroups: yup.array().of(yup.string().required('validation.required')),
});

export type ContractEditFormValidationValues = InferType<typeof ContractEditFormValidationSchema>;
