import {
  BudgetLevel,
  CommitmentReadModel,
  ContractReadModel,
  useApiGetCalculationModelCostsQuery,
  useApiPostUpdateCommitmentMutation,
  useApiPostUpdateContractMutation,
} from '@client/shared/api';
import {
  BudgetAssignmentCustomIcon,
  ContextMenu,
  ContextMenuItem,
  DecoratedCard,
  DecoratedCardAddButton,
  ListTitle, LoadingIndicator, Modal, PencilIcon,
  SlideOverSortableList,
  SlideOverSortableListItemData, TrashIcon,
} from '@client/shared/toolkit';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAvailableBudget, useFlattenCostElements, BudgetLevelModal, BudgetModal, FormattedCurrency } from '../..';
import { findCostElement, timelineReadModelToPayload } from '../../utils';
import { formatPercentage, safeMutation } from '@client/shared/utilities';
import { useLoadedProjectId, useLoadedProjectValueType, useLoadedVariantId } from '@client/project/store';

interface ContractBudgetingProps {
  contract?: ContractReadModel;
  commitment?: CommitmentReadModel | null
}

export const ContractBudgeting = (props: ContractBudgetingProps) => {
  const { contract, commitment } = props;
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const calculateValueType = useLoadedProjectValueType();

  const [postUpdateContract, { isLoading }] = useApiPostUpdateContractMutation();
  const [postUpdateCommitment, { isLoading: isUpdating }] = useApiPostUpdateCommitmentMutation();

  const [isBudgetModalOpen, setIsBudgetModalOpen] = useState(false);
  const [selectedBudget, setSelectedBudget] = useState('')

  const [sourceBudgetLevel, setSourceBudgetLevel] = useState<BudgetLevel | null>();
  const [showBudgetLevelModal, setShowBudgetLevelModal] = useState<boolean>(false);

  const handleBudgetDelete = useCallback(async (id: string) => {
    if (loadedProjectId && loadedVariantId) {
      if (contract) {
        const singleInvoiceCalculationScheme = contract.invoiceCalculationSchemes.find((scheme) => scheme.invoiceCalculationScheme.type === 'Single')
        const cumulatedInvoiceCalculationScheme = contract.invoiceCalculationSchemes.find((scheme) => scheme.invoiceCalculationScheme.type === 'Cumulated')
        const advancePaymentInvoiceCalculationScheme = contract.invoiceCalculationSchemes.find((scheme) => scheme.invoiceCalculationScheme.type === 'AdvancePayment')

        await safeMutation(
          postUpdateContract,
          {
            contractId: contract.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: {
              code: contract.code,
              name: contract.name,
              description: contract.description,
              vat: contract.vat,
              discount: contract.discount,
              dueDateDeadline: contract.dueDateDeadline,
              cashDiscountDeadline: contract.cashDiscountDeadline,
              contractorId: contract.contractor?.id,
              clientId: contract.client?.id,
              commitmentId: contract.commitmentId,
              elementTimeline: contract?.elementTimeline ? timelineReadModelToPayload(contract.elementTimeline) : null,
              addedBudgetAssignments: [],
              updatedBudgetAssignments: [],
              deletedBudgetAssignments: [id],
              permissionGroups: contract.permissionGroups.map(x => x.id),
              singleInvoiceCalculationScheme: singleInvoiceCalculationScheme
                ? { invoiceCalculationSchemeId: singleInvoiceCalculationScheme.invoiceCalculationScheme.invoiceCalculationSchemeId, valueOverrides: [] }
                : null,
              cumulatedInvoiceCalculationScheme: cumulatedInvoiceCalculationScheme
                ? { invoiceCalculationSchemeId: cumulatedInvoiceCalculationScheme.invoiceCalculationScheme.invoiceCalculationSchemeId, valueOverrides: [] }
                : null,
              advancePaymentInvoiceCalculationScheme: advancePaymentInvoiceCalculationScheme
                ? { invoiceCalculationSchemeId: advancePaymentInvoiceCalculationScheme.invoiceCalculationScheme.invoiceCalculationSchemeId, valueOverrides: [] }
                : null,
            },
          },
          isLoading,
        );
      }
      if (commitment) {
        await safeMutation(
          postUpdateCommitment,
          {
            commitmentId: commitment.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: {
              code: commitment.code,
              name: commitment.name,
              description: commitment.description,
              addedContracts: [],
              deletedContracts: [],
              addedBudgetAssignments: [],
              updatedBudgetAssignments: [],
              deletedBudgetAssignments: [id],
              permissionGroups: commitment.permissionGroups.map(x => x.id),
            },
          },
          isUpdating
        )
      }
    }
  }, [loadedProjectId, loadedVariantId, contract, postUpdateContract, isLoading, commitment, postUpdateCommitment, isUpdating])

  const commitmentHasBudgeting = useMemo(() => {
    if (contract?.commitmentId && !contract.canBeBudgeted && !contract.budgetAssignments.length) {
      if (contract.titles.length) {
        const foundTitlesWithBudget = contract.titles.find((title) => title.budgetAssignments.length > 0);
        if (foundTitlesWithBudget) {
          return false;
        }
        // contract does not have assignments and titles don't have assignments, so the commitment does
      }
      return true;
    }
    return false;
  }, [contract]);

  const { data: costData, isFetching: isFetchingCostElements } = useApiGetCalculationModelCostsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: !loadedVariantId || !loadedProjectId },
  );

  const flattenedCostElements = useFlattenCostElements(costData, true);

  const getCostElement = useCallback(
    (id: string) => {
      return findCostElement(id, flattenedCostElements);
    },
    [flattenedCostElements],
  );

  const budgetItems: SlideOverSortableListItemData[] = useMemo(() => {
    const items: SlideOverSortableListItemData[] = [];

    const budgets = contract?.budgetAssignments ?? commitment?.budgetAssignments ?? []
    if (budgets.length) {
      budgets.forEach((budget) => {
        const contextMenu = (
          <ContextMenu
            stopPropagation
            items={[
              {
                label: t('common.edit'),
                icon: <PencilIcon />,
                onClick: () => {
                  setSelectedBudget(budget.id)
                  setIsBudgetModalOpen(true)
                },
              },
              {
                label: t('common.delete'),
                icon: <TrashIcon />,
                onClick: () => {
                  handleBudgetDelete(budget.id)
                },
                stopPropagation: true
              },
            ]}
          />
        );

        const costElement = getCostElement(budget.costElementId);
        const availableBudget = costElement ? getAvailableBudget(calculateValueType, costElement) : 0;

        items.push({
          icon: <BudgetAssignmentCustomIcon className="w-full text-gray-400" />,
          id: budget.id,
          name: `${budget.code} ${budget.description ? budget.description : t('projectCalculate.unnamedElement')}`,
          cols: [
            {
              value: formatPercentage(availableBudget === 0 ? 1 : budget.budgetNet / availableBudget),
              title: t('projectContract.budgetPercentageOfGroupShort'),
              header: t('projectContract.budgetPercentageOfGroup')
            },
            {
              value: <FormattedCurrency amount={budget.budgetNet} options={{ maxDigits: 2, minDigits: 2 }} />,
              title: t('projectContract.budgetNet'),
              header: t('projectContract.budgetNet')
            },
          ],
          contextMenu: contextMenu,
        });
      });
    }
    return items;
  }, [contract?.budgetAssignments, commitment?.budgetAssignments, calculateValueType, t, getCostElement, handleBudgetDelete]);


  const message = useMemo(() => {
    if (contract && !contract.canBeBudgeted) {
      return commitmentHasBudgeting ? t('projectContract.contractBudgetedByCommitmentMessage') : t('projectContract.contractCannotBeBudgetedMessage')
    }
    if (commitment && !commitment.canBeBudgeted) {
      return t('projectContract.commitmentCannotBeBudgetedMessage')
    }
    return ''
  }, [contract, t, commitment, commitmentHasBudgeting])

  const handleShowBudgetLevelModal = () => {
    const sourceLevel: BudgetLevel = contract !== (null || undefined)
      ? 'Contract'
      : 'Commitment';

    setSourceBudgetLevel(sourceLevel);
    setShowBudgetLevelModal(true);
  }

  const menuItems: ContextMenuItem[] = [
    {
      label: t('projectContract.budgeting'),
      subtitle: t('projectContract.budgetingEdit'),
      onClick: () => {setIsBudgetModalOpen(true)},
      isDisabled: (!contract?.canBeBudgeted && !commitment?.canBeBudgeted)
    },
    {
      label: t('projectContract.budgetinglevel'),
      subtitle: t('projectContract.budgetinglevel.description'),
      onClick: () => {handleShowBudgetLevelModal()},
      isDisabled: (((contract?.titles.length ?? 0) === 0 && (commitment?.contracts.length ?? 0) === 0) || !contract?.budgetAssignments.length)
    },
  ]

  return (
    <div className="pt-6">
      {isUpdating && (<LoadingIndicator text={t('projectContract.savingContractLoadingIndicator')} mode="overlay-window" />)}
      {(isLoading || isFetchingCostElements) && (<LoadingIndicator text={t('common.loading')} mode="overlay-window" />)}
      <DecoratedCard shadowVariant="normal">
        <DecoratedCard.Content>
          <ListTitle title={t('projectContract.budget')} color="bg-emerald-500" />
          {message && (
            <div className="w-full flex items-center text-xs text-slate-600 mx-6 mb-4">
              {message}
            </div>
          )}
          {(contract?.canBeBudgeted || commitment?.canBeBudgeted) && (
            <SlideOverSortableList
              data={budgetItems}
              headline=""
              handleSelect={(i) => {
                setSelectedBudget(contract ? contract.budgetAssignments[i].id : (commitment ? commitment.budgetAssignments[i].id : ''))
                setIsBudgetModalOpen(true)
              }}
              sortHeader={[]}
              noItemsMessage={t('projectContract.noBugdetElementSelected')}
              gridCols="grid-cols-2"
              color=""
              textColor="text-emerald-500"
              contextMenu
              subTotals={budgetItems.length ? [null, <FormattedCurrency amount={contract ? contract.budgetNet : commitment?.budgetNet ?? 0} options={{ maxDigits: 2, minDigits: 2 }} />] : undefined}
              subTotalLabel=""
              subTotalSumLabel={t('projectContract.sum')}
              subTotalHeaders={budgetItems.length ?[
                '',
                t('projectContract.budgetSum')
              ]: []}
            />
          )}
          {(contract?.canBeBudgeted || commitment?.canBeBudgeted) && (
            <DecoratedCardAddButton menuItems={menuItems} />
          )}

          {/* {(contract?.canBeBudgeted || commitment?.canBeBudgeted) && !contract?.isPxContract && (
            <DecoratedCardAddButton onClick={() => setIsBudgetModalOpen(true)} />
          )} */}
        </DecoratedCard.Content>
      </DecoratedCard>
      <Modal isOpen={isBudgetModalOpen} onClose={() => setIsBudgetModalOpen(false)}>
        <BudgetModal
          commitment={commitment}
          contract={contract}
          setIsOpen={setIsBudgetModalOpen}
          selectedBudget={selectedBudget}
        />
      </Modal>
      <Modal isOpen={showBudgetLevelModal} onClose={() => setShowBudgetLevelModal(false)}>
          {sourceBudgetLevel &&
            <BudgetLevelModal contract={contract} commitment={commitment} hasCommitment={contract?.commitmentId !== (null || undefined)} sourceLevel={sourceBudgetLevel} onClose={() => setShowBudgetLevelModal(false)}/>
          }
      </Modal>
    </div>
  );
};
