import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useApiGetCalculationModelFinancingQuery } from '@client/shared/api';
import { ComboSelect, ComboSelectOption, LoadingIndicator } from '@client/shared/toolkit';
import cn from 'classnames';
import { ReactNode, useMemo } from 'react';

interface FinancialElementsSelectProps {
  label: string;
  calculationModelId: string;
  selectedFinancialElements: string[];
  setSelectedFinancialElements: (items: string[]) => void;
  multiple?: boolean;
  className?: string;
  icon?: ReactNode;
  disabled?: boolean;
}

export const FinancialElementsSelect = (props: FinancialElementsSelectProps) => {
  const {
    label,
    calculationModelId,
    selectedFinancialElements,
    setSelectedFinancialElements,
    multiple = true,
    className,
    icon,
    disabled = false,
  } = props;

  const loadedProjectId = useLoadedProjectId();

  const { data: calculationModelFinancingData, isFetching: isLoadingCalculationModelFinancingData } = useApiGetCalculationModelFinancingQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: calculationModelId ?? '',
    },
    { skip: !loadedProjectId || !calculationModelId },
  );

  const options: ComboSelectOption[] = useMemo(() => {
    if (!calculationModelFinancingData?.payload.financingGroups) return [];

    return calculationModelFinancingData.payload.financingGroups.map((group) => ({
      label: `${group.code} - ${group.title}`,
      options: group.financingElements.map((financingElement) => ({
        label: financingElement.code ? `${financingElement.code} - ${financingElement.description || financingElement.title}` : financingElement.description || financingElement.title || '',
        value: financingElement.id ?? ''
      })),
      value: group.groupId ?? ''
    }));
  }, [calculationModelFinancingData]);

  return (
    <div className={cn('relative', className)}>
      {isLoadingCalculationModelFinancingData ? (
        <LoadingIndicator mode="overlay" />
      ) : (
        <ComboSelect
          label={label}
          icon={icon}
          multiple={multiple}
          value={selectedFinancialElements}
          options={options}
          onChangeMultiple={(options) => {
            const ids = options?.flatMap(option => option.options ? option.options.map(opt => opt.value) : [option.value]) || [];
            setSelectedFinancialElements(ids);
          }}
          disabled={disabled}
        />
      )}
    </div>
  );
};
