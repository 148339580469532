import { DatevProbisMappingReadModel, YardiProbisMappingReadModel } from '@client/shared/api';
import {
  ContextMenu,
  ContextMenuItem,
  EditListIcon,
  FilledLockIcon,
  PencilIcon,
  SlideOverSortableList,
  SortingArrowsIcon,
  TrashIcon,
} from '@client/shared/toolkit';
import { SortHeaderType } from '@client/shared/utilities';
import { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const API_MAPPING = {
  DATEV: 'Datev',
  YARDI: 'Yardi',
  DOCUWARE: 'DocuWare',
  DATEV_PROPERTY_NAME: 'datevPropertyName',
  YARDI_PROPERTY_NAME: 'yardiPropertyName',
  PROBIS_PROPERTY_NAME: 'probisPropertyName',
  USER_DEFINED_FIELD_NAME: 'userDefinedFieldName',
  DEFAULT_VALUE: 'defaultValue',
  AI_EVAL_DOCUMENT: 'Probis Invoice AI'
} as const;

interface ApiClientMappingListProps extends PropsWithChildren {
  mappingType: 'Datev' | 'Yardi';
  mappings: DatevProbisMappingReadModel[] | YardiProbisMappingReadModel[];
  title: string;
  handleEdit?: (item: DatevProbisMappingReadModel | YardiProbisMappingReadModel) => void;
  handleDelete?: (item: DatevProbisMappingReadModel | YardiProbisMappingReadModel) => void;
  sortHeader?: SortHeaderType[];
  onHandleSort?: (index: number) => void;
}

export const ApiClientMappingList = ({
  mappingType,
  mappings,
  title,
  handleEdit,
  handleDelete,
  children,
  sortHeader,
  onHandleSort,
}: ApiClientMappingListProps) => {
  const { t } = useTranslation();

  const isDatev = mappingType === API_MAPPING.DATEV;

  const listItems = useMemo(() => {
    return mappings.map((item, i) => {
      const contextMenuItems: ContextMenuItem[] =
        isDatev
          ? [
              {
                label: t('common.edit'),
                subtitle: t('app.settingsApiDatevConnectionEdit'),
                icon: <PencilIcon />,
                onClick: () => {
                  handleEdit && handleEdit(item);
                },
                isDisabled: !handleEdit,
              },
              {
                label: t('common.delete'),
                subtitle: t('app.settingsApiDatevConnectionDelete'),
                icon: <TrashIcon />,
                onClick: () => {
                  handleDelete && handleDelete(item);
                },
                stopPropagation: true,
                isDisabled: item.isMandatoryElement || !handleDelete,
              },
            ]
          : [];

      return {
        id: item.id,
        name: (
          <div className="flex items-center gap-2 h-full">
            {item.name}
            {item.isMandatoryElement && (
              <span className="h-full">
                <FilledLockIcon className="h-2.5" />
              </span>
            )}
          </div>
        ),
        description: item.description,
        icon: <SortingArrowsIcon className="rotate-90 h-6" />,
        cols: [
          {
            value:
              API_MAPPING.DATEV_PROPERTY_NAME in item
                ? item.datevPropertyName
                : API_MAPPING.YARDI_PROPERTY_NAME in item
                  ? item.yardiPropertyName
                  : '',
            header: t(
              isDatev ? 'app.settingsApiDatevConnectionDatevId' : 'app.settingsApiYardiProperty',
            ),
          },
          {
            value: item.probisPropertyName ?? '-',
            header: t('app.settingsApiDatevConnectionProbisObjectId'),
          },
          {
            value: item.userDefinedFieldName ?? '-',
            header: t('app.settingsApiDatevConnectionCustomField'),
          },
          {
            value: item.defaultValue ?? '-',
            header: t('app.settingsApiDatevConnectionDefaultValue'),
          },
        ],
        contextMenu: isDatev ? <ContextMenu items={contextMenuItems} stopPropagation /> : undefined,
        className: 'bg-white',
        disabled: !isDatev,
      };
    });
  }, [mappings, isDatev, t, handleEdit, handleDelete]);

  return (
    <div>
      <SlideOverSortableList
        data={listItems}
        handleSelect={isDatev ? (i: number) => handleEdit && handleEdit(mappings[i]) : undefined}
        sortHeader={sortHeader ?? []}
        onHandleSort={onHandleSort}
        headline={
          sortHeader ? (
            <span className="text-[13px] text-gray-400">{t('app.settingsApiDatevConnectionObjectsyncronisation')}</span>
          ) : undefined
        }
        bgColor=" "
        noItemsMessage={t('app.settingsApiDatevNoConnections')}
        gridCols="grid-cols-4 gap-8"
        contextMenu={isDatev}
        additionalTopChildren={
          <div className="w-full h-[60px] bg-slate-200 flex justify-between items-center text-slate-500">
            <div className="flex items-center justify-center">
              <div className="w-20 flex items-center justify-center">
                <EditListIcon className="h-6" />
              </div>
              <span className="text-lg font-bold mr-2">{title}</span>
              <SortingArrowsIcon className="rotate-90 h-6" />
            </div>
          </div>
        }
      />

      {children}
    </div>
  );
};
