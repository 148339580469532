import cn from 'classnames';
import { LoadingIndicator, MonthPicker, NumberInput, PercentageIcon } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  ProjectCalculationModelCostsSelect,
  ProjectCalculationModelEarningsSelect,
  ProjectUdfListSelect,
} from '../../FeatureElement';
import { formatDateOnly } from '@client/shared/utilities';

export interface InflationCalcEditProps {
  inflationRate: number;
  setInflationRate: (value: number) => void;
  selectedElementIds: string[];
  setSelectedElementIds: (value: string[]) => void;
  selectedUDF: string;
  setSelectedUDF: (value: string) => void;
  selectedUDFLabels: string[];
  setSelectedUDFLabels: (value: string[]) => void;
  startDate: string;
  setStartDate: (date: string) => void;
  endDate: string;
  setEndDate: (date: string) => void;
  id?: string;
  className?: string;
  type: 'costs' | 'earnings';
}

export const InflationCalcEdit = (props: InflationCalcEditProps) => {
  const {
    id,
    inflationRate,
    setInflationRate,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedElementIds,
    setSelectedElementIds,
    selectedUDF,
    setSelectedUDF,
    selectedUDFLabels,
    setSelectedUDFLabels,
    className,
    type,
  } = props;

  const { t } = useTranslation();
  const [isFetchingUDFs, setIsFetchingUDFs] = useState(false);
  const [isFetchingElements, setIsFetchingElements] = useState(false);

  return (
    <div className={cn('flex flex-wrap divide-y', className)}>
      {(isFetchingElements || isFetchingUDFs) && <LoadingIndicator text={t('common.loading')} mode="overlay" />}
      <div
        className={cn('w-full flex', {
          'flex-col divide-y': id,
          'flex-row divide-x': !id,
        })}
      >
        <NumberInput
          label={t('projectCalculate.inflationCalcModal.inflationRate')}
          value={inflationRate}
          onChange={(value) => {
            setInflationRate(value ?? 0);
          }}
          icon={<PercentageIcon />}
          className={id ? 'w-full' : 'w-1/3'}
        />
        <MonthPicker
          label={t('projectCalculate.inflationCalcModal.startDate')}
          value={startDate ? new Date(startDate) : new Date()}
          onChange={(date) => {
            setStartDate(date ? formatDateOnly(date) : '');
          }}
          className={id ? 'w-full' : 'w-1/3'}
        />
        <MonthPicker
          label={t('projectCalculate.inflationCalcModal.endDate')}
          value={endDate ? new Date(endDate) : new Date()}
          onChange={(date) => {
            setEndDate(date ? formatDateOnly(date) : '');
          }}
          className={id ? 'w-full' : 'w-1/3'}
          disabled
        />
      </div>
      <div className={cn('w-full flex', { 'divide-x': !id, 'flex-wrap divide-y': id })}>
        {type === 'costs' ? (
          <ProjectCalculationModelCostsSelect
            selectedElementIds={selectedElementIds}
            setSelectedElementIds={setSelectedElementIds}
            setIsFetching={setIsFetchingElements}
            className={id ? 'w-full' : 'w-1/3'}
            label={t('projectCalculate.costGroups')}
            showControls
            useStore={false}
          />
        ) : (
          <ProjectCalculationModelEarningsSelect
            selectedElementIds={selectedElementIds}
            setSelectedElementIds={setSelectedElementIds}
            setIsFetching={setIsFetchingElements}
            className={id ? 'w-full' : 'w-1/3'}
            showControls
            useStore={false}
          />
        )}
        <ProjectUdfListSelect
          selectedUDF={selectedUDF}
          setSelectedUDF={setSelectedUDF}
          setIsFetchingUDFs={setIsFetchingUDFs}
          selectedUDFLabels={selectedUDFLabels}
          setSelectedUDFLabels={setSelectedUDFLabels}
          widthClass={id ? 'w-full' : 'w-1/3'}
        />
      </div>
    </div>
  );
};
