import {
  ReportDashboardReadModel,
  UpsertReportDashboardPayload,
  useApiPostCreateReportDashboardMutation,
  useApiPostUpdateReportDashboardMutation,
} from '@client/shared/api';
import {
  JLLCustomReportDashboard,
  mapDashboardWidgetsToGridLayout,
  prepareWidgetsToSave,
  Widget,
  WidgetDashboard
} from '.';
import { useLoadedProjectId } from '@client/project/store';
import { LoadingIndicator } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import { useMemo } from 'react';

export const WidgetReportDashboard = ({ reportDashboardData, isEditable = true }: { reportDashboardData: ReportDashboardReadModel | JLLCustomReportDashboard, isEditable?: boolean }) => {
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();

  const [createReportDashboard, { isLoading: isCreatingReportDashboard }] = useApiPostCreateReportDashboardMutation();
  const [updateReportDashboard, { isLoading: isUpdatingReportDashboard }] = useApiPostUpdateReportDashboardMutation();

  /**
   * Maps the data from the backend to our layout data
   */
  const reportDashboardLayout: Widget[] = useMemo(() => {
    return 'layout' in reportDashboardData ? reportDashboardData['layout'] : mapDashboardWidgetsToGridLayout('report', reportDashboardData as ReportDashboardReadModel);
  }, [reportDashboardData]);

  const handleSave = async (widgets: Widget[]) => {
    if (loadedProjectId) {
      if (!reportDashboardData?.id) {
        try {
          await safeMutation(
            createReportDashboard,
            {
              projectId: loadedProjectId,
              body: {
                name: t('reporting.customReportTitle'),
                budgetStatusWidgets: [],
                cashOutWidgets: [],
                financialUsageWidgets: [],
                kpisWidgets: [],
                projectInformationWidgets: [],
                riskMitigationListWidgets: [],
                tasksWidgets: [],
                textboxWidgets: [],
                trafficLightsWidgets: [],
                projectClockWidgets: [],
                waterfallWidgets: []
              },
            },
            isCreatingReportDashboard,
          );
        } catch (e) {
          console.error(e);
        }
      } else {
        await safeMutation(
          updateReportDashboard,
          {
            projectId: loadedProjectId,
            dashboardId: reportDashboardData.id,
            body: {
              name: reportDashboardData.name || t('reporting.customReportTitle'),
              ...prepareWidgetsToSave(widgets, undefined, 'report'),
            } as UpsertReportDashboardPayload,
          },
          isUpdatingReportDashboard,
        );
      }
    }
  };

  return (
    <>
      {isCreatingReportDashboard || isUpdatingReportDashboard ? (
        <LoadingIndicator text={t('common.loading')} mode="overlay-window" />
      ) : (
        <WidgetDashboard
          type="report"
          layout={reportDashboardLayout}
          onSave={isEditable ? handleSave : undefined}
          dashboardId={reportDashboardData?.id}
          dashboardName={reportDashboardData?.name}
          isEditable={isEditable}
        />
      )}
    </>
  );
};
