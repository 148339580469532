import {
  DecoratedCard,
  Collapsible,
  useComponentDimensions,
  LoadingIndicator,
  PetitionIcon,
  ReceiptAndChangeIcon,
  LevelToggle,
  ToggleSlider,
  HierarchyIndicator,
} from '@client/shared/toolkit';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  useApiGetDetailReportQuery,
  DetailCatalogElement,
  DetailCommitment,
  DetailContract,
  DetailContractTitle,
  DetailInvoicingParty,
} from '@client/shared/api';
import {
  useLoadedProjectVariants,
  useLoadedProjectId,
  setExpandedDetailReportIds,
  useExpandedReportingIds,
} from '@client/project/store';
import { CalculationModelSelect } from './CalculationModelSelect';
import { ToggleButton } from '@client/shared/toolkit';
import { FormattedCurrency } from '@client/project/shared';
import { formatPercentage, isEmpty } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getLevelledElements } from '../utils';
import { UserDefinedFieldSelectSelect } from './UserDefinedFieldSelect';
import { t } from 'i18next';
import { ReportingContext } from './ReportingContextProvider';

export const ReportDetails = () => {
  const { data: projectVariants } = useLoadedProjectVariants();
  const { t } = useTranslation();
  const activeVariant = projectVariants.find((x) => x.type === 'Version');
  const activeVariantId = activeVariant?.id;

  const projectId = useLoadedProjectId();
  const [sourceCalculationModelId, setSourceCalculationModelId] = useState<string | undefined>(activeVariantId);

  const [selectedUserDefinedFieldDefinition, setSelectedUserDefinedFieldDefinition] = useState<string | null>(null);
  const [showNet, setShowNet] = useState(true);

  const { setCalculationModelId, setDatasourceParameters, setExportVariables, setExportCustomComponents } =
    useContext(ReportingContext);

  useEffect(() => {
    setExportVariables([
      {
        name: 'NetGrossType',
        value: showNet ? 'Net' : 'Gross',
      },
    ]);
  }, [showNet, setExportVariables]);

  useEffect(() => {
    setExportCustomComponents([
      {
        name: 'CatalogElements',
        exportType: 'xlsx',
        key: 'headerPrintOnAllPages',
        value: false,
      },
    ]);
  }, [setExportCustomComponents]);

  useEffect(() => {
    if (sourceCalculationModelId) {
      setCalculationModelId(sourceCalculationModelId);
    }
  }, [sourceCalculationModelId, setCalculationModelId]);

  useEffect(() => {
    if (selectedUserDefinedFieldDefinition) {
      setDatasourceParameters(`userDefinedFieldDefinitionId=${selectedUserDefinedFieldDefinition}`);
    } else {
      setDatasourceParameters(null);
    }
  }, [selectedUserDefinedFieldDefinition, setDatasourceParameters]);

  const { data, isFetching } = useApiGetDetailReportQuery(
    {
      projectId: projectId ?? '',
      calculationModelId: sourceCalculationModelId ?? '',
      userDefinedFieldDefinitionId: selectedUserDefinedFieldDefinition ?? undefined,
    },
    { skip: isEmpty(sourceCalculationModelId) },
  );

  const wrapper = useRef<HTMLDivElement>(null);
  const dimensions = useComponentDimensions(wrapper);

  const [open, setOpen] = useState(true);

  const levelledElements = useMemo(() => {
    const allElements = [...(data?.data.catalogElements ?? [])];

    return getLevelledElements(allElements, ['childElements', 'commitments', 'contracts', 'titles', 'invoices'], 'uid');
  }, [data?.data.catalogElements]);

  const dispatch = useDispatch();

  const { Detail: expandedElements } = useExpandedReportingIds();

  const handleOnCollapse = (level: number) => {
    if (!open) setOpen(true);
    const elements = level !== 3 ? levelledElements.filter((x) => x.level <= level - 2) : levelledElements;
    const expandedElementIds = elements.map((x) => x.elementId);
    dispatch(setExpandedDetailReportIds(expandedElementIds));
  };
  const { setExportFilterConfig } = useContext(ReportingContext);

  useEffect(() => {
    const allElements = [...(data?.data.catalogElements ?? [])];
    const levelElements = getLevelledElements(
      allElements,
      ['childElements', 'commitments', 'contracts', 'titles', 'invoices'],
      'uid',
    );
    const shownElements = levelElements.filter(
      (x) => x.level === 0 || (x.parentId && expandedElements.includes(x.parentId)),
    );
    setExportFilterConfig(
      [
        {
          componentName: 'CatalogElements_ChildElements',
          filterMode: 'And',
          filters: [
            {
              column: '',
              dataType: 'Expression',
              expression: shownElements
                .filter((x) => x.level === 1 && x.type === 'childElements')
                .map((item) => {
                  return `(data_catalogElements_childElements.id == "${item.element.id}" && data_catalogElements_childElements.parentId == "${item.element.parentId}")`;
                })
                .join(' || '),
              item: 3,
            },
          ],
        },
        {
          componentName: 'CatalogElements_ChildElements_ChildElements',
          filterMode: 'And',
          filters: [
            {
              column: '',
              dataType: 'Expression',
              expression: shownElements
                .filter((x) => x.level > 1 && x.type === 'childElements')
                .map((item) => {
                  return `(data_catalogElements_childElements_childElements.id == "${item.element.id}" && data_catalogElements_childElements_childElements.parentId == "${item.element.parentId}")`;
                })
                .join(' || '),
              item: 3,
            },
          ],
        },
        {
          componentName: 'CatalogElements_Commitments',
          filterMode: 'And',
          filters: [
            {
              column: '',
              dataType: 'Expression',
              expression: shownElements
                .filter((x) => x.level === 1 && x.type === 'commitments')
                .map((item) => {
                  return `(data_catalogElements_commitments.id == "${
                    item.element.id
                  }" && data_catalogElements_commitments.catalogId == "${
                    'catalogId' in item.element && item.element.catalogId
                  }")`;
                })
                .join(' || '),
              item: 3,
            },
          ],
        },
        {
          componentName: 'CatalogElements_Contracts',
          filterMode: 'And',
          filters: [
            {
              column: '',
              dataType: 'Expression',
              expression: shownElements
                .filter((x) => x.level === 1 && x.type === 'contracts')
                .map((item) => {
                  return `(data_catalogElements_contracts.id == "${
                    item.element.id
                  }" && data_catalogElements_contracts.catalogId == "${
                    'catalogId' in item.element && item.element.catalogId
                  }")`;
                })
                .join(' || '),
              item: 3,
            },
          ],
        },
        {
          componentName: 'CatalogElements_Contracts_Titles',
          filterMode: 'And',
          filters: [
            {
              column: '',
              dataType: 'Expression',
              expression: shownElements
                .filter((x) => x.level === 2 && x.type === 'titles')
                .map((item) => {
                  return `(data_catalogElements_contracts_titles.id == "${
                    item.element.id
                  }" && data_catalogElements_contracts_titles.catalogId == "${
                    'catalogId' in item.element && item.element.catalogId
                  }")`;
                })
                .join(' || '),
              item: 3,
            },
          ],
        },
        {
          componentName: 'CatalogElements_Invoices',
          filterMode: 'And',
          filters: [
            {
              column: '',
              dataType: 'Expression',
              expression: shownElements
                .filter((x) => x.level === 1 && x.type === 'invoices')
                .map((item) => {
                  return `(data_catalogElements_invoices.id == "${
                    item.element.id
                  }" && data_catalogElements_invoices.catalogId == "${
                    'catalogId' in item.element && item.element.catalogId
                  }")`;
                })
                .join(' || '),
              item: 3,
            },
          ],
        },
        {
          componentName: 'CatalogElements_ChildElements_Commitments',
          filterMode: 'And',
          filters: [
            {
              column: '',
              dataType: 'Expression',
              expression: shownElements
                .filter((x) => x.level === 2 && x.type === 'commitments')
                .map((item) => {
                  return `(data_catalogElements_childElements_commitments.id == "${
                    item.element.id
                  }" && data_catalogElements_childElements_commitments.catalogId == "${
                    'catalogId' in item.element && item.element.catalogId
                  })`;
                })
                .join(' || '),
              item: 3,
            },
          ],
        },
        {
          componentName: 'CatalogElements_ChildElements_Contracts',
          filterMode: 'And',
          filters: [
            {
              column: '',
              dataType: 'Expression',
              expression: shownElements
                .filter((x) => x.level === 2 && x.type === 'contracts')
                .map((item) => {
                  return `(data_catalogElements_childElements_contracts.id == "${
                    item.element.id
                  }" && data_catalogElements_childElements_contracts.catalogId == "${
                    'catalogId' in item.element && item.element.catalogId
                  }")`;
                })
                .join(' || '),
              item: 3,
            },
          ],
        },
        {
          componentName: 'CatalogElements_ChildElements_Contracts_Titles',
          filterMode: 'And',
          filters: [
            {
              column: '',
              dataType: 'Expression',
              expression: shownElements
                .filter((x) => x.level === 3 && x.type === 'titles')
                .map((item) => {
                  return `(data_catalogElements_childElements_contracts_titles.id == "${
                    item.element.id
                  }" && data_catalogElements_childElements_contracts_titles.catalogId == "${
                    'catalogId' in item.element && item.element.catalogId
                  }")`;
                })
                .join(' || '),
              item: 3,
            },
          ],
        },
        {
          componentName: 'CatalogElements_ChildElements_Invoices',
          filterMode: 'And',
          filters: [
            {
              column: '',
              dataType: 'Expression',
              expression: shownElements
                .filter((x) => x.level === 2 && x.type === 'invoices')
                .map((item) => {
                  return `(data_catalogElements_childElements_invoices.id == "${
                    item.element.id
                  }" && data_catalogElements_childElements_invoices.catalogId == "${
                    'catalogId' in item.element && item.element.catalogId
                  }")`;
                })
                .join(' || '),
              item: 3,
            },
          ],
        },
        {
          componentName: 'CatalogElements_ChildElements_ChildElements_Commitments',
          filterMode: 'And',
          filters: [
            {
              column: '',
              dataType: 'Expression',
              expression: shownElements
                .filter((x) => x.level === 3 && x.type === 'commitments')
                .map((item) => {
                  return `(data_catalogElements_childElements_childElements_commitments.id == "${
                    item.element.id
                  }" && data_catalogElements_childElements_childElements_commitments.catalogId == "${
                    'catalogId' in item.element && item.element.catalogId
                  }")`;
                })
                .join(' || '),
              item: 3,
            },
          ],
        },
        {
          componentName: 'CatalogElements_ChildElements_ChildElements_Contracts',
          filterMode: 'And',
          filters: [
            {
              column: '',
              dataType: 'Expression',
              expression: shownElements
                .filter((x) => x.level === 3 && x.type === 'contracts')
                .map((item) => {
                  return `(data_catalogElements_childElements_childElements_contracts.id == "${
                    item.element.id
                  }" && data_catalogElements_childElements_childElements_contracts.catalogId == "${
                    'catalogId' in item.element && item.element.catalogId
                  }")`;
                })
                .join(' || '),
              item: 3,
            },
          ],
        },
        {
          componentName: 'CatalogElements_ChildElements_ChildElements_Contracts_Titles',
          filterMode: 'And',
          filters: [
            {
              column: '',
              dataType: 'Expression',
              expression: shownElements
                .filter((x) => x.level === 4 && x.type === 'titles')
                .map((item) => {
                  return `(data_catalogElements_childElements_contracts_titles.id == "${
                    item.element.id
                  }" && data_catalogElements_childElements_childElements_contracts_titles.catalogId == "${
                    'catalogId' in item.element && item.element.catalogId
                  }")`;
                })
                .join(' || '),
              item: 3,
            },
          ],
        },
      ],
    );
  }, [setExportFilterConfig, data?.data.catalogElements, expandedElements, selectedUserDefinedFieldDefinition]);

  const columns = useMemo(() => {
    return [
      {
        value: 'initialBudget',
        label: t('reporting.tableInitialBudget'),
        border: 'major',
        total: showNet ? data?.data.total.availableBudgetNet : data?.data.total.availableBudgetGross,
      },
      {
        value: 'contracts',
        label: t('reporting.tableContracts'),
        border: 'minor',
        total: showNet
          ? data?.data.total.values?.mainContractValueNet
          : data?.data.total.values?.mainContractValueGross,
      },
      {
        value: 'additions',
        label: t('reporting.tableAdditions'),
        border: 'minor',
        total: showNet ? data?.data.total.values?.supplementValueNet : data?.data.total.values?.supplementValueGross,
      },
      {
        value: 'totalOrder',
        label: t('reporting.tableTotalOrder'),
        border: 'major',
        percentage: showNet
          ? data?.data.total.values?.contractDifferencePercentageNet
          : data?.data.total.values?.contractDifferencePercentageGross,
        total: showNet ? data?.data.total.values?.contractValueNet : data?.data.total.values?.contractValueGross,
      },
      {
        value: 'invoice',
        label: t('reporting.tableInvoice'),
        border: 'minor',
        total: showNet ? data?.data.total.values?.invoiceValueNet : data?.data.total.values?.invoiceValueGross,
      },
      {
        value: 'payment',
        label: t('reporting.tablePayment'),
        border: 'major',
        total: showNet ? data?.data.total.values?.paymentValueNet : data?.data.total.values?.paymentValueGross,
      },
      {
        value: 'forecast',
        label: t('reporting.tableForecast'),
        border: 'none',
        percentage: showNet
          ? data?.data.total.values?.forecastDifferencePercentageNet
          : data?.data.total.values?.forecastDifferencePercentageGross,
        total: showNet ? data?.data.total.values?.forecastValueNet : data?.data.total.values?.forecastValueGross,
      },
      {
        value: 'difference',
        label: t('reporting.tableColumnDifference'),
        border: 'none',
        total: showNet
          ? data?.data.total.values?.forecastDifferenceNet ?? 0
          : data?.data.total.values?.forecastDifferenceGross ?? 0,
      },
      {
        value: 'differencePercentage',
        label: t('reporting.tableColumnDifferenceInPercentage'),
        border: 'none',
        total: showNet
          ? data?.data.total.values?.forecastDifferencePercentageNet ?? 0
          : data?.data.total.values?.forecastDifferencePercentageGross ?? 0,
      },
    ];
  }, [t, showNet, data]);

  const maxLevel = useMemo(() => {
    return levelledElements.reduce((value, element) => {
      return Math.max(value, element.level);
    }, 3) + 1;
  }, [levelledElements]);

  return (
    <DecoratedCard className="min-w-[1690px]">
      <DecoratedCard.Header showActionButton={false}>
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex">
            <div className="truncate">{t('reporting.reportDetails.fullTitle')}</div>
            <div className="pdf-export-hidden">
              <LevelToggle handleOnCollapse={handleOnCollapse} showLevels={Array.from({length: maxLevel}, (_, i) => i + 1)} />
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex items-center mr-6">
              <div className="font-bold text-[15px] pr-6">{t('reporting.groupBy')}</div>
              <div className="flex space-x-0.5 items-center">
                <UserDefinedFieldSelectSelect
                  className="w-52"
                  projectId={projectId}
                  selectedUserDefinedFieldId={selectedUserDefinedFieldDefinition}
                  onChange={(value) => setSelectedUserDefinedFieldDefinition(value)}
                />
              </div>
            </div>
            <div className="font-bold text-[15px] pr-6">{t('reporting.dataSource')}</div>
            <div className="flex space-x-3 items-center">
              <CalculationModelSelect
                selectedCalculationModelId={sourceCalculationModelId}
                className="w-52"
                onChange={(id) => setSourceCalculationModelId(id)}
              />
              <ToggleSlider
                headerLabel={t('reporting.netGrossSwitchLabel')}
                left={showNet}
                onClick={() => setShowNet((prev) => !prev)}
                labelLeft={t('projectControl.net')}
                labelRight={t('projectControl.gross')}
              />
            </div>
          </div>
        </div>
      </DecoratedCard.Header>
      <DecoratedCard.Content className="w-full h-full flex relative">
        {isFetching && <LoadingIndicator text={t('reporting.loadingReport')} mode="overlay" />}

        <div className="relative w-full" ref={wrapper}>
          <div
            className="w-2 my-4 rounded -ml-1 left-0 absolute bg-red-700"
            style={{ height: (dimensions.height ?? 15) - 32 }}
          />
          <div className="w-4/12 ml-4 mt-5 text-[22px] font-bold flex truncate">
            {data?.data.catalogElements && data?.data.catalogElements.length > 0 ? (
              <ToggleButton open={open} onClick={() => setOpen(!open)} />
            ) : (
              <div className="w-6 flex-none">&nbsp;</div>
            )}
            <div className="text-red-700">{t('reporting.reportDetails.groupCosts')}</div>
          </div>
          <div className="h-8 bg-white border-b border-b-slate-300 pb-1">
            <HeaderFooter columns={columns} />
          </div>
          <Collapsible open={open}>
            {data?.data.catalogElements.map((element, i) => (
              <DetailCostRow
                rowData={element}
                key={`report-cost-${element.code}-${i}`}
                expandedElements={expandedElements}
                showNet={showNet}
              />
            ))}
          </Collapsible>
          <div className="h-20 bg-white">
            <HeaderFooter columns={columns} isFooter />
          </div>
        </div>
      </DecoratedCard.Content>
    </DecoratedCard>
  );
};

interface Column {
  value: string;
  label: string;
  border: string;
  percentage?: number;
  total?: number;
}

interface HeaderFooterProps {
  columns: Column[];
  isFooter?: boolean;
}

const HeaderFooter = ({ columns, isFooter = false }: HeaderFooterProps) => {
  // const initialBudget = columns.find((x) => x.value === 'initialBudget')?.total ?? 0;
  const { t } = useTranslation();
  return (
    <div className="flex pr-4 pl-12 w-full h-full">
      <div className="flex items-end flex-none">
        <div
          className={classNames('h-full flex text-[11px] text-slate-500 gap-2 w-[422px]', {
            'items-start': isFooter,
            'items-end': !isFooter,
          })}
        >
          {!isFooter && (<div className="truncate">{t('reporting.tableColumnId')}</div>)}
          {isFooter ? (
            <div className="flex items-end h-11 text-[18px] font-bold text-red-700 truncate">
              {t('reporting.reportDetails.groupTotalInvestmentCosts')}
            </div>
          ) : (
            <div className="truncate">{t('reporting.tableColumnDescription')}</div>
          )}
        </div>
      </div>
      <div
        className={classNames('flex-grow h-full', {
          'pb-6': isFooter,
        })}
      >
        <div className="flex items-start justify-end pb">
          {columns?.map((column, index) => (
            <div
              className={classNames(
                'flex-grow basis-0 text-right font-bold text-[15px] px-4 truncate',
                {
                  'h-11 flex items-end justify-end': isFooter,
                  'h-full block': !isFooter,
                  'border-r-4 border-slate-300': column.border === 'major',
                  'border-r border-slate-300': column.border === 'minor',
                  'border-r-0': column.border === 'none',
                },
              )}
              key={`report-detail-column-${isFooter ? 'footer' : 'header'}-${index}`}
              title={!isFooter ? column.label : undefined}
            >
              {isFooter ? (
                <div className="flex items-end">
                  {/* Diff in % as percentage and colored */}
                  {(index === 8 || index === 7) ? (
                    <DifferenceElement value={column.total} asPercentage={index === 8} />
                  ) : (
                    <FormattedCurrency amount={column.total} />
                  )}
                </div>
              ) : (
                column.label
              )}
            </div>
          ))}
        </div>
        {isFooter && (
          <div className="flex items-start justify-end">
            {columns?.map((column) => (
              <div
                className="flex-grow basis-0 h-full border-b-4 border-double pb-2 ml-2"
                key={`report-detail-column-underline-${column.value}`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const DifferenceElement = ({ value = 0, percentage, asPercentage = false }: { value?: number; percentage?: number; asPercentage?: boolean; }) => {
  return (
    <div
      className={classNames('flex justify-end items-end', {
        'text-red-700': value > 0,
        'text-green-700': value <= 0,
      })}
    >
      {!!percentage && (
        <span className="mr-2 mb-0.5 text-xxs">
          {percentage < 0 ? '' : '+'}
          {formatPercentage(percentage, {
            maxDigits: percentage > -0.99 ? 0 : 2,
          })}
        </span>
      )}
      {asPercentage ? (
        formatPercentage(value, {
          maxDigits: value > -0.99 ? 0 : 2,
        })
      ) : (
        <FormattedCurrency amount={value} />
      )}
    </div>
  );
};

interface DetailCostRowProps {
  rowData?: DetailCatalogElement;
  showNet: boolean;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
}

const DetailCostRow = ({ columns, showNet, level = 0, rowData, expandedElements }: DetailCostRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>&nbsp;</>,
      <div className="flex gap-2">
        <span>{rowData.code}</span>
        <span>{rowData.name}</span>
      </div>,
      <div className="flex flex-col">
        <FormattedCurrency amount={showNet ? rowData.availableBudgetNet : rowData.availableBudgetGross} />
      </div>,
      <FormattedCurrency amount={showNet ? rowData?.mainContractValueNet : rowData?.mainContractValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.supplementValueNet : rowData?.supplementValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.contractValueNet : rowData?.contractValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.invoiceValueNet : rowData?.invoiceValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.paymentValueNet : rowData?.paymentValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.forecastValueNet : rowData?.forecastValueGross} />,
      <DifferenceElement value={showNet ? rowData?.forecastDifferenceNet : rowData?.forecastDifferenceGross} />,
      <DifferenceElement value={showNet ? rowData?.forecastDifferencePercentageNet : rowData?.forecastDifferencePercentageGross} asPercentage />,
    ];
  }

  return (
    <ReportDetailsCollapsingRow<DetailCatalogElement>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={DetailCostRow}
      type="catalog"
      expandedElements={expandedElements}
      showNet={showNet}
    />
  );
};

interface DetailCommitmentRowProps {
  rowData?: DetailCommitment;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
  showNet: boolean;
}

const DetailCommitmentRow = ({ columns, level = 0, rowData, expandedElements, showNet }: DetailCommitmentRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>&nbsp;</>,
      <div className="flex gap-2">
        <span>{rowData.code}</span>
        <span>{rowData.name}</span>
      </div>,
      <FormattedCurrency amount={showNet ? rowData?.budgetValueNet : rowData?.budgetValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.mainContractValueNet : rowData?.mainContractValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.supplementValueNet : rowData?.supplementValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.contractValueNet : rowData?.contractValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.invoiceValueNet : rowData?.invoiceValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.paymentValueNet : rowData?.paymentValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.forecastValueNet : rowData?.forecastValueGross} />,
      <DifferenceElement value={showNet ? rowData?.forecastDifferenceNet : rowData?.forecastDifferenceGross} />,
      <DifferenceElement value={showNet ? rowData?.forecastDifferencePercentageNet : rowData?.forecastDifferencePercentageGross} asPercentage />,
    ];
  }

  return (
    <ReportDetailsCollapsingRow<DetailCommitment>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={DetailCommitmentRow}
      type="commitment"
      expandedElements={expandedElements}
      showNet={showNet}
    />
  );
};

interface DetailContractRowProps {
  rowData?: DetailContract;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
  showNet: boolean;
}

const DetailContractRow = ({ columns, level = 0, rowData, expandedElements, showNet }: DetailContractRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>&nbsp;</>,
      <div className="flex items-center gap-2">
        <div className={classNames('mx-2 relative w-[0.5625rem] flex-none')}>
          <HierarchyIndicator />
          {rowData.titles.length > 0 && expandedElements.includes(rowData.uid) && (
            <div className="w-px h-8 absolute ml-1 bg-slate-400 z-0" />
          )}
        </div>
        <PetitionIcon className="w-5 h-5" />
        <span>{rowData.code}</span>
        <span>{rowData.name}</span>
      </div>,
      <FormattedCurrency amount={showNet ? rowData?.budgetValueNet : rowData?.budgetValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.mainContractValueNet : rowData?.mainContractValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.supplementValueNet : rowData?.supplementValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.contractValueNet : rowData?.contractValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.invoiceValueNet : rowData?.invoiceValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.paymentValueNet : rowData?.paymentValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.forecastValueNet : rowData?.forecastValueGross} />,
      <DifferenceElement value={showNet ? rowData?.forecastDifferenceNet : rowData?.forecastDifferenceGross} />,
      <DifferenceElement value={showNet ? rowData?.forecastDifferencePercentageNet : rowData?.forecastDifferencePercentageGross} asPercentage />,
    ];
  }

  return (
    <ReportDetailsCollapsingRow<DetailContract>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={DetailContractRow}
      type="contract"
      expandedElements={expandedElements}
      showNet={showNet}
    />
  );
};

interface DetailInvoicingPartyRowProps {
  rowData?: DetailInvoicingParty;
  level?: number;
  columns?: React.ReactElement[];
  expandedElements: string[];
  showNet: boolean;
}

const DetailInvoicingPartyRow = ({
  columns,
  level = 0,
  rowData,
  expandedElements,
  showNet,
}: DetailInvoicingPartyRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>&nbsp;</>,
      <div className="flex items-center gap-2">
        <PetitionIcon />
        <span>{isEmpty(rowData.code) ? t('common.none') : rowData.code}</span>
        <span>{isEmpty(rowData.name) ? '-' : rowData.name}</span>
      </div>,
      <FormattedCurrency amount={showNet ? rowData?.budgetValueNet : rowData?.budgetValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.mainContractValueNet : rowData?.mainContractValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.supplementValueNet : rowData?.supplementValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.contractValueNet : rowData?.contractValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.invoiceValueNet : rowData?.invoiceValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.paymentValueNet : rowData?.paymentValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.forecastValueNet : rowData?.forecastValueGross} />,
      <DifferenceElement value={showNet ? rowData?.forecastDifferenceNet : rowData?.forecastDifferenceGross} />,
      <DifferenceElement value={showNet ? rowData?.forecastDifferencePercentageNet : rowData?.forecastDifferencePercentageGross} asPercentage />,
    ];
  }

  return (
    <ReportDetailsCollapsingRow<DetailInvoicingParty>
      rowData={rowData}
      columns={columns}
      level={level}
      RowComponent={DetailInvoicingPartyRow}
      type="invoice"
      expandedElements={expandedElements}
      showNet={showNet}
    />
  );
};

interface DetailTitleRowProps {
  rowData?: DetailContractTitle;
  level?: number;
  columns?: React.ReactElement[];
  lastElement: boolean;
  expandedElements: string[];
  showNet: boolean;
}

const DetailTitleRow = ({
  columns,
  level = 0,
  rowData,
  lastElement,
  expandedElements,
  showNet,
}: DetailTitleRowProps) => {
  if (rowData && !columns) {
    columns = [
      <>&nbsp;</>,
      <div className="flex items-center gap-2">
        <div className="mx-2 relative w-[0.5625rem] flex-none">
          <div className="w-px h-8 absolute ml-1 -mt-9 bg-slate-400 z-0" />
          <HierarchyIndicator />
          {!lastElement && <div className="w-px h-8 absolute ml-1 bg-slate-400 z-0" />}
        </div>
        <ReceiptAndChangeIcon className="w-5 h-5 px-0.5" />
        <span>{rowData.code}</span>
        <span>{rowData.name}</span>
      </div>,
      <FormattedCurrency amount={showNet ? rowData?.budgetValueNet : rowData?.budgetValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.mainContractValueNet : rowData?.mainContractValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.supplementValueNet : rowData?.supplementValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.contractValueNet : rowData?.contractValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.invoiceValueNet : rowData?.invoiceValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.paymentValueNet : rowData?.paymentValueGross} />,
      <FormattedCurrency amount={showNet ? rowData?.forecastValueNet : rowData?.forecastValueGross} />,
      <DifferenceElement value={showNet ? rowData?.forecastDifferenceNet : rowData?.forecastDifferenceGross} />,
      <DifferenceElement value={showNet ? rowData?.forecastDifferencePercentageNet : rowData?.forecastDifferencePercentageGross} asPercentage />,
    ];
  }

  return (
    <ReportDetailsCollapsingRow<DetailContractTitle>
      rowData={rowData}
      columns={columns}
      level={level - 1}
      RowComponent={DetailTitleRow}
      type="title"
      expandedElements={expandedElements}
      showNet={showNet}
    />
  );
};

interface ReportDetailsCollapsingRowProps<T> {
  rowData?: T & {
    childElements?: DetailCatalogElement[] | null;
    commitments?: DetailCommitment[];
    contracts?: DetailContract[];
    titles?: DetailContractTitle[];
    invoices?: DetailInvoicingParty[];
    uid: string;
  };
  level?: number;
  columns?: React.ReactElement[];
  // FP: Using any here because different components are passed in as props
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  RowComponent: any;
  type: 'catalog' | 'commitment' | 'contract' | 'title' | 'invoice';
  expandedElements: string[];
  showNet: boolean;
}

const ReportDetailsCollapsingRow = <T extends object>({
  columns,
  level = 0,
  rowData,
  RowComponent,
  type,
  expandedElements,
  showNet,
}: ReportDetailsCollapsingRowProps<T>) => {
  const hasChildren =
    (rowData?.childElements && rowData.childElements.length > 0) ||
    (rowData?.commitments && rowData.commitments.length > 0) ||
    (rowData?.contracts && rowData.contracts.length > 0) ||
    (rowData?.titles && rowData.titles.length > 0) ||
    (rowData?.invoices && rowData.invoices.length > 0);

  const dispatch = useDispatch();

  return (
    <>
      <div
        className={classNames('flex items-center px-4 hover:bg-slate-50 transition-colors', {
          'bg-gray-50 font-bold border-b border-slate-300 text-[14px]': level === 0,
          'bg-white border-b border-slate-300 border-dotted text-[12px]': level > 0,
        })}
      >
        {hasChildren ? (
          <ToggleButton
            open={expandedElements.includes(rowData?.uid)}
            onClick={() =>
              dispatch(
                setExpandedDetailReportIds(
                  expandedElements.includes(rowData?.uid)
                    ? expandedElements.filter((x) => x !== rowData?.uid)
                    : [...expandedElements, rowData?.uid],
                ),
              )
            }
          />
        ) : (
          <div className="w-8">&nbsp;</div>
        )}
        <div className="flex flex-grow h-9 justify-center">
          {columns && (
            <div className="flex flex-none gap-2 w-[422px] overflow-hidden" style={{paddingLeft: level * 10 }}>
              <div className="flex flex-row items-center gap-2 truncate overflow-x-hidden">
                <span className="text-ellipsis">{columns[1]}</span>
              </div>
            </div>
          )}
          {columns?.map((column, index) => {
            if (index > 1) {
              return (
                <div
                  key={index}
                  className={classNames('h-full flex flex-col justify-center py-2 gap-2', {
                    'text-[12px]': index === 0,
                    'w-[422px] text-[12px] truncate': index === 11,
                    'flex-grow basis-0 min-w-[28px] text-right px-4': index > 1,
                    'text-slate-500': type === 'title',
                    'border-r-4 border-slate-300': index === 2 || index === 5 || index === 7,
                    'border-r border-slate-300': index === 3 || index === 4 || index === 6,
                    'border-r-0': index === 8,
                  })}
                >
                  {column}
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>

      {hasChildren && (
        <Collapsible open={expandedElements.includes(rowData?.uid ?? '')}>
          {rowData.childElements?.map((child, index) => (
            <RowComponent
              key={index}
              rowData={child}
              level={level + 1}
              expandedElements={expandedElements}
              showNet={showNet}
            />
          ))}
          {rowData.commitments?.map((commitment, index) => (
            <DetailCommitmentRow
              key={index}
              rowData={commitment}
              level={level + 1}
              expandedElements={expandedElements}
              showNet={showNet}
            />
          ))}
          {rowData.contracts?.map((contract, index) => (
            <DetailContractRow
              key={index}
              rowData={contract}
              level={level + 1}
              expandedElements={expandedElements}
              showNet={showNet}
            />
          ))}
          {rowData.titles?.map((title, index) => (
            <DetailTitleRow
              key={index}
              rowData={title}
              level={level + 1}
              lastElement={rowData.titles ? index === rowData.titles.length - 1 : false}
              expandedElements={expandedElements}
              showNet={showNet}
            />
          ))}
          {rowData.invoices?.map((invoice, index) => (
            <DetailInvoicingPartyRow
              key={index}
              rowData={invoice}
              level={level + 1}
              expandedElements={expandedElements}
              showNet={showNet}
            />
          ))}
        </Collapsible>
      )}
    </>
  );
};
