import { ContractReadModel } from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import {
  DecoratedCard,
  DecoratedCardAddButton,
  ListTitle,
  LoadingIndicator, SlideOver
} from '@client/shared/toolkit';
import React, { useState } from 'react';
import {
  ContractCreatePerformancePercentageSlideOver,
  PerformancePercentageReadModel
} from './ContractCreatePerformancePercentageSlideOver';

interface ContractCompletionLevelProps {
  contract?: ContractReadModel;
}
export const ContractCompletionLevel = (props: ContractCompletionLevelProps) => {
  const { t } = useTranslation();

  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [selectedItem] = useState<PerformancePercentageReadModel | null>(null);

  const isUpdating = false;

  return (
    <div className="pt-6">
      {isUpdating && (<LoadingIndicator text={t('projectContract.savingContractLoadingIndicator')} mode="overlay-window" />)}
      <DecoratedCard shadowVariant="normal">
        <DecoratedCard.Content>
          <ListTitle title={t('projectContract.completionLevel.title')} />
          <DecoratedCardAddButton onClick={() => setIsSlideOverOpen(true)} />
        </DecoratedCard.Content>
      </DecoratedCard>
      <SlideOver
        isOpen={isSlideOverOpen}
        onClose={() => setIsSlideOverOpen(false)}
      >
        <ContractCreatePerformancePercentageSlideOver performancePercentage={selectedItem} onClose={() => setIsSlideOverOpen(false)} />
      </SlideOver>
    </div>
  );
}
