import { useValidateProjectPermission } from '@client/shared/permissions';
import { getCompanyBranches, useLoadedProjectId } from '@client/project/store';
import {
  AiEvalGenericResultReadModel,
  ShortContractReadModel,
  useApiGetInvoiceRecipientSelectCompanyBranchesQuery,
  useApiGetProjectSelectCompanyBranchesQuery,
} from '@client/shared/api';
import React, { RefObject, useEffect, useMemo, useState } from 'react';
import {
  Button,
  ComboSelect,
  ComboSelectAdditionalOption,
  ContactIcon,
  DocumentViewerFileDataInlineEdit,
  DocumentViewerFileDataSet,
  FormField,
  FormRefHandle,
  LoadingIndicator,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { BranchData, InvoiceCreateFormValidationValues } from '..';
import { AddCompanyModal } from '../../AddCompanyModal';
import { SpvModal } from '../../SpvModal';

export interface InvoiceDocumentReviewPartyAndRecipientProps {
  contract?: ShortContractReadModel | null;
  invoicingPartyId?: string | null;
  invoiceRecipientId?: string | null;
  invoiceDocument?: AiEvalGenericResultReadModel;
  formRef: RefObject<FormRefHandle<InvoiceCreateFormValidationValues>> | null;
  defaultFormValues: InvoiceCreateFormValidationValues;
  updateUnsavedData?: (formField: string, unsaved: boolean) => void;
}

export const InvoiceDocumentReviewPartyAndRecipient = (props: InvoiceDocumentReviewPartyAndRecipientProps) => {
  const {
    contract,
    invoicingPartyId,
    invoiceRecipientId,
    invoiceDocument,
    formRef,
    defaultFormValues,
    updateUnsavedData,
  } = props;

  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');

  const [allowChangeMode, setAllowChangeMode] = useState(true);
  const [createCompanyField, setCreateCompanyField] = useState<'contractor' | 'client'>('contractor');
  const [isOpenCreateCompanyModal, setIsOpenCreateCompanyModal] = useState(false);
  const [isOpenAddSpvModal, setIsOpenAddSpvModal] = useState(false);
  const [createdCompany, setCreatedCompany] = useState<string | undefined>(undefined);

  const { data: branches, isFetching: isLoadingBranches } = useApiGetProjectSelectCompanyBranchesQuery(
    {
      projectId: loadedProjectId ?? '',
    },
    {
      skip: !loadedProjectId,
    },
  );

  const { data: invoiceRecipientBranches, isFetching: isLoadingInvoiceRecipientBranches } =
    useApiGetInvoiceRecipientSelectCompanyBranchesQuery(
      {
        projectId: loadedProjectId ?? '',
      },
      {
        skip: !loadedProjectId,
      },
    );

  const companiesOptions = useMemo(() => {
    return getCompanyBranches(branches);
  }, [branches]);

  const invoiceRecipientCompaniesOptions = useMemo(() => {
    return getCompanyBranches(invoiceRecipientBranches?.branches);
  }, [invoiceRecipientBranches]);

  const selectedContractor = useMemo(() => {
    return branches?.find((branch) => branch.id === invoicingPartyId);
  }, [invoicingPartyId, branches]);

  const selectedClient = useMemo(() => {
    return branches?.find((branch) => branch.id === invoiceRecipientId);
  }, [invoiceRecipientId, branches]);

  useEffect(() => {
    if (createdCompany && formRef?.current && branches?.length) {
      const foundBranch = branches?.find(
        (branch) => branch.id === createdCompany || branch.companyId === createdCompany,
      );
      if (foundBranch) {
        if (createCompanyField === 'contractor') {
          formRef?.current?.setValue('invoicingPartyId', foundBranch.id);
          if (updateUnsavedData) {
            updateUnsavedData('invoicingPartyId', defaultFormValues?.invoicingPartyId === foundBranch.id);
          }
        } else {
          formRef?.current?.setValue('invoiceRecipientId', foundBranch.id);
          if (updateUnsavedData) {
            updateUnsavedData('invoiceRecipientId', defaultFormValues?.invoiceRecipientId === foundBranch.id);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdCompany, branches]);

  return isLoadingBranches || isLoadingInvoiceRecipientBranches ? (
    <LoadingIndicator text={t('common.loading')} />
  ) : (
    <div className="relative flex flex-col divide-y-2 gap-4">
      {/* If there is a contract selected, the invoicing party cannot be selected */}
      {/*--------- INVOICING PARTY -------*/}
      {!contract && (
        <DocumentViewerFileDataInlineEdit
          allowChangeMode={allowChangeMode}
          toggleContent={
            canWrite ? (
              <FormField name="invoicingPartyId">
                {(control) => (
                  <ComboSelect
                    label={t('projectControl.invoicingParty')}
                    icon={<ContactIcon className="h-6 w-6" />}
                    pageOptions={true}
                    options={companiesOptions}
                    additionalOption={
                      <ComboSelectAdditionalOption label={t('projectControl.createNewInvoicingParty')} 
                        disabled={!canWrite}
                        onClick={() => {
                          setCreateCompanyField('contractor');
                          setIsOpenCreateCompanyModal(true);
                        }} 
                      />
                    }
                    disabled={!canWrite}
                    {...control}
                    nullable
                    handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                    onChange={(branchId) => {
                      control.onChange(branchId);
                      if (updateUnsavedData) {
                        updateUnsavedData('invoicingPartyId', defaultFormValues?.invoicingPartyId === branchId);
                      }
                    }}
                  />
                )}
              </FormField>
            ) : undefined
          }
        >
          <div className="divide-y-2">
            {selectedContractor ? (
              <BranchData
                branch={selectedContractor}
                label={t('projectControl.invoicingParty')}
                branchNameClassName={
                  defaultFormValues?.invoicingPartyId !== selectedContractor?.id ? 'text-secondary' : ''
                }
                // notMatched
              />
            ) : (
              <DocumentViewerFileDataSet label={t('projectControl.invoicingParty')}>
                <div className="w-full flex justify-center">
                  <Button variant="secondary">{t('common.add')}</Button>
                </div>
              </DocumentViewerFileDataSet>
            )}
          </div>
        </DocumentViewerFileDataInlineEdit>
      )}

      {/* If there is a contract selected the default invoice recipient is the same as contract client, if exists or not already overwritten */}
      {/*--------- INVOICE RECIPIENT -------*/}
      <DocumentViewerFileDataInlineEdit
        marginX={!contract ? '-mb-[18px]' : undefined}
        allowChangeMode={allowChangeMode}
        toggleContent={
          canWrite ? (
            <FormField name="invoiceRecipientId">
              {(control) => (
                <ComboSelect
                  label={t('projectControl.invoiceRecipient')}
                  icon={<ContactIcon className="h-6 w-6" />}
                  pageOptions={true}
                  options={invoiceRecipientCompaniesOptions}
                  additionalOption={
                    <div className="flex flex-col divide-y">
                      {!invoiceRecipientBranches?.isSpvCompanies && (
                        <ComboSelectAdditionalOption
                          label={t('projectControl.createNewInvoiceRecipient')}
                          disabled={!canWrite}
                          onClick={() => {
                            setCreateCompanyField('client');
                            setIsOpenCreateCompanyModal(true);
                          }}
                        />
                      )}
                      <ComboSelectAdditionalOption
                        label={t('projectControl.createNewSpv')}
                        disabled={!canWrite}
                        onClick={() => {
                          setCreateCompanyField('client');
                          setIsOpenAddSpvModal(true);
                        }}
                      />
                    </div>
                  }
                  disabled={!canWrite}
                  {...control}
                  nullable
                  handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                  onChange={(branchId) => {
                    control.onChange(branchId);
                    if (updateUnsavedData) {
                      updateUnsavedData('invoiceRecipientId', defaultFormValues?.invoiceRecipientId === branchId);
                    }
                  }}
                />
              )}
            </FormField>
          ) : undefined
        }
      >
        <div className={!contract ? 'mt-4' : ''}>
          {selectedClient ? (
            <BranchData
              branch={selectedClient}
              label={t('projectControl.invoiceRecipient')}
              branchNameClassName={defaultFormValues?.invoiceRecipientId !== selectedClient?.id ? 'text-secondary' : ''}
              // notMatched
            />
          ) : (
            <DocumentViewerFileDataSet label={t('projectControl.invoiceRecipient')}>
              <div className="w-full flex justify-center">
                <Button variant="secondary">{t('common.add')}</Button>
              </div>
            </DocumentViewerFileDataSet>
          )}
        </div>
      </DocumentViewerFileDataInlineEdit>
      <AddCompanyModal
        isOpen={isOpenCreateCompanyModal}
        onClose={(branchId) => {
          setCreatedCompany(branchId);
          setIsOpenCreateCompanyModal(false);
        }}
        field={createCompanyField}
        invoiceDocumentFileData={invoiceDocument?.result}
      />
      <SpvModal
        isOpen={isOpenAddSpvModal}
        onClose={(spvId) => {
          if(spvId) {
            setCreatedCompany(spvId);
          }
          setIsOpenAddSpvModal(false);
        }}
        projectId={loadedProjectId}
      />
    </div>
  );
};
