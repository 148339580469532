import { 
  useApiPostTakeoverInvoiceByContractMutation, 
  useApiGetSelectableContractsQuery 
} from '@client/shared/api';
import { 
  HintBox, 
  ModalOnCloseProps, 
  LoadingIndicator, 
  ComboSelectOption, 
  Modal, ComboSelect, 
  DocumentCustomIcon, 
  Button 
} from '@client/shared/toolkit';
import { safeMutation } from '@client/shared/utilities';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useEffect, useState } from 'react';

interface InvoiceTakeoverModalProps extends ModalOnCloseProps {
  invoiceId: string;
  contractId: string;
}

export const InvoiceTakeoverModal = ({ invoiceId, contractId, onClose }: InvoiceTakeoverModalProps) => {
  const { t } = useTranslation();
  
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [contractOptions, setContractsOptions] = useState<ComboSelectOption[]>([]);
  const [selectedContract, setSelectedContract] = useState<string | null>(null);

  const [takeOverInvoiceByContract, { isLoading : isTakingOver }] = useApiPostTakeoverInvoiceByContractMutation();

  const { data : contracts, isFetching : isLoadingContracts} = useApiGetSelectableContractsQuery({
    projectId : loadedProjectId ?? '',
    calculationModelId : loadedVariantId ?? ''
  }, {
    skip : !loadedProjectId || !loadedVariantId
  })

  useEffect(() => {
    const options: ComboSelectOption[] = [];

    contracts?.forEach((contract) => {
      if (contract.id !== contractId) {
        options.push({
          value: contract.id,
          label: `${contract.code} - ${contract.name}`,
        })
      }
    });

    setContractsOptions(options);
  }, [contracts, contractId]);

  const handleTakeover = async () => {
    if (!selectedContract){
      return;
    }
    
    try {
      await safeMutation(
        takeOverInvoiceByContract,
        {
          invoiceId: invoiceId,
          contractId: selectedContract,
          originalContractId: contractId,
          projectId: loadedProjectId ?? '',
          calculationModelId: loadedVariantId ?? ''
        },
        isTakingOver
      );

      onClose(true);
    } catch {
      /* left blank */
    }
  };

  return (
    <>
    <Modal.Header title={t('projectControl.takeoverInvoiceTitle')} />
    <Modal.Content className="space-y-6">
      {(isLoadingContracts || isTakingOver) && <LoadingIndicator text={t('projectControl.takeoverInvoiceLoading') ?? ''} mode="overlay" />}
      <div className="border-b">
        <ComboSelect
          label={t('projectContract.contract')}
          options={contractOptions}
          value={selectedContract}
          onChange={(value) => setSelectedContract(value)}
          icon={<DocumentCustomIcon />}
        />
      </div>
      <HintBox hintType="warning">{t('projectControl.takeoverInvoiceHint')}</HintBox>
    </Modal.Content>
    <Modal.Controls className="bg-white">
      <Button variant="secondary" onClick={() => onClose(false)} className="mr-2">
        {t('common.cancel')}
      </Button>
      <Button variant="primary" onClick={handleTakeover} disabled={!selectedContract}>
        {t('projectControl.takeoverInvoice')}
      </Button>
    </Modal.Controls>
  </>
  );
};
