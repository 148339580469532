import {
  useLoadedProjectUnitSystemSymbol,
  useLoadedProjectId,
  useLoadedVariantId,
  getAssetClasses,
  useLoadedAssetClasses,
} from '@client/project/store';
import {
  TaxonomyReadModel,
  TaxonomyType,
  ParkingLotType,
  ParkingLotFeatures,
  useApiPostInitializeTaxonomyMutation,
  useApiPostUpdateTaxonomyMutation,
  UserDefinedFieldPayload,
} from '@client/shared/api';
import {
  MultiSelect,
  BaseSelect,
  Button,
  Form,
  FormField,
  FormRefHandle,
  FormWatch,
  ResumeIcon,
  Modal,
  NumberInput,
  SlideOver,
  SlideOverOnCloseProps,
  TextInput,
  EditNodeIcon, SlideOverTitle, LoadingIndicator,
  ComboSelect,
} from '@client/shared/toolkit';
import { BuildingOfficeIcon, CalculatorIcon, DocumentIcon, TagIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import { useAllowedTaxonomyTypes, useMgfExclusiveTypes } from '../../hooks';
import {
  BuildingItemAddFormValidationSchema,
  BuildingItemAddFormValidationValues,
} from './BuildingItemAddFormValidationSchema';
import { BuildingItemDeleteModal } from './BuildingItemDeleteModal';
import { EditUserDefinedFields } from '@client/project/shared';

interface BuildingItemSlideOverProps extends SlideOverOnCloseProps {
  item?: TaxonomyReadModel;
  isReadOnly: boolean;
  canDelete: boolean;
  field?: string;
}

export const BuildingItemSlideOver = ({ item, isReadOnly, canDelete, onClose }: BuildingItemSlideOverProps) => {
  const { t } = useTranslation();

  const formRef = useRef<FormRefHandle<BuildingItemAddFormValidationValues>>();

  const isEditing = item != null;

  const loadedProjectId = useLoadedProjectId();

  const loadedVariantId = useLoadedVariantId();

  const unitSystem = useLoadedProjectUnitSystemSymbol();

  const { forModifying } = useAllowedTaxonomyTypes(item);
  const mgfExclusiveTypes = useMgfExclusiveTypes(item);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const [updateBuildingItem, { isLoading: isUpdating }] = useApiPostUpdateTaxonomyMutation();
  const [initBuildingTree, { isLoading: isCreating }] = useApiPostInitializeTaxonomyMutation();

  // for custom fields
  const [udfUpdatePayload, setUdfUpdatePayload] = useState<UserDefinedFieldPayload[] | undefined>();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [customFieldsAreValid, setCustomFieldsAreValid] = useState(true)

  const availableAssetClasses = useLoadedAssetClasses();
  const assetClassOptions = useMemo(() => getAssetClasses(availableAssetClasses),[availableAssetClasses]);

  const allowedTypes = forModifying.map((taxonomyType) => ({
    label: t(`projectTaxonomy.taxonomyType.${taxonomyType}`),
    value: taxonomyType,
  }));

  if (allowedTypes.length === 0 || item?.itemType === 'Project') {
    allowedTypes.push({
      label: t(`projectTaxonomy.taxonomyType.Project`),
      value: 'Project',
    });
  }

  const parkingLotTypes = ['Undefined', 'Garage', 'Outdoor', 'Duplex', 'Carport', 'Other'];
  const allowedParkingLotTypes = parkingLotTypes.slice(1).map((parkingType) => ({
    label: t(`projectTaxonomy.parkingLotType.${parkingType}`),
    value: parkingType,
  }));

  const parkingLotFeatures: Record<string, string> = {
    Wallbox11kW: '11kW Wallbox',
    Wallbox22kW: '22kW Wallbox',
    Xxl: 'XXL',
    Disabled: 'Disabled',
  };

  const allowedParkingLotFeatures = Object.keys(parkingLotFeatures).map((parkingType) => ({
    label: parkingLotFeatures[parkingType],
    value: parkingType,
  }));

  const updateBuildingItemPiece = async (data: BuildingItemAddFormValidationValues) => {
    if (item == null) return;
    try {
      await safeMutation(
        updateBuildingItem,
        {
          taxonomyItemId: item?.itemId,
          calculationModelId: loadedVariantId ?? '',
          projectId: loadedProjectId ?? 'unset',
          body: {
            name: data.name,
            customerName: data.customerName,
            bgf: mgfExclusiveTypes.includes(data.type as TaxonomyType) ? undefined : data.bgf,
            ngf: mgfExclusiveTypes.includes(data.type as TaxonomyType) ? undefined : data.ngf,
            // mfgEfficiency: item?.grossFloorEfficiency ?? undefined,
            // ngfEfficiency: item?.netFloorEfficiency ?? undefined,
            rentalSpace: data.rentalSpace,
            type: data.type as TaxonomyType,
            selectedAssetClassId: data.assetClass,
            floorAreaCount: item?.sizes?.floorAreaCount,
            constructionFloor: item?.sizes?.constructionFloor?.value,
            salesSpace: item?.sizes?.salesSpace?.value,
            commonSpace: item?.sizes?.commonSpace?.value,
            grossVolume: item?.sizes?.grossVolume?.value,
            other: item?.sizes?.other?.value,
            amount: data.amount,
            parkingLot:
              data.type === 'Parking'
                ? {
                    number: data.parkingLotNumber,
                    type: data.parkingLotType as ParkingLotType,
                    features: data.parkingLotFeatures as ParkingLotFeatures[],
                    description: data.parkingLotDescription,
                  }
                : null,
            description: data.description,
            cost: data.cost,
            userDefinedFieldsPayload: udfUpdatePayload?.length ? udfUpdatePayload : undefined,
          },
        },
        isUpdating
      );
      onClose(true);
    } catch (error) {
      console.log(error);
    }
  };

  const createInitialBuilding = async (data: BuildingItemAddFormValidationValues) => {
    if (item != null) return;

    try {
      await safeMutation(
        initBuildingTree,
        {
          calculationModelId: loadedVariantId ?? '',
          projectId: loadedProjectId ?? 'unset',
          body: {
            name: data.name,
            customerName: data.customerName,
            bgf: data.bgf,
            ngf: data.ngf,
            rentalSpace: data.rentalSpace,
            userDefinedFieldsPayload: udfUpdatePayload
          },
        },
        isCreating
      );

      onClose(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnClose = () => {
    if (!isOpenDeleteModal) {
      onClose(false);
    }
  };

  const handleSubmit = isEditing ? updateBuildingItemPiece : createInitialBuilding;

  const defaultFormValues = {
    name: item?.itemName ?? '',
    customerName: item?.itemCustomerName ?? '',
    bgf: item?.sizes?.bgf?.value ?? undefined,
    ngf: item?.sizes?.ngf?.value ?? undefined,
    mfgEfficiency: item?.grossFloorEfficiency?.value ?? undefined,
    ngfEfficiency: item?.netFloorEfficiency?.value ?? undefined,
    rentalSpace: item?.sizes?.rentalSpace?.value ?? null,
    type: (item?.itemType as TaxonomyType) ?? 'Project',
    assetClass: (item?.selectedAssetClass?.id) ?? null,
    amount: item?.sizes?.amount?.value ?? undefined,
    parkingLotDescription: item?.parkingLot?.description ?? '',
    parkingLotNumber: item?.parkingLot?.number ?? '',
    parkingLotType: item?.parkingLot?.type ?? '',
    parkingLotFeatures: item?.parkingLot?.features ?? [],
    description: item?.description ?? '',
    cost: item?.cost ?? undefined,
  };

  return (
    <>
      {(isCreating || isUpdating) && (
        <LoadingIndicator
          text={
            isCreating
              ? t('projectTaxonomy.creatingTaxonomyLoadingIndicator')
              : t('projectTaxonomy.updatingTaxonomyLoadingIndicator')
          }
          mode="overlay-window"
        />
      )}
      <SlideOver.Header
        onClose={handleOnClose}
        title={item?.itemName ?? t('projectTaxonomy.taxonomyItem')}
        subTitle={item?.itemType ? t(`projectTaxonomy.taxonomyType.${item?.itemType}`) : ''}
        backgroundClassName="bg-sky-900"
      />

      <Form<BuildingItemAddFormValidationValues>
        onSubmit={(data) => {
          setIsFormSubmitted(true);
          if (customFieldsAreValid) {
            handleSubmit(data);
          }
        }}
        validationSchema={BuildingItemAddFormValidationSchema}
        defaultValues={defaultFormValues}
        className="flex flex-col flex-grow min-h-0"
        ref={formRef}
      >
        <SlideOver.Content>
          <div className="m-8 mb-0 bg-white">
            <div className="divide-gray-100 divide-y">
              <FormField name="type">
                {(control) => (
                  <BaseSelect
                    label={t('projectTaxonomy.addBuildingElementLabelType')}
                    options={allowedTypes}
                    icon={<DocumentIcon />}
                    disabled={item == null || item.itemType === 'Project' || isReadOnly}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="name">
                {(control) => <TextInput label="Name" disabled={isReadOnly} icon={<EditNodeIcon />} {...control} />}
              </FormField>
              <FormField name="customerName">
                {(control) => (
                  <TextInput
                    disabled={isReadOnly}
                    label={t('projectTaxonomy.addBuildingElementLabelCustomerName')}
                    icon={<EditNodeIcon />}
                    {...control}
                  />
                )}
              </FormField>
              {item?.itemType !== 'Parking' && (
                <FormField name="description">
                  {(control) => (
                    <TextInput
                      disabled={isReadOnly}
                      label={t('projectTaxonomy.addBuildingElementLabelDescription')}
                      icon={<ResumeIcon />}
                      inputType="textarea"
                      {...control}
                    />
                  )}
                </FormField>
              )}
              <FormWatch<BuildingItemAddFormValidationValues>
                onChange={({ type }) => {
                  if (type === 'Parking') {
                    formRef.current?.setValue('assetClass', availableAssetClasses.find(x => x.enumValue === 'Traffic')?.id ?? '');
                  }
                }}
                fieldNames={['type']}
              >
                {({ type }) => (
                  <>
                    {item != null && item.itemType !== 'Project' && (
                      <FormField name="assetClass">
                        {(control) => (
                          <ComboSelect
                            label={t('projectTaxonomy.addBuildingElementLabelUsageType')}
                            options={assetClassOptions}
                            icon={<DocumentIcon />}
                            disabled={type === 'Parking' || isReadOnly}
                            {...control}
                          />
                        )}
                      </FormField>
                    )}
                  </>
                )}
              </FormWatch>
            </div>
          </div>
          <SlideOverTitle title={t('projectTaxonomy.addBuildingElementLabelKeyValues')} className="px-8" />
          <div className="mx-8 bg-white">
            <div className="divide-gray-100 divide-y">
              <FormWatch<BuildingItemAddFormValidationValues> fieldNames={['type']}>
                {({ type }) => (
                  <>
                    {item?.itemType !== 'Project' && (
                      <FormField name="cost">
                        {(control) => (
                          <NumberInput
                            disabled={isReadOnly}
                            label={t('projectTaxonomy.addBuildingElementLabelCost')}
                            icon={<TagIcon />}
                            {...control}
                          />
                        )}
                      </FormField>
                    )}

                    {!mgfExclusiveTypes.includes(type as TaxonomyType) && (
                      <>
                        <FormField name="bgf">
                          {(control) => (
                            <NumberInput
                              disabled={isReadOnly}
                              label={`${t('projectTaxonomy.buildingBgf')} (${item?.sizes?.bgf?.unit ?? unitSystem})`}
                              icon={<BuildingOfficeIcon />}
                              {...control}
                            />
                          )}
                        </FormField>
                        <FormField name="ngf">
                          {(control) => (
                            <NumberInput
                              disabled={isReadOnly}
                              label={`${t('projectTaxonomy.buildingNgf')} (${item?.sizes?.ngf?.unit ?? unitSystem})`}
                              icon={<BuildingOfficeIcon />}
                              {...control}
                            />
                          )}
                        </FormField>
                      </>
                    )}
                    <FormField name="rentalSpace">
                      {(control) => (
                        <NumberInput
                          disabled={isReadOnly}
                          label={`${t('projectTaxonomy.buildingMfg')} (${
                            item?.sizes?.rentalSpace?.unit ?? unitSystem
                          })`}
                          icon={<BuildingOfficeIcon />}
                          {...control}
                        />
                      )}
                    </FormField>
                    <FormField name="amount">
                      {(control) => (
                        <NumberInput
                          disabled={isReadOnly}
                          label={t('projectTaxonomy.buildingAmount')}
                          icon={<CalculatorIcon />}
                          {...control}
                        />
                      )}
                    </FormField>

                    {type === 'Parking' && (
                      <>
                        <FormField name="parkingLotType">
                          {(control) => (
                            <BaseSelect
                              disabled={isReadOnly}
                              label={t('projectTaxonomy.parkingLot.type')}
                              options={allowedParkingLotTypes}
                              icon={<DocumentIcon />}
                              {...control}
                            />
                          )}
                        </FormField>
                        <FormField name="parkingLotDescription">
                          {(control) => (
                            <TextInput
                              disabled={isReadOnly}
                              label={t('projectTaxonomy.parkingLotDescription')}
                              icon={<ResumeIcon />}
                              inputType="textarea"
                              {...control}
                            />
                          )}
                        </FormField>
                        <FormField name="parkingLotNumber">
                          {(control) => (
                            <TextInput
                              disabled={isReadOnly}
                              label={t('projectTaxonomy.parkingLotNumber')}
                              icon={<DocumentIcon />}
                              {...control}
                            />
                          )}
                        </FormField>

                        <FormField name="parkingLotFeatures">
                          {(control) => (
                            <MultiSelect
                              disabled={isReadOnly}
                              label={t('projectTaxonomy.parkingLotFeatures')}
                              options={allowedParkingLotFeatures}
                              icon={<DocumentIcon />}
                              {...control}
                              value={control.value}
                              onChange={(value) => {
                                if (control.value.includes('Wallbox11kW') && value.includes('Wallbox22kW')) {
                                  control.onChange(value.filter((feature) => feature !== 'Wallbox11kW'));
                                } else if (control.value.includes('Wallbox22kW') && value.includes('Wallbox11kW')) {
                                  control.onChange(value.filter((feature) => feature !== 'Wallbox22kW'));
                                } else {
                                  control.onChange(value);
                                }
                              }}
                              className="w-full"
                            />
                          )}
                        </FormField>
                      </>
                    )}
                    {/*
                    // user should be able to manually override the efficiency which then calculated NGF and BGF. This is not implemented in the endpoint yet.
                    {type === 'Building' && (
                      <>
                        <FormField name="mfgEfficiency">
                          {(control) => (
                            <NumberInput
                              disabled={isReadOnly}
                              label={`${t('projectTaxonomy.buildingMfg')} ${t('projectTaxonomy.building.efficiency')}`}
                              icon={<CalculatorIcon />}
                              {...control}
                            />
                          )}
                        </FormField>
                        <FormField name="ngfEfficiency">
                          {(control) => (
                            <NumberInput
                              disabled={isReadOnly}
                              label={`${t('projectTaxonomy.buildingNgf')} ${t('projectTaxonomy.building.efficiency')}`}
                              icon={<CalculatorIcon />}
                              {...control}
                            />
                          )}
                        </FormField>
                      </>
                    )} */}
                  </>
                )}
              </FormWatch>
            </div>
          </div>
          {!isReadOnly && (
            <EditUserDefinedFields
              elementId={item?.itemId}
              type="Taxonomy"
              setUpdatePayload={setUdfUpdatePayload}
              isSubmitted={isFormSubmitted}
              updateIsValid={setCustomFieldsAreValid}
            />
          )}
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className={classNames('flex w-full', isEditing ? 'justify-between' : 'justify-end')}>
            {item != null && canDelete && (
              <Button variant="warning" onClick={() => setIsOpenDeleteModal(true)}>
                {t('common.delete')}
              </Button>
            )}
            <div className="flex">
              <Button variant="secondary" className="mr-2" onClick={handleOnClose}>
                {t('common.cancel')}
              </Button>
              {!isReadOnly && (
                <Button variant="primary" formSubmit onClick={() => setIsFormSubmitted(true)}>
                  {t('common.save')}
                </Button>
              )}
            </div>
          </div>
        </SlideOver.Controls>
      </Form>

      {item != null && (
        <Modal isOpen={isOpenDeleteModal} onClose={() => setIsOpenDeleteModal(false)}>
          <BuildingItemDeleteModal onClose={() => setIsOpenDeleteModal(false)} item={item} />
        </Modal>
      )}
    </>
  );
};

