import { useApiGetCalculationModelEarningsQuery } from '@client/shared/api';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { ProjectCalculationModelElementsSelect } from './ProjectCalculationModelElementsSelect';
import { EarningsElementMultiSelect, findEarningsElement, useFlattenEarningsElements } from '@client/project/shared';

export interface ProjectCalculationModelEarningsSelectProps {
  setIsFetching: (isFetching: boolean) => void;
  selectedElementIds: string[];
  setSelectedElementIds: (value: string[]) => void;
  className?: string;
  showControls?: boolean;
  useStore?: boolean;
}

export const ProjectCalculationModelEarningsSelect = (props: ProjectCalculationModelEarningsSelectProps) => {
  const { setIsFetching, selectedElementIds, setSelectedElementIds, className = 'w-full', showControls, useStore = true } = props;

  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const popoverButtonRef = useRef<HTMLDivElement>(null);

  const { data: earningsData, isFetching } = useApiGetCalculationModelEarningsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? '',
    },
    { skip: loadedVariantId == null },
  );

  const flattenedEarningElements = useFlattenEarningsElements(earningsData, true);

  const selectedLabels = useMemo(() => {
    return selectedElementIds.length > 0
      ? selectedElementIds
          .map((elementId) => {
            const element = findEarningsElement(elementId, flattenedEarningElements);
            return element?.description === '' ? t('projectCalculate.unnamedElement') : element?.description ?? '';
          })
          .join(', ')
      : t('projectCalculate.NoSelection');
  }, [selectedElementIds, flattenedEarningElements, t]);

  useEffect(() => {
    setIsFetching(isFetching);
  }, [isFetching, setIsFetching]);

  return (
    <ProjectCalculationModelElementsSelect
      className={className}
      label={t('projectCalculate.revenueGroups')}
      selectedLabels={selectedLabels}
      popoverButtonRef={popoverButtonRef}
      fallbackPlacements={['right-start', 'top']}
      height="h-[450px]"
    >
      <EarningsElementMultiSelect
        earningsData={earningsData?.payload?.earnings ?? []}
        selectedElements={selectedElementIds}
        updateSelectedElements={setSelectedElementIds}
        onClose={() => popoverButtonRef.current?.click()}
        showControls={showControls}
        useStore={useStore}
      />
    </ProjectCalculationModelElementsSelect>
  );
};
