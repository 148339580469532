import {
  Button,
  HintBox,
  LoadingIndicator,
  Modal,
  TextInput,
  Wizard,
  WizardSlideContent,
  WizardSlideHeader,
} from '@client/shared/toolkit';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalApiReadModel, useApiGetExternalApisQuery, WorkflowTaskStatus } from '@client/shared/api';
import { isEmpty } from '@client/shared/utilities';
import { API_MAPPING } from '../ApiClients';

interface InvoiceReviewCommentModalProps {
  reviewInvoice: (comment: string, reUpload: boolean) => void;
  onClose: () => void;
  onBackAction: () => void;
  completionStatus: WorkflowTaskStatus | null;
  error?: string | null;
  isProcessing?: boolean;
}

export const InvoiceReviewCommentModal = ({
  reviewInvoice,
  onClose,
  onBackAction,
  completionStatus,
  error,
  isProcessing
}: InvoiceReviewCommentModalProps) => {
  const { t } = useTranslation();

  const { data: externalApis } = useApiGetExternalApisQuery();

  const invoiceAiActive = useMemo(() => {
    return !!externalApis?.find((x: ExternalApiReadModel) => x.api.name === API_MAPPING.AI_EVAL_DOCUMENT)?.api.isActive;
  }, [externalApis]);

  const [comment, setComment] = useState('');
  const [reUpload, setReUpload] = useState(false);

  const getTitle = (): string => {
    if (completionStatus === 'Declined') return t('projectControl.addCommentDeclined');

    if (completionStatus === 'Rejected') return t('projectControl.addCommentRejection');

    return t('projectControl.addComment');
  };

  const handleAddComment = () => {
    reviewInvoice(comment, reUpload);
  };

  const [currentStep, setCurrentStep] = useState(completionStatus === 'Declined' ? 0 : 1);

  return (<>
    {isProcessing && <LoadingIndicator mode="overlay" />}
    <Wizard currentStep={currentStep} onSlideChange={(step) => setCurrentStep(step)}>
      <Wizard.Slides>
        <Wizard.Slide>
          <WizardSlideHeader title={t('projectControl.declineWorkflowTitle')} />
          <WizardSlideContent className="h-full flex flex-col justify-center">
            <HintBox>{t('projectControl.declineWorkflowDescription')}</HintBox>
          </WizardSlideContent>
        </Wizard.Slide>
        <Wizard.Slide appear={completionStatus === 'Declined' ? true : false}>
          <WizardSlideHeader title={getTitle()} subTitle={t('projectControl.addCommentDescription')} />
          <WizardSlideContent className="h-full">
            {error && <p className="text-red-500 text-sm pb-3">{error}</p>}
            <TextInput
              label={t('projectControl.comment')}
              value={comment}
              onChange={(value) => setComment(value)}
              inputType="textarea"
              className="min-h-40"
              isValidationValid={!isEmpty(comment)}
            />
          </WizardSlideContent>
        </Wizard.Slide>
      </Wizard.Slides>
      <Wizard.Navigation>
        {({ canGoNext, next, previous }) => (
          <Modal.Controls className="bg-white">
            {(() => {
              return (
                <div className="flex justify-between w-full">
                  <Button variant="secondary" onClick={onClose}>
                    {t('common.cancel')}
                  </Button>

                  {currentStep === 0 ? (
                    <div className="flex gap-2">
                      <Button
                        variant="danger"
                        disabled={!canGoNext || !invoiceAiActive }
                        onClick={() => {
                          setReUpload(true);
                          next();
                        }}
                      >
                        {t('projectControl.reUpload')}
                      </Button>
                      <Button variant="danger" disabled={!canGoNext} onClick={next}>
                        {t('projectControl.workflowDeclined')}
                      </Button>
                    </div>
                  ) : (
                    <div className="flex gap-2">
                      {completionStatus === 'Declined' && (
                        <Button variant="secondary" onClick={() => {
                          previous();
                          onBackAction();
                        }}>
                          {t('common.back')}
                        </Button>
                      )}
                      <Button
                        variant="primary"
                        disabled={completionStatus === 'Declined' && isEmpty(comment)}
                        onClick={handleAddComment}
                      >
                        {t('common.proceed')}
                      </Button>
                    </div>
                  )}
                </div>
              );
            })()}
          </Modal.Controls>
        )}
      </Wizard.Navigation>
    </Wizard>
    </>);
};
