import {
  AddressCardContent,
  BaseSelectOption,
  Button,
  ComboSelect,
  ComboSelectAdditionalOption,
  ContractIcon,
  DocumentViewerFileDataGroup,
  DocumentViewerFileDataInlineEdit,
  DocumentViewerFileDataSet,
  FormField,
  FormRefHandle,
  Modal,
  PaidBillDottedIcon,
  SlideOverTitle,
  WorkflowIcon,
} from '@client/shared/toolkit';
import React, { RefObject, useEffect, useMemo, useState } from 'react';
import {
  AiEvalGenericResultReadModel,
  apiEndpointUrls,
  InvoiceType,
  ShortContractReadModel,
  useApiGetContractsQuery,
  useApiGetProjectSelectCompanyBranchesQuery,
  useApiGetWorkflowsQuery,
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import cn from 'classnames';
import classNames from 'classnames';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { addDays } from 'date-fns';
import { InvoiceCreateFormValidationValues } from '../InvoiceCreateFormValidationValues';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { ContractNewWizard } from '../../Contract';

export interface InvoiceDocumentReviewContractProps {
  contract: ShortContractReadModel | null;
  invoicingPartyId?: string | null;
  allowChangeMode: boolean;
  setAllowChangeMode: (allowChangeMode: boolean) => void;
  setSelectedContract: (contract: ShortContractReadModel | null) => void;
  formRef: RefObject<FormRefHandle<InvoiceCreateFormValidationValues>> | null;
  dateOfReceipt?: string | null;
  type?: NonNullable<InvoiceType | undefined>;
  invoiceDocument?: AiEvalGenericResultReadModel;
  defaultFormValues: InvoiceCreateFormValidationValues;
  updateUnsavedData?: (formField: string, unsaved: boolean) => void;
  setIsLoading?: (isLoading: boolean) => void;
  workflow?: string | null;
}

/**
 * If invoice document result contains a contractId, show contractor and client of contract
 */
export const InvoiceDocumentReviewContract = (props: InvoiceDocumentReviewContractProps) => {
  const {
    invoiceDocument,
    contract,
    invoicingPartyId,
    allowChangeMode,
    setAllowChangeMode,
    setSelectedContract,
    formRef,
    defaultFormValues,
    dateOfReceipt,
    type,
    updateUnsavedData,
    setIsLoading,
    workflow
  } = props;
  const { t } = useTranslation();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');

  const [isCreateContractWizardOpen, setIsCreateContractWizardOpen] = useState(false);
  const [createdContract, setCreatedContract] = useState<string | undefined>(undefined);

  const { data: workflows } = useApiGetWorkflowsQuery();
  const { data: fetchedContracts, isFetching: isFetchingContracts } = useApiGetContractsQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
    },
    {
      skip: !loadedProjectId || !loadedVariantId,
    },
  );
  const { data: branches, isFetching: isLoadingBranches } = useApiGetProjectSelectCompanyBranchesQuery(
    {
      projectId: loadedProjectId ?? '',
    },
    {
      skip: !loadedProjectId,
    },
  );

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(isLoadingBranches || isFetchingContracts);
    }
  }, [setIsLoading, isLoadingBranches, isFetchingContracts]);

  const contractor = useMemo(() => {
    return branches?.find((branch) => branch.id === contract?.contractorId);
  }, [branches, contract?.contractorId]);

  const client = useMemo(() => {
    return branches?.find((branch) => branch.id === contract?.clientId);
  }, [branches, contract?.clientId]);

  const workflowOptions = useMemo(() => {
    return workflows?.workflows
      .filter((workflow) => workflow.workflowType === 'Invoice')
      .map((workflow) => ({
        label: workflow.name,
        value: workflow.definitionId,
      })) as BaseSelectOption[];
  }, [workflows?.workflows]);

  const contracts = useMemo(() => {
    let allContracts: ShortContractReadModel[] = fetchedContracts?.contracts ?? [];
    let contractOptions: BaseSelectOption[] = [];

    if (typeof fetchedContracts !== 'undefined') {
      contractOptions =
        fetchedContracts.contracts
          ?.map((contract) => {
            return {
              value: contract.id,
              label: `${contract.code} - ${contract.name}`,
            };
          }) ?? [];

      fetchedContracts.commitments?.forEach((commitment) => {
        allContracts = [...allContracts, ...(commitment.contracts ?? [])];
        contractOptions = contractOptions.concat(
          commitment.contracts
            ?.map((contract) => {
              return {
                value: contract.id,
                label: `${contract.code} - ${contract.name}`,
              };
            }) ?? [],
        );
      });
    }
    return {
      contractOptions: contractOptions,
      allContracts: allContracts,
    };
  }, [fetchedContracts]);

  /* Assign newly created contract */
  useEffect(() => {
    if (createdContract && contracts?.allContracts.length && formRef?.current) {
      const foundContract = contracts?.allContracts.find((contract) => contract.id === createdContract);
      if (foundContract) {
        setSelectedContract(foundContract);
        if (updateUnsavedData) {
          updateUnsavedData('contractId', defaultFormValues.contractId === foundContract?.id);
        }
        formRef?.current.setValue('invoicingPartyId', foundContract.contractorId ?? null);
        if (!invoiceDocument?.invoiceMetadata?.invoiceRecipientId) {
          formRef?.current.setValue('invoiceRecipientId', foundContract.clientId ?? null);
        }
        formRef?.current.setValue('contractId', foundContract.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdContract, contracts?.allContracts]);

  /* Set default values for invoicing party and invoice recipient from contract if selected and not already overwritten */
  useEffect(() => {
    if (
      (invoiceDocument?.invoiceMetadata?.uploadedByContract?.id || invoiceDocument?.result?.contractId) &&
      contracts?.allContracts.length &&
      formRef?.current &&
      !createdContract
    ) {
      const contractId = invoiceDocument?.invoiceMetadata ? invoiceDocument?.invoiceMetadata?.uploadedByContract?.id ?? null : invoiceDocument?.result?.contractId;
      const foundContract = contracts?.allContracts.find((contract) => contract.id === contractId);
      if (foundContract) {
        setSelectedContract(foundContract ?? null);
        formRef?.current.setValue('contractId', foundContract.id);
        formRef?.current.setValue('invoicingPartyId', foundContract.contractorId ?? null);
        if (!invoiceDocument?.invoiceMetadata?.invoiceRecipientId) {
          formRef?.current.setValue('invoiceRecipientId', foundContract.clientId ?? null);
        }
      }
    }
  }, [
    createdContract,
    contracts?.allContracts,
    invoiceDocument?.invoiceMetadata,
    invoiceDocument?.result?.contractId,
    formRef,
    invoiceDocument?.result?.customerId,
    setSelectedContract,
  ]);

  return (
    <>
      <DocumentViewerFileDataGroup className="relative overflow-hidden" divider={false}>
        <Disclosure as="div" className="flex flex-col divide-y-2" defaultOpen>
          {({ open }) => (
            <>
              <DisclosureButton>
                <div className={cn('flex gap-2 cursor-pointer', open ? 'pb-2' : '')}>
                  <PaidBillDottedIcon className="w-9 flex-none" />
                  <SlideOverTitle
                    marginTop={false}
                    title={t('projectContract.contract')}
                    className="mt-4 flex-1 flex hover:text-gray-800 duration-300 transition-colors relative"
                  >
                    <div className={classNames('flex-1 flex items-center justify-end')}>
                      <ChevronDownIcon
                        className={classNames(
                          'transition-transform will-change-transform duration-100 transform -rotate-90 h5 w-5',
                          {
                            'rotate-0': open,
                          },
                        )}
                      />
                    </div>
                  </SlideOverTitle>
                </div>
              </DisclosureButton>
              <DisclosurePanel>
                <div className="flex flex-col divide-y-2">
                  {/* CONTRACT */}
                  <div className="py-4">
                    <DocumentViewerFileDataInlineEdit
                      marginX={contract ? '-mb-[26px] -mt-[18px]' : undefined}
                      allowChangeMode={allowChangeMode}
                      toggleContent={
                        <FormField name="contractId">
                          {(control) => (
                            <ComboSelect
                              nullable
                              icon={<ContractIcon className="h-6 w-6" />}
                              label={t('projectContract.contract')}
                              options={contracts.contractOptions}
                              disabled={!canWrite}
                              handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                              {...control}
                              onChange={(contractId) => {
                                const foundContract = contracts.allContracts.find(
                                  (contract) => contract.id === contractId,
                                );
                                setSelectedContract(foundContract ?? null);
                                control.onChange(contractId);
                                formRef?.current?.setValue('invoicingPartyId', foundContract?.contractorId ?? null);
                                formRef?.current?.setValue('invoiceRecipientId', foundContract?.clientId ?? null);
                                /*
                                 * For the due date the due date deadline days of contract are added to the invoice receipt date
                                 * For the cash discount date the cash discount deadline days of contract are added to the invoice receipt date
                                 */
                                if (formRef?.current && dateOfReceipt && foundContract) {
                                  if (foundContract?.dueDateDeadline) {
                                    formRef?.current.setValue(
                                      'dueDate',
                                      addDays(dateOfReceipt, foundContract.dueDateDeadline),
                                    );
                                  }
                                  if (foundContract?.cashDiscountDeadline) {
                                    formRef?.current.setValue(
                                      'cashDiscountDate',
                                      addDays(dateOfReceipt, foundContract.cashDiscountDeadline),
                                    );
                                  }
                                }
                                if (!foundContract && type !== 'Single') {
                                  formRef?.current?.setValue('type', 'Single');
                                  if (updateUnsavedData) {
                                    updateUnsavedData('type', defaultFormValues.type === 'Single');
                                  }
                                }

                                if (updateUnsavedData) {
                                  updateUnsavedData('contractId', defaultFormValues.contractId === foundContract?.id);
                                }
                              }}
                              additionalOption={
                                <ComboSelectAdditionalOption 
                                  label={t('projectContract.createContract')}
                                  disabled={!canWrite}
                                  onClick={() => setIsCreateContractWizardOpen(true)}
                                />
                              }
                            />
                          )}
                        </FormField>
                      }
                    >
                      {contract ? (
                        <div className="flex flex-col gap-4">
                          <DocumentViewerFileDataSet
                            className={contract.id !== defaultFormValues.contractId ? 'text-secondary' : undefined}
                            label={t('projectContract.contract')}
                            title={contract.name}
                            text={contract?.description}
                          />
                          <div className="grid grid-cols-2 gap-2 items-center">
                            <DocumentViewerFileDataSet
                              label={t('projectControl.contractNr')}
                              subtitle={<span className="font-bold text-lg">{contract.code}</span>}
                            />
                          </div>
                          {contract.commitmentId && (
                            <DocumentViewerFileDataSet
                              label={t('projectControl.commitments')}
                              subtitle={
                                fetchedContracts?.commitments?.find(
                                  (commitment) => commitment.id === contract.commitmentId,
                                )?.name ?? ''
                              }
                            />
                          )}
                        </div>
                      ) : (
                        <DocumentViewerFileDataSet label={t('projectContract.contract')}>
                          {invoicingPartyId ? (
                            <>-</>
                          ) : (
                            <div className="w-full flex justify-center">
                              <Button variant="secondary">{t('common.add')}</Button>
                            </div>
                          )}
                        </DocumentViewerFileDataSet>
                      )}
                    </DocumentViewerFileDataInlineEdit>
                  </div>
                  {/* CONTRACTOR AND CLIENT (only information) */}
                  {contract && (
                    <div className="flex flex-col divide-y-2 my-2 relative">
                      <AddressCardContent
                        contentClassName="py-4"
                        address={contractor?.address ?? undefined}
                        companyName={contractor?.companyName}
                        name={contractor?.name}
                        logo={
                          contractor?.companyLogoId
                            ? apiEndpointUrls.apiGetCompanyLogo.replace(':companyId', contractor?.companyId).replace(':fileId', contractor?.companyLogoId)
                            : undefined
                        }
                        label={t('projectContract.contractor')}
                        centered
                      />
                      <AddressCardContent
                        contentClassName="py-4"
                        address={client?.address ?? undefined}
                        companyName={client?.companyName}
                        name={client?.name}
                        logo={
                          client?.companyLogoId
                            ? apiEndpointUrls.apiGetCompanyLogo.replace(':companyId', client?.companyId).replace(':fileId', client?.companyLogoId)
                            : undefined
                        }
                        label={t('projectContract.contractClient')}
                        centered
                      />
                    </div>
                  )}
                  {/* WORKFLOW */}
                    <DocumentViewerFileDataInlineEdit
                      allowChangeMode={allowChangeMode}
                      className="pt-4"
                      toggleContent={
                        canWrite ? (
                          <FormField name="workflow">
                            {(control) => (
                              <ComboSelect
                                label={t('app.workflow')}
                                icon={<WorkflowIcon className="h-6 w-6" />}
                                nullable
                                options={workflowOptions}
                                disabled={!canWrite || workflowOptions.length === 0}
                                {...control}
                                onChange={(val) => {
                                  control.onChange(val);
                                  if (updateUnsavedData) {
                                    updateUnsavedData('workflow', val === defaultFormValues.workflow);
                                  }
                                }}
                                handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                              />
                            )}
                          </FormField>
                        ) : undefined
                      }
                    >
                      <DocumentViewerFileDataSet
                        label={t('app.workflow')}
                        subtitle={
                          workflow ? workflowOptions?.find((option) => option.value === workflow)?.label : '-'
                        }
                        className={workflow !== defaultFormValues.workflow ? 'text-secondary' : undefined}
                      />
                    </DocumentViewerFileDataInlineEdit>
                </div>
              </DisclosurePanel>
            </>
          )}
        </Disclosure>
      </DocumentViewerFileDataGroup>
      <Modal isOpen={isCreateContractWizardOpen} onClose={() => setIsCreateContractWizardOpen(false)}>
        <ContractNewWizard
          onClose={() => {
            setIsCreateContractWizardOpen(false);
          }}
          setNewContractId={setCreatedContract}
          openNewContract={false}
        />
      </Modal>
    </>
  );
};
