import {
  dashboardConfig,
  DashboardConfigKeys,
  DashboardWidgetType,
  widgets,
  WidgetSizeType,
} from './widgetDashboardConfig';
import { Widget, WidgetConfig, WidgetDashboardTypeSizesConfig } from '../WidgetDashboard';
import {
  BudgetStatusWidgetReadModel,
  CashOutWidgetReadModel,
  CustomDashboardReadModel,
  FinancialUsageWidgetReadModel,
  KpisWidgetReadModel,
  MapViewWidgetReadModel,
  ProjectClockWidgetReadModel,
  ProjectInformationWidgetReadModel,
  ProjectUdfsWidgetReadModel,
  ReportDashboardReadModel,
  RiskMitigationListWidgetReadModel,
  TasksWidgetReadModel,
  TextboxWidgetReadModel,
  TrafficLightsWidgetReadModel,
  WaterfallWidgetReadModel,
} from '@client/shared/api';
import { Layout, Layouts } from 'react-grid-layout';

type WidgetReadModel =
  | BudgetStatusWidgetReadModel
  | CashOutWidgetReadModel
  | FinancialUsageWidgetReadModel
  | KpisWidgetReadModel
  | MapViewWidgetReadModel
  | ProjectInformationWidgetReadModel
  | ProjectUdfsWidgetReadModel
  | RiskMitigationListWidgetReadModel
  | TasksWidgetReadModel
  | TextboxWidgetReadModel
  | TrafficLightsWidgetReadModel
  | WaterfallWidgetReadModel;

/**
 * Gets the configuration of a widget for a given type.
 * Each widget has e.g. its own sizes.
 * @param type The widget type.
 */
export const getWidgetConfigurationForType = (type: DashboardWidgetType): WidgetConfig | null => {
  const foundWidgetConfig = Object.entries(widgets).find((widgetConfig) => {
    return widgetConfig[1].type === type;
  });
  return foundWidgetConfig ? foundWidgetConfig[1] : null;
};

/**
 * Gets the size configuration of a widget for a given widget type and dashboard type.
 * If each widget for dashboard type has its own sizes.
 * @param type The widget sizes.
 * @param dashboardType Type of the dashboard.
 */
export const getWidgetSizeConfigurationForType = (type: DashboardWidgetType, dashboardType: DashboardConfigKeys): WidgetSizeType[] => {
  const foundWidgetConfig = Object.values(widgets).find((widget) => widget.type === type);

  if (!foundWidgetConfig) {
    return [];
  }

  const widgetSizes = foundWidgetConfig.sizes ?? [];
  const allowedWidgetSizes =
    typeof widgetSizes === "object" && dashboardType in widgetSizes
      ? (widgetSizes as WidgetDashboardTypeSizesConfig)[dashboardType]
      : (widgetSizes as WidgetSizeType[]);

  return [{ w: 1, h: 1 }, ...allowedWidgetSizes];
};

/**
 * Maps the dashboard data from the Backend to the ReactGrid Layout.
 * All cells that are not filled with a widget are filled with placeholder widgets.
 *
 * @param type The dashboard type (custom or report dashboard).
 * @param dashboardWidgets The widgets saved in the Backend for this dashboard.
 */
export const mapDashboardWidgetsToGridLayout = (
  type: DashboardConfigKeys,
  dashboardWidgets?: CustomDashboardReadModel | ReportDashboardReadModel,
) => {
  const mappedLayout: Widget[] = [];
  // map to our layout data
  const dashboardLayoutConfig = dashboardConfig[type];
  const widgetProps = dashboardWidgets?.widgetProperties;
  const { rows, cols = { lg: 0 } } = dashboardLayoutConfig;
  const totalCells = rows * cols.lg;
  const filledCells = Array(totalCells).fill(false);

  if (dashboardWidgets && widgetProps) {
    widgetProps.forEach((property) => {
      const widgetProp = property.charAt(0).toLowerCase() + property.slice(1);
      if (widgetProp in dashboardWidgets) {
        const widgetsAtWidgetProp = dashboardWidgets[widgetProp as keyof (CustomDashboardReadModel | ReportDashboardReadModel)];
        if (Array.isArray(widgetsAtWidgetProp) && widgetsAtWidgetProp.length > 0) {
          widgetsAtWidgetProp.forEach((widget) => {
            const dashboardWidget = widget as WidgetReadModel;
            for (
              let row = dashboardWidget.startingPoint.row;
              row < dashboardWidget.startingPoint.row + dashboardWidget.size.amountOfRows;
              row++
            ) {
              for (
                let col = dashboardWidget.startingPoint.column;
                col < dashboardWidget.startingPoint.column + dashboardWidget.size.amountOfColumns;
                col++
              ) {
                const filledCellIndex = row * cols.lg + col;
                filledCells[filledCellIndex] = true;
              }
            }

            const widgetType = getWidgetTypeForReadModelProp(widgetProp);

            let widgetSizes: WidgetSizeType[] | null = null;
            const widgetConfiguration = getWidgetConfigurationForType(widgetType);
            if (widgetConfiguration && widgetConfiguration.sizes) {
              widgetSizes = typeof widgetConfiguration.sizes === 'object' && type in widgetConfiguration.sizes ? ((widgetConfiguration.sizes as WidgetDashboardTypeSizesConfig)[type]) : widgetConfiguration.sizes as WidgetSizeType[];
            }

            const maxDimensions = widgetSizes
              ? widgetSizes.reduce(
                  (acc, size) => {
                    acc.maxW = Math.max(acc.maxW, size.w);
                    acc.maxH = Math.max(acc.maxH, size.h);
                    return acc;
                  },
                  { maxW: 1, maxH: 1 },
                )
              : { maxW: 1, maxH: 1 };

            mappedLayout.push({
              layout: {
                x: dashboardWidget.startingPoint.column,
                y: dashboardWidget.startingPoint.row,
                w: dashboardWidget.size.amountOfColumns,
                maxW: maxDimensions.maxW,
                h: dashboardWidget.size.amountOfRows,
                maxH: maxDimensions.maxH,
                i: dashboardWidget.id,
                static: false,
              },
              widget: {
                type: widgetType,
                id: dashboardWidget.id,
                title: dashboardWidget.name,
                // all additional config provided by each widget
                additionalConfig: {
                  [DashboardWidgetType.BudgetStatus]:
                    widgetProp === 'budgetStatusWidgets'
                      ? {
                        costCatalogElements: (dashboardWidget as BudgetStatusWidgetReadModel).costCatalogElements,
                        columns: (dashboardWidget as BudgetStatusWidgetReadModel).columns,
                      }
                      : undefined,
                  [DashboardWidgetType.FinancialUsage]:
                    widgetProp === 'financialUsageWidgets'
                      ? {
                        calculationModelId: (dashboardWidget as FinancialUsageWidgetReadModel).calculationModelId,
                        financingElements: (dashboardWidget as FinancialUsageWidgetReadModel).financingElementIds,
                        columns: (dashboardWidget as FinancialUsageWidgetReadModel).columns,
                      }
                      : undefined,
                  [DashboardWidgetType.KPIs]:
                    widgetProp === 'kpisWidgets' ? (dashboardWidget as KpisWidgetReadModel).kpis : undefined,
                  [DashboardWidgetType.MapView]:
                    widgetProp === 'mapViewWidgets'
                      ? {
                          lat: (dashboardWidget as MapViewWidgetReadModel).lat,
                          lng: (dashboardWidget as MapViewWidgetReadModel).lng,
                        }
                      : undefined,
                  [DashboardWidgetType.ProjectInformation]:
                    widgetProp === 'projectInformationWidgets'
                      ? {
                          projectManagerId: (dashboardWidget as ProjectInformationWidgetReadModel).projectManagerId,
                          clientId: (dashboardWidget as ProjectInformationWidgetReadModel).clientId
                        }
                      : undefined,
                  [DashboardWidgetType.RiskMitigationList]:
                    widgetProp === 'riskMitigationListWidgets'
                      ? (dashboardWidget as RiskMitigationListWidgetReadModel).riskMitigations
                      : undefined,
                  [DashboardWidgetType.Tasks]:
                    widgetProp === 'tasksWidgets'
                      ? (dashboardWidget as TasksWidgetReadModel).tasks
                      : undefined,
                  [DashboardWidgetType.TextBox]:
                    widgetProp === 'textboxWidgets'
                      ? {
                          text: (dashboardWidget as TextboxWidgetReadModel).text,
                        }
                      : undefined,
                  [DashboardWidgetType.TrafficLight]:
                    widgetProp === 'trafficLightsWidgets'
                      ? (dashboardWidget as TrafficLightsWidgetReadModel).trafficLights
                      : undefined,
                  [DashboardWidgetType.ProjectClock]:
                    widgetProp === 'projectClockWidgets'
                      ? (dashboardWidget as ProjectClockWidgetReadModel).datasets
                      : undefined,
                  [DashboardWidgetType.Waterfall]:
                    widgetProp === 'waterfallWidgets'
                      ? {
                        calculationModelId: (dashboardWidget as WaterfallWidgetReadModel).calculationModelId
                      }
                      : undefined
                },
              },
            });
          });
        }
      }
    });
  }

  // fill in empty cells with placeholder widget
  filledCells.forEach((filledCell, index) => {
    if (!filledCell) {
      const key = `placeholder-${index}`;
      const y = Math.floor(index / cols.lg);
      const x = index % cols.lg;
      mappedLayout.push({
        layout: {
          i: key,
          x: x,
          y: y,
          h: 1,
          w: 1,
          isResizable: false,
          isDraggable: false,
          static: true
        },
        widget: {
          type: DashboardWidgetType.Placeholder,
          id: key,
        },
      });
    }
  });

  return mappedLayout;
};

/**
 * Generates standarized mobile breakpoints from the desktop grid layout.
 *
 * @param initialBreakpoints Initial layout with breakpoints definition.
 * @param type The dashboard type (custom or report dashboard).
 */
export const prepareMobileBreakpoints = (initialBreakpoints: Layouts, type: DashboardConfigKeys) => {
  const finalBreakpoints: Layouts = {};
  const dashboardCols = dashboardConfig[type]?.cols;

  initialBreakpoints.lg.sort((a, b) => {
    if (a.static === false && b.static === true) return -1;
    if (a.static === true && b.static === false) return 1;

    if (a.y !== b.y) return a.y - b.y;

    return a.x - b.x;
  });

  if (!dashboardCols) return { ...initialBreakpoints };

  Object.entries(dashboardCols).forEach(([breakpoint, cols]) => {
    if (breakpoint === "lg") return;

    let currentX = 0;
    let currentY = 0;

    finalBreakpoints[breakpoint] = initialBreakpoints.lg.map((item) => {
      const newItem = {
        ...item,
        w: 1,
        h: 1,
        isDraggable: false,
        isResizable: false,
        x: currentX,
        y: currentY
      };

      currentX += newItem.w;

      if (currentX >= cols) {
        currentX = 0;
        currentY++;
      }

      return newItem;
    });
  });

  return {...initialBreakpoints, ...finalBreakpoints};
};

/**
 * Returns the widget type for a given Backend ReadModel property (CashOutWidgetReadModel).
 * @param widgetProp The widgets list property. Different for each type. So one widget type should be mapped to a list in the ReadModel.
 */
export const getWidgetTypeForReadModelProp = (widgetProp: string) => {
  switch (widgetProp) {
    case 'budgetStatusWidgets':
      return DashboardWidgetType.BudgetStatus;
    case 'cashOutWidgets':
      return DashboardWidgetType.CashOutPlan;
    case 'financialUsageWidgets':
      return DashboardWidgetType.FinancialUsage;
    case 'kpisWidgets':
      return DashboardWidgetType.KPIs;
    case 'mapViewWidgets':
        return DashboardWidgetType.MapView;
    case 'projectInformationWidgets':
      return DashboardWidgetType.ProjectInformation;
    case 'projectUdfsWidgets':
      return DashboardWidgetType.ProjectUdfs;
    case 'riskMatrixWidgets':
      return DashboardWidgetType.RiskMatrix;
    case 'riskMitigationListWidgets':
      return DashboardWidgetType.RiskMitigationList;
    case 'tasksWidgets':
      return DashboardWidgetType.Tasks;
    case 'textboxWidgets':
      return DashboardWidgetType.TextBox;
    case 'timelineWidgets':
      return DashboardWidgetType.Timeline;
    case 'trafficLightsWidgets':
      return DashboardWidgetType.TrafficLight;
    case 'projectClockWidgets':
      return DashboardWidgetType.ProjectClock;
    case 'waterfallWidgets':
      return DashboardWidgetType.Waterfall;
    default:
      return DashboardWidgetType.Placeholder;
  }
};

/**
 * Iterates through the current layout and fills in the empty cells with a placeholder widget.
 *
 * @param layout Current layout.
 * @param rows Available rows for used dashboard.
 * @param cols Available cols for used dashboard.
 */
export const fillEmptyCells = (layout: Layout[], rows: number, cols?: number) => {
  if (!cols) return layout;

  const occupied: Set<string> = new Set();

  layout.forEach((item) => {
    for (let dx = 0; dx < item.w; dx++) {
      for (let dy = 0; dy < item.h; dy++) {
        const cellKey = `${item.x + dx}-${item.y + dy}`;
        occupied.add(cellKey);
      }
    }
  });

  const updatedLayout = [...layout];
  for (let y = 0; y < rows; y++) {
    for (let x = 0; x < cols; x++) {
      const cellKey = `${x}-${y}`;
      if (!occupied.has(cellKey)) {
        updatedLayout.push({
          i: `placeholder-${x}-${y}`,
          x,
          y,
          w: 1,
          h: 1,
          isResizable: false,
          isDraggable: false,
          static: true,
        });
        occupied.add(cellKey);
      }
    }
  }

  return updatedLayout;
};

/**
 * Find a widget definition in the loaded layout based on the grid item widget id.
 *
 * @param widgetId Widget ID that we are looking for.
 * @param layout Current layout.
 */
export const findWidget = (widgetId: string, layout: Widget[]) => {
  return layout.find((layoutItem: Widget) => layoutItem.widget.id === widgetId);
};

/**
 * Compares two layouts to check if there are changes. Undefined values are not considered.
 * @param layoutA
 * @param layoutB
 */
export const areLayoutsEqual = (layoutA: Layout[], layoutB: Layout[]): boolean => {
  if (layoutA.length !== layoutB.length) return false;

  const layoutMapA = new Map(layoutA.map((item) => [item.i, item]));
  const layoutMapB = new Map(layoutB.map((item) => [item.i, item]));

  for (const [key, itemA] of layoutMapA) {
    const itemB = layoutMapB.get(key);
    if (!itemB || itemA.x !== itemB.x || itemA.y !== itemB.y || itemA.w !== itemB.w || itemA.h !== itemB.h) {
      return false;
    }
  }

  return true;
};

/**
 * Gets the allowed resize sizes for a given widget type
 * @param widget The widget that wants to get resized. Each widget type has its own size configuration.
 * @param layout The current layout where the widget is place in.
 * @param gridType The type of the grid.
 */
export const getWidgetAllowedSizesInLayout = (widget: Widget, layout: Layouts, gridType: DashboardConfigKeys) => {
  const isPositionOccupied = (x: number, y: number, id: string) => {
    return layout.lg.some((item: Layout) =>
      !item.i.includes('placeholder-') && // ignore the placeholder widget
      item.i !== id && // ignore the current widget itself
      x >= item.x && x < item.x + item.w &&
      y >= item.y && y < item.y + item.h
    );
  };

  // Grid config
  const gridConfig = dashboardConfig[gridType];
  const maxCols = gridConfig.cols?.lg ?? 0;
  const maxRows = gridConfig.rows;

  // Widget config
  const allowedWidgetSizes = getWidgetSizeConfigurationForType(widget.widget.type, gridType);

  if (allowedWidgetSizes && widget.layout) {
    const availableSizes: WidgetSizeType[] = [];

    allowedWidgetSizes.forEach((size) => {
      if (!availableSizes.find((item) => item.w === size.w && item.h === size.h)) {
        let disabled = false;

        // Check if item exceeds the grid boundaries
        if (widget.layout.x + size.w > maxCols || widget.layout.y + size.h > maxRows) {
          disabled = true;
        } else {
          // Check if any position within the potential new widget's area is occupied
          for (let i = widget.layout.x; i < widget.layout.x + size.w; i++) {
            for (let j = widget.layout.y; j < widget.layout.y + size.h; j++) {
              if (isPositionOccupied(i, j, widget.layout.i)) {
                disabled = true;
              }
            }
          }
        }
        availableSizes.push({ ...size, disabled: disabled });
      }
    });

    return availableSizes;
  }

  return [];
};
