import { ConfirmModal, HintBox, LoadingIndicator, ModalOnCloseProps } from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { PerformancePercentageReadModel } from '@client/project/shared';

interface ContractDeletePerformancePercentageModalProps extends ModalOnCloseProps {
  item: PerformancePercentageReadModel;
}

export const ContractDeletePerformancePercentageModal = (props: ContractDeletePerformancePercentageModalProps) => {
  const { item, onClose } = props;

  const { t } = useTranslation();
  // const [call, { isLoading: isDeleting }] = useApiDeleteContractPerformancePercentageElementMutation();
  const isDeleting = false;

  const handleOnClose = () => {
    onClose(false);
  };

  const handleDelete = async () => {
    if (item?.id) {
      /* try {
         await safeMutation(
           call,
           {
             id: elementId,
             projectId: projectId ?? 'unset',
             calculationModelId: variantId,
           },
           isDeleting
         );

         onClose(true);
       } catch (e) {
        console.error(e);
       }
       */
    }
  };

  return (
    <ConfirmModal
      title={t('projectContract.completionLevel.performancePercentage.delete.title')}
      onConfirm={handleDelete}
      onClose={handleOnClose}
      confirmVariant="danger"
      confirmText={t('common.delete')}
      isLoading={isDeleting}
    >
      {isDeleting && <LoadingIndicator text={t('common.deleteLoading') ?? ''} mode="overlay" />}
      <HintBox hintType="warning">{t('projectContract.completionLevel.performancePercentage.delete.hint')}</HintBox>
    </ConfirmModal>
  );
};
