import {
  BaseSelect,
  Button,
  ComboSelect,
  ComboSelectOption,
  FileInput,
  Form,
  FormField,
  FormRefHandle,
  FormWatch,
  HintBox,
  LoadingIndicator,
  Modal,
  TextInput,
  UploadToCloudDottedIcon,
  Wizard,
} from '@client/shared/toolkit';
import { Trans, useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UploadPortalValidationSchema, UploadPortalValidationValues } from './UploadPortalValidationSchema';
import cn from 'classnames';
import {
  apiBase,
  ApiPostUploadAiEvalDocumentApiArg,
  ApiTagTypes,
  CrossTenantContractReadModel,
  TrustedProjectReadModel,
  useApiGetContractsQuery,
  useApiGetProjectQuery,
  useApiGetProjectsQuery,
  useApiPostCrossTenantUploadAiEvalDocumentMutation,
  useApiPostUploadAiEvalDocumentMutation,
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { useHasUploadLicense } from '@client/shared/permissions';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';

export const UploadWizard = ({
  contracts = [],
  loadedProjectId,
  project,
  onClose,
  isCrossTenant,
  selectProject = false,
}: {
  loadedProjectId?: string;
  project?: TrustedProjectReadModel;
  contracts?: CrossTenantContractReadModel[];
  onClose?: () => void;
  isCrossTenant?: boolean;
  selectProject?: boolean;
}) => {
  const { t } = useTranslation();
  const hasUploadLicense = useHasUploadLicense();
  const dispatch = useDispatch();

  const [projectId, setProjectId] = useState<string | undefined>(loadedProjectId);
  const [calculationModelId, setCalculationModelId] = useState<string | undefined>(undefined);

  const { data, isFetching: isFetchingProjects } = useApiGetProjectsQuery(undefined, {
    skip: !selectProject,
  });

  const projectOptions: ComboSelectOption[] = useMemo(() => {
    let allProjects = data?.projects ?? [];
    data?.projectGroups?.map((x) => (allProjects = allProjects.concat(x.projects)));

    return allProjects.map((project) => {
      return {
        label: project.name,
        value: project.id,
      };
    });
  }, [data?.projects, data?.projectGroups]);

  const { data: loadedInternalProject, isFetching: isFetchingInternalProject } = useApiGetProjectQuery(
    { projectId: projectId || '' },
    { skip: !projectId || !hasUploadLicense, refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (loadedInternalProject?.project?.calculationModels?.length) {
      setCalculationModelId(loadedInternalProject.project.calculationModels[0].id);
    }
  }, [loadedInternalProject]);

  const { data: fetchedContracts } = useApiGetContractsQuery(
    {
      projectId: projectId ?? '',
      calculationModelId: loadedInternalProject?.project?.calculationModels[0]?.id ?? '',
    },
    {
      skip: !projectId || !calculationModelId,
      refetchOnMountOrArgChange: true,
    },
  );

  const loadedProject = useMemo(() => {
    return project || loadedInternalProject?.project.payload;
  }, [project, loadedInternalProject?.project.payload]);

  useEffect(() => {
    setProjectId(loadedProjectId);
  }, [loadedProjectId]);

  const [uploadInvoiceDocument, { isLoading: isUploadingDocument }] =
    useApiPostCrossTenantUploadAiEvalDocumentMutation();
  const [uploadInternalInvoiceDocument, { isLoading: isUploadingDocumentInternal, isError }] =
    useApiPostUploadAiEvalDocumentMutation();

  const formRef = useRef<FormRefHandle<UploadPortalValidationValues>>();

  const [currentStep, setCurrentStep] = useState(0);
  const [allowNextStep, setAllowNextStep] = useState(false);

  const [fileList, setFileList] = useState<FileList | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [fileError, setFileError] = useState(false);

  const handleOnSlideChange = async (index: number) => {
    setAllowNextStep(false);
    setCurrentStep(index);
    validateSteps();
  };

  const uploadDocument = async (data: UploadPortalValidationValues) => {
    if (files.length && loadedProject && !fileError) {
      if (isCrossTenant && project) {
        try {
          const formData = new FormData();
          formData.append('file', files[0]);
          await safeMutation(
            uploadInvoiceDocument,
            {
              contractId: data.contract ? data.contract : '00000000-0000-0000-0000-000000000000',
              crossTenantId: project.tenantId,
              crossTenantProjectId: data.projectId,
              body: formData as unknown as { file: Blob },
            },
            isUploadingDocument,
          );
          setCurrentStep((prev) => prev + 1);
        } catch (e) {
          console.log(e);
        }
      } else if (!isCrossTenant && loadedProject) {
        let errorCount = 0;
        files.forEach(async (file, i) => {
          const formData = new FormData();
          formData.append('file', file);
          if (data.contract) {
            formData.append('contractId', data.contract);
          }

          const args = {
            invoiceId: null,
            projectId: projectId,
            docType: 'Generic',
            body: formData,
          } as ApiPostUploadAiEvalDocumentApiArg;

          try {
            const response = await safeMutation(uploadInternalInvoiceDocument, args, isUploadingDocumentInternal);
            if (
              response?.aiServiceError !== null &&
              response?.aiServiceError !== '' &&
              response?.aiServiceError !== undefined
            ) {
              errorCount += 1;
              toast.error(t(response.aiServiceError), { duration: 5000 });
            } else {
              setTimeout(() => {
                // @ts-expect-error invalidate get tasks list
                dispatch(apiBase.util.invalidateTags([{ type: ApiTagTypes.Tasks }]));
              }, 3000);
            }

            if (i === files.length - 1) {
              setFiles([]);
              setFileList(null);
              setFileError(false);
              if (errorCount === 0 && !isError) {
                toast.success(t('projectControl.notificationUploadingInvoiceDocumentsCompleted'));
                setCurrentStep((prev) => prev + 1);
              }
            }
          } catch (e) {
            setFiles([]);
            setFileList(null);
            setFileError(false);
          }
        });
      }
    }
  };

  const validateSteps = useCallback(() => {
    if (formRef.current) {
      const values = formRef?.current?.getValues();
      switch (currentStep) {
        case 0: {
          if (values.documentType) {
            setAllowNextStep(true);
          } else {
            setAllowNextStep(false);
          }
          break;
        }
        case 1: {
          if (values.documentType && files.length) {
            setAllowNextStep(true);
          } else {
            setAllowNextStep(false);
          }
          break;
        }
        default:
          break;
      }
    }
  }, [currentStep, formRef, files]);

  const handleFileChange = (files: FileList | null) => {
    if (files?.length) {
      setFiles(Array.from(files));
      setFileList(files);
      setAllowNextStep(true);
    } else {
      setFiles([]);
      setFileList(null);
      setAllowNextStep(false);
    }
  };

  const nextButtonLabel = useMemo(() => {
    switch (currentStep) {
      case 0:
        return t('common.next');
      case 1:
        return t('common.upload');
    }

    return t('common.next');
  }, [currentStep, t]);

  const documentTypeOptions = useMemo(() => {
    return [
      { label: t('projectControl.invoice'), value: 'invoice' },
      // { label: t('projectContract.contract'), value: 'contract' },
      // { label: t('projectContract.supplement'), value: 'supplement' },
    ];
  }, [t]);

  const contractOptions = useMemo(() => {
    const loadedContracts = (fetchedContracts?.contracts ? fetchedContracts?.contracts : contracts) ?? [];
    return loadedContracts.map((contract) => {
      return {
        label: `${contract.code} ${contract.name}`,
        value: contract.id,
      };
    });
  }, [contracts, fetchedContracts?.contracts]);

  const defaultFormValues = {
    projectId: projectId,
    documentType: 'invoice',
    contract: '',
  };

  useEffect(() => {
    formRef?.current?.setValue('contract', '');
  }, [projectId]);

  const reset = () => {
    handleFileChange(null);
    setCurrentStep(0);
  };

  const isLoading = useMemo(() => {
    if (projectId) {
      return !fetchedContracts;
    }
    return isFetchingProjects || isFetchingInternalProject;
  }, [fetchedContracts, isFetchingProjects, projectId, isFetchingInternalProject]);

  const isUploading = useMemo(() => {
    return isUploadingDocument || isUploadingDocumentInternal;
  }, [isUploadingDocument, isUploadingDocumentInternal]);

  return (
    <Form<UploadPortalValidationValues>
      onSubmit={uploadDocument}
      validationSchema={UploadPortalValidationSchema}
      defaultValues={defaultFormValues}
      ref={formRef}
      className="h-full"
    >
      <div className="w-full h-full flex items-center">
        {isUploading && !isLoading && (
          <LoadingIndicator text={t('projectControl.uploadInvoiceLoadingIndicator')} mode="overlay-window" />
        )}
        {isLoading && !isUploading && <LoadingIndicator text={t('common.loading')} mode="overlay-window" />}
        {isCrossTenant && (
          <div className="w-[260px] min-h-full py-6 px-2 bg-primary text-white flex flex-col items-center bg-bottom bg-no-repeat bg-contain" />
        )}
        <div className="h-full flex-1">
          <FormWatch<UploadPortalValidationValues>
            onChange={() => {
              validateSteps();
            }}
            fieldNames={['projectId', 'documentType', 'contract']}
          >
            {() => (
              <Wizard
                currentStep={currentStep}
                onSlideChange={(step) => handleOnSlideChange(step)}
                className="w-full h-full"
              >
                <Wizard.Slides className="h-full overflow-auto">
                  <Wizard.Slide hasPadding={false}>
                    <div className="w-full px-4 sm:px-10">
                      <h2 className={cn('text-4xl text-primary font-bold mb-8', { 'text-center': !isCrossTenant })}>
                        {t('uploadPortal.uploadTitle')}
                      </h2>
                      {selectProject ? (
                        <FormField name="projectId">
                          {(control) => (
                            <ComboSelect
                              className="mt-1"
                              options={projectOptions}
                              label={t('app.project')}
                              {...control}
                              onChange={(val) => {
                                if (val) {
                                  setCalculationModelId(undefined);
                                  setProjectId(val);
                                  control.onChange(val);
                                }
                              }}
                              nullable={false}
                              pageOptions
                            />
                          )}
                        </FormField>
                      ) : (
                        <TextInput
                          label={t('app.project')}
                          value={`${loadedProject?.tenantName} - ${loadedProject?.name}`}
                          disabled
                        />
                      )}
                      <FormField name="documentType">
                        {(control) => (
                          <BaseSelect
                            className="mt-1"
                            options={documentTypeOptions}
                            label={t('uploadPortal.uploadTypeOfDocument')}
                            {...control}
                          />
                        )}
                      </FormField>
                      <FormField name="contract">
                        {(control) => (
                          <BaseSelect
                            className="mt-1"
                            disabled={!contractOptions.length}
                            options={contractOptions}
                            label={t('projectContract.contract')}
                            nullable
                            {...control}
                          />
                        )}
                      </FormField>
                      {!contracts.length && isCrossTenant && (
                        <HintBox hintType="warning" className="mt-2">
                          {t('uploadPortal.noContractsAvailable', { name: loadedProject?.name })}
                        </HintBox>
                      )}
                    </div>
                  </Wizard.Slide>
                  <Wizard.Slide>
                    <div className="w-full px-4 sm:px-10">
                      <h2 className={cn('text-4xl text-primary font-bold mb-8', { 'text-center': !isCrossTenant })}>
                        {t('uploadPortal.uploadFile')}
                      </h2>
                      <FileInput
                        className="w-full bg-white p-2"
                        icon={<UploadToCloudDottedIcon className="w-16 flex-1" />}
                        acceptedFileTypes={['application/pdf']}
                        multiple
                        selectedFiles={fileList}
                        onChange={handleFileChange}
                        uploadDescription={
                          <Trans i18nKey="uploadPortal.uploadFileInputDescription">
                            <span className="text-[13px]">
                              Ziehen Sie Ihre Datei per <span className="font-bold">Drag & Drop</span> in diesen Bereich
                              oder <span className="font-bold">klicken</span> Sie hier.
                            </span>
                          </Trans>
                        }
                        setError={setFileError}
                      />
                    </div>
                  </Wizard.Slide>
                  <Wizard.Slide>
                    <div className="max-w-[369px] px-4 sm:px-10">
                      <p className="text-sm text-slate-500">{t('uploadPortal.uploadFileUploadSuccessMessage')}</p>
                    </div>
                  </Wizard.Slide>
                </Wizard.Slides>
                <Wizard.Navigation>
                  {({ count, isFirst, isLast, canGoPrevious, canGoNext, previous, next }) => (
                    <Modal.Controls
                      hasPadding={false}
                      className={cn(
                        'py-6',
                        currentStep === 2 ? 'bg-white px-8 py-4' : 'px-4 sm:px-10',
                        isLast ? '' : 'justify-between',
                      )}
                    >
                      {(() => {
                        if ((isFirst || isLast) && onClose) {
                          return (
                            <Button
                              variant="text"
                              onClick={onClose}
                              className="bg-slate-200 px-8 h-[48px] hover:bg-slate-300"
                            >
                              {t('common.close')}
                            </Button>
                          );
                        }
                        return (
                          <Button
                            variant="secondary"
                            disabled={!isFirst && !canGoPrevious}
                            onClick={isFirst && onClose ? onClose : previous}
                            className="bg-slate-200 px-8 h-[48px] hover:bg-slate-300"
                          >
                            {t('common.back')}
                          </Button>
                        );
                      })()}
                      {(() => {
                        if (isLast) {
                          return (
                            <div className="text-right">
                              <Button variant="primary" onClick={reset} className="px-8 h-[48px]">
                                {t('uploadPortal.uploadAnotherFile')}
                              </Button>
                            </div>
                          );
                        } else if (currentStep === 1) {
                          return (
                            <Button
                              variant="danger"
                              onClick={() => formRef.current?.submitForm()}
                              disabled={fileError || !files.length}
                            >
                              {t('uploadPortal.transmitData')}
                            </Button>
                          );
                        } else {
                          return (
                            <Button
                              variant="primary"
                              disabled={!canGoNext || !allowNextStep || fileError || !projectId}
                              onClick={next}
                              className="px-8 h-[48px]"
                            >
                              {nextButtonLabel}
                            </Button>
                          );
                        }
                      })()}
                    </Modal.Controls>
                  )}
                </Wizard.Navigation>
              </Wizard>
            )}
          </FormWatch>
        </div>
      </div>
    </Form>
  );
};
