import { useLoadedProjectId } from '@client/project/store';
import { useApiPostCreateReportDashboardMutation } from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LoadingIndicator,
  Modal,
  ModalOnCloseProps, WidgetReportCustomIcon,
  WizardSelectList,
  WizardSelectListItemProps,
} from '@client/shared/toolkit';

export interface ReportAddModalProps extends ModalOnCloseProps {
  setCreatedDashboardId: (id: string | null) => void;
}

export const ReportAddModal = (props: ReportAddModalProps) => {
  const { onClose, setCreatedDashboardId } = props;
  const { t } = useTranslation();
  const projectId = useLoadedProjectId();

  const [createReportDashboard, { isLoading: isCreatingReportDashboard }] = useApiPostCreateReportDashboardMutation();

  const createCustomReport = useCallback(async () => {
    if (projectId) {
      try {
        const createResponse = await safeMutation(
          createReportDashboard,
          {
            projectId: projectId,
            body: {
              name: t('reporting.customReportTitle'),
              budgetStatusWidgets: [],
              cashOutWidgets: [],
              financialUsageWidgets: [],
              kpisWidgets: [],
              projectInformationWidgets: [],
              riskMitigationListWidgets: [],
              tasksWidgets: [],
              textboxWidgets: [],
              trafficLightsWidgets: [],
              projectClockWidgets: [],
              waterfallWidgets: []
            },
          },
          isCreatingReportDashboard,
        );
        if (createResponse?.dashboardId) {
          setCreatedDashboardId(createResponse.dashboardId);
          onClose(false);
          // navigating to the new view is happening in useEffect now
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [t, projectId, createReportDashboard, isCreatingReportDashboard, setCreatedDashboardId, onClose]);

  const items: WizardSelectListItemProps[] = useMemo(() => {
    const onSelect = (type: string) => {
      switch (type) {
        case 'widget':
          createCustomReport();
          break;
        default:
          break;
      }
    };

    return [
      {
        title: t('reporting.newReport.widgetPageTitle'),
        subtitle: t('reporting.newReport.widgetPageDescription'),
        icon: <WidgetReportCustomIcon />,
        handleClick: () => {
          onSelect('widget');
        },
        iconSize: 'w-full h-auto px-4',
        inBox: false,
      },
    ];
  }, [t, createCustomReport]);

  return (
    <>
      {isCreatingReportDashboard && <LoadingIndicator mode="overlay-window" />}
      <Modal.Header title={t('reporting.newReport.title')} description={t('reporting.newReport.description')} />
      <Modal.Content>
        <WizardSelectList
          items={items}
          grid={items.length > 1}
          gridCols={items.length === 3 ? 'grid-cols-3' : 'grid-cols-4'}
        />
      </Modal.Content>
    </>
  );
};
